import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import React from 'react'
import { useListTeamChatRoomsQuery } from '../../graphql/generated'
import ProjectAvatar from './ProjectAvatar'
import Styles from "./ShowProject.module.scss"
import { chatboxOutline } from "ionicons/icons"
import { useGraphQLDataSource } from '../../api/graphql'
import { useHistory } from 'react-router'
import { ShowProjectMember, ProjectMemberTeam } from './ShowProject'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'

type ProjectMemberRowProps = {
  member: ShowProjectMember,
  createChatRoom: (team1: ProjectMemberTeam, team2: ProjectMemberTeam) => void,
}

const useListChatRoomBetweenTeams = (team1Id: string, team2Id: string) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  // TODO: Research why refetchOnWindowFocus isn't defaulting to true when it should based on React Query's idocumentation.
  const result = useListTeamChatRoomsQuery(gqlDataSource, { team1Id, team2Id }, { refetchOnWindowFocus: true })

  if (result.isFetching || result.data === undefined) {
    return undefined
  }

  const chatRooms = result.data.listTeamChatRooms
  if (chatRooms !== undefined && chatRooms !== null && chatRooms?.length > 0)
    return chatRooms[0]

  return undefined
}

export const ProjectMemberRow: React.FC<ProjectMemberRowProps> = ({ member, createChatRoom }) => {
  const myTeam = useMyIndividualActiveTeam()
  if (!myTeam) throw new Error("Can't render this component without an active team")
  const memberTeam = member.team

  const history = useHistory()
  const chatRoomBetweenTeams = useListChatRoomBetweenTeams(myTeam.id, memberTeam.id)

  const goToChatRoom = (chatRoomId: string) => {
    history.push(`/chats/${chatRoomId}`)
  }

  return (
    <IonItem key={memberTeam.id} className={Styles.projectMemberItem} routerLink={myTeam.id !== memberTeam.id ? `/profile/${memberTeam.type}/${memberTeam.id}` : `/profile`}>
      <ProjectAvatar slot="start" title={memberTeam.name} />
      <IonLabel>
        <h2>{memberTeam.name}</h2>
        <h3>{memberTeam.type}</h3>
      </IonLabel>

      <IonButton size='large' className={Styles.chatIconButton} slot="end" fill="clear" hidden={myTeam.id === memberTeam.id} onClick={event => {
        event.preventDefault()
        const doesAChatAlreadyExists = chatRoomBetweenTeams?.id !== undefined

        if (doesAChatAlreadyExists) {
          goToChatRoom(chatRoomBetweenTeams?.id)
        } else {
          createChatRoom(myTeam, memberTeam)
        }
        event.stopPropagation()
      }}>
        <IonIcon icon={chatboxOutline} />
      </IonButton>
    </IonItem>
  )
}
