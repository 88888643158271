import { useState } from "react"

type ChatMessageImagesCache = {
  [fileName: string]: Blob,
}

const useMessageImagesCache = () => {
  const [ cache, setCache ] = useState<ChatMessageImagesCache>({})

  const readFromCache = (fileName: string): Blob | undefined => cache[fileName]
  const saveToCache = (fileName: string, blob: Blob) => { cache[fileName] = blob }
  const emptyCache = () => setCache({})

  return {
    readFromCache,
    saveToCache,
    emptyCache,
  }
}

export default useMessageImagesCache
