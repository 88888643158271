
import { useAblyPartyLinePresence } from "../../providers/AblyProvider/useAblyPartyLine"

type UseChatRoomMembersPresenceReturns = {
  enterChatRoom: ReturnType<typeof useAblyPartyLinePresence>['enterChannel'],
  leaveChatRoom: ReturnType<typeof useAblyPartyLinePresence>['leaveChannel'],
  getMembersPresentInChatRoom: ReturnType<typeof useAblyPartyLinePresence>['getMembersPresentInChannel'],
}

export const useChatRoomMembersPresence = ({ chatRoomId }: { chatRoomId: string }): UseChatRoomMembersPresenceReturns => {

  const { enterChannel, leaveChannel, getMembersPresentInChannel } = useAblyPartyLinePresence({
    partyLineId: chatRoomId,
  })

  const enterChatRoom = () => enterChannel()
  const leaveChatRoom = () => leaveChannel()
  const getMembersPresentInChatRoom = () => getMembersPresentInChannel()

  return {
    enterChatRoom,
    leaveChatRoom,
    getMembersPresentInChatRoom,
  }
}
