import { GoogleGeocodedAddressInput, LocationInput, ViewportInput } from "../../graphql/generated"

export const convertGoogleMapsLatLngToLocationInput = (errorLocation: string, location?: google.maps.LatLng): LocationInput => {
  const lat = location?.lat()
  const lng = location?.lng()

  if (lat == null || lng == null) {
    console.error(`[convertGoogleMapsLatLngToLocationInput:${errorLocation}] Missing lat / lng`, location)
    throw new Error(`[convertGoogleMapsLatLngToLocationInput:${errorLocation}] Missing lat / lng`)
  }

  return {
    lat,
    lng,
  }
}

export const convertGoogleMapsLatLngBoundsToViewportInput = (errorLocation: string, viewport?: google.maps.LatLngBounds): ViewportInput | null | undefined => {
  const northeast = viewport?.getNorthEast()
  const southwest = viewport?.getSouthWest()

  if (northeast == null || southwest == null) return undefined

  return {
    northeast: convertGoogleMapsLatLngToLocationInput(`${errorLocation}.northeast`, northeast),
    southwest: convertGoogleMapsLatLngToLocationInput(`${errorLocation}.southwest`, southwest),
  }
}

export const convertGoogleMapsPlaceResultToGoogleGeocodedAddressInput = (place: google.maps.places.PlaceResult): GoogleGeocodedAddressInput => {
  const { address_components, formatted_address, place_id, plus_code, types, geometry } = place
  return {
    address_components,
    formatted_address,
    place_id,
    plus_code,
    types,
    geometry: {
      location: convertGoogleMapsLatLngToLocationInput('places.geometry.location', geometry?.location),
      viewport: convertGoogleMapsLatLngBoundsToViewportInput('places.geometry.viewport', geometry?.viewport),
    },
  }
}
