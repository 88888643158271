import { isMobile } from "../../../common/utils/ionic"
import { useEnvironmentContext } from "../EnvironmentProvider"
import { BrowserTracing } from "@sentry/tracing"
import * as SentryCapacitor from "@sentry/capacitor"
import * as SentryReact from "@sentry/react"
import { useEffect, useState } from "react"

export const useSetupSentry = () => {
  const [ isInitialised, setIsInitialised ] = useState(false)
  const environment = useEnvironmentContext()

  useEffect(() => {
    if (isInitialised)
      return

    if (process.env.NODE_ENV === 'development'){
      setIsInitialised(false)
      return
    }

    if (isMobile){
      SentryCapacitor.init({
        dsn: environment.integrations.sentry.app.DSN,
        environment: `${environment.account.type}_${environment.account.name}`,
        release: process.env.REACT_APP_RELEASE_VERSION,
        tracesSampleRate: 1.0,
      })
    } else {
      SentryReact.init({
        dsn: environment.integrations.sentry.frontend.DSN,
        environment: `${environment.account.type}_${environment.account.name}`,
        integrations: [ new BrowserTracing() ],
        release: process.env.REACT_APP_RELEASE_VERSION,
        tracesSampleRate: 1.0,
      })
    }

    setIsInitialised(true)
  }, [ isInitialised , setIsInitialised ])

  return isInitialised
}
