import { useGraphQLDataSource } from "../../graphql"
import { GetChatRoomQuery, useGetChatRoomQuery } from "../../../graphql/generated"

export type ChatRoom = NonNullable<GetChatRoomQuery['getChatRoom']>

type UseGetChatRoomReturns = {
  chatRoom: ChatRoom | null | undefined,
}

export const useGetChatRoom = ({ chatRoomId }: { chatRoomId: string }): UseGetChatRoomReturns => {
  const fiveMinutesInMilliseconds = 5 * 60 * 1000
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getChatRoomQuery = useGetChatRoomQuery(gqlDataSource, { chatRoomId }, {
    staleTime: fiveMinutesInMilliseconds,
  })

  const chatRoom = getChatRoomQuery.isSuccess
    ? getChatRoomQuery.data.getChatRoom
    : getChatRoomQuery.isLoading
      ? undefined
      : null

  return {
    chatRoom,
  }
}
