import { useGraphQLDataSource } from '../../../api/graphql'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useMyIndividual } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { GoogleGeocodedAddressInput, TeamType, useAugmentGoogleGeocodedAddressMutation, useClaimCompanyTeamMutation, useClaimNonCompanyTeamMutation, useCreateArchitectProfileMutation, useCreateContractorProfileMutation, useCreateHomeownerProfileMutation } from '../../../graphql/generated'

export const useTeamNamePlaceholder = (team: TeamType): string => {
  const myIndividual = useMyIndividual()

  switch (team) {
    case TeamType.Architect: return `${myIndividual.familyName}'s Architect`
    case TeamType.Contractor: return `${myIndividual.familyName}'s Contractor`
    case TeamType.Homeowner: return `The ${myIndividual.familyName}'s`
    case TeamType.Weaver: return `Weaver`
  }
}

type OnboardArchitectTeamProps = {
  companyNumber: string,
  companyRegisteredName: string,
  companyTradingAs: string,
  companyAddress?: GoogleGeocodedAddressInput,
}
export const useOnboardArchitectTeam = () => {
  const triggerEvent_eOnboardingTeamFinish = useAnalyticsEvent('Onboarding_Team_Completed')
  const datasource = useGraphQLDataSource({ api: 'core' })
  const claimCompanyTeam = useClaimCompanyTeamMutation(datasource)
  const createArchitectProfile = useCreateArchitectProfileMutation(datasource)
  const augmentGoogleGeocodedAddress = useAugmentGoogleGeocodedAddressMutation(datasource)
  return async ({ companyNumber, companyRegisteredName, companyTradingAs, companyAddress }: OnboardArchitectTeamProps) => {
    // Create the team first
    const claimCompanyTeamResult = await claimCompanyTeam.mutateAsync({
      type: TeamType.Architect,
      name: companyTradingAs,
      companyNumber,
    })

    // Augment the address if it exists
    if (companyAddress !== undefined) {
      await augmentGoogleGeocodedAddress.mutateAsync({
        location: companyAddress.geometry.location,
        googleGeocodedAddress: companyAddress,
      })
    }

    // Build the architect profile
    await createArchitectProfile.mutate({
      input: {
        architectTeamId: claimCompanyTeamResult.claimCompanyTeam.id,
        companyRegisteredName,
        companyTradingAs,
        companyNumber,
        companyLocation: companyAddress?.geometry.location,
      },
    })

    triggerEvent_eOnboardingTeamFinish({
      claimedTeamNumber: companyNumber,
      claimedTeamName: companyTradingAs,
      claimedTeamType: TeamType.Architect,
    })

    return { team: claimCompanyTeamResult.claimCompanyTeam }
  }
}

type OnboardContractorTeamProps = {
  companyNumber: string,
  companyRegisteredName: string,
  companyTradingAs: string,
  companyAddress?: GoogleGeocodedAddressInput,
}
export const useOnboardContractorTeam = () => {
  const triggerEvent_eOnboardingTeamFinish = useAnalyticsEvent('Onboarding_Team_Completed')
  const datasource = useGraphQLDataSource({ api: 'core' })
  const claimCompanyTeam = useClaimCompanyTeamMutation(datasource)
  const createContractorProfile = useCreateContractorProfileMutation(datasource)
  const augmentGoogleGeocodedAddress = useAugmentGoogleGeocodedAddressMutation(datasource)
  return async ({ companyNumber, companyRegisteredName, companyTradingAs, companyAddress }: OnboardContractorTeamProps) => {
    // Create the team first
    const claimCompanyTeamResult = await claimCompanyTeam.mutateAsync({
      type: TeamType.Contractor,
      name: companyTradingAs,
      companyNumber,
    })

    // Augment the address if it exists
    if (companyAddress !== undefined) {
      await augmentGoogleGeocodedAddress.mutateAsync({
        location: companyAddress.geometry.location,
        googleGeocodedAddress: companyAddress,
      })
    }

    // Build the contractor profile
    await createContractorProfile.mutate({
      input: {
        contractorTeamId: claimCompanyTeamResult.claimCompanyTeam.id,
        companyRegisteredName,
        companyTradingAs,
        companyNumber,
        companyLocation: companyAddress?.geometry.location,
      },
    })

    triggerEvent_eOnboardingTeamFinish({
      claimedTeamNumber: companyNumber,
      claimedTeamName: companyTradingAs,
      claimedTeamType: TeamType.Contractor,
    })

    return { team: claimCompanyTeamResult.claimCompanyTeam }
  }
}

type OnboardHomeownerTeamProps = {
  teamName: string,
  propertyAddress?: GoogleGeocodedAddressInput,
}
export const useOnboardHomeownerTeam = () => {
  const triggerEvent_eOnboardingTeamFinish = useAnalyticsEvent('Onboarding_Team_Completed')
  const datasource = useGraphQLDataSource({ api: 'core' })
  const claimNonCompanyTeam = useClaimNonCompanyTeamMutation(datasource)
  const createHomeownerProfile = useCreateHomeownerProfileMutation(datasource)
  const augmentGoogleGeocodedAddress = useAugmentGoogleGeocodedAddressMutation(datasource)
  return async ({ teamName, propertyAddress }: OnboardHomeownerTeamProps) => {
    // Create the team first
    const claimCompanyTeamResult = await claimNonCompanyTeam.mutateAsync({
      type: TeamType.Homeowner,
      name: teamName,
    })

    // Augment the address if it exists
    if (propertyAddress !== undefined) {
      await augmentGoogleGeocodedAddress.mutateAsync({
        location: propertyAddress.geometry.location,
        googleGeocodedAddress: propertyAddress,
      })
    }

    // Build the contractor profile
    await createHomeownerProfile.mutate({
      input: {
        homeownerTeamId: claimCompanyTeamResult.claimNonCompanyTeam.id,
        teamName,
        propertyLocation: propertyAddress?.geometry.location,
      },
    })

    triggerEvent_eOnboardingTeamFinish({
      claimedTeamName: teamName,
      claimedTeamType: TeamType.Homeowner,
    })

    return { team: claimCompanyTeamResult.claimNonCompanyTeam }
  }
}
