import React, { useEffect, useState } from 'react'
import { IonButton, IonContent } from '@ionic/react'
import Confetti from 'react-confetti'
import Styles from "./ConfettiPage.module.scss"

interface ConfettiPageProps {
  caption: string,
  message: string,
  onClick?: () => void,
}

const width = window.innerWidth
const height = window.innerHeight

const ConfettiPage: React.FC<ConfettiPageProps> = ({ caption, message, onClick }) => {
  const [ runLeft, setRunLeft ] = useState(true)
  const [ runRight, setRunRight ] = useState(false)

  useEffect(() => {
    const timerLeft = setTimeout(() => {
      // This will run after 1 second on the RH side
      setRunLeft(false)
      setRunRight(true)
    }, 2000)
    const timerRight = setTimeout(() => {
      // This will run after 1 second on the LH side
      setRunRight(false)
    }, 4000)
    return () => {
      clearTimeout(timerLeft)
      clearTimeout(timerRight)
    }
  }, [])

  return (
    <IonContent>
      <div className={Styles.pageContainer}>
        <h1 className={Styles.caption}>{caption}</h1>
        <p className={Styles.notification}>
          {message}
        </p>
        {onClick && <IonButton className={Styles.button} expand='block' onClick={onClick} size="large">Continue</IonButton>}
        <Confetti
          width={runLeft || runRight ? width : 0}
          height={height}
          recycle={true}
          run={runRight || runLeft}
          gravity={0.07}
          confettiSource={{ x: runLeft  ? 0 : width, y: height / 2, w: width / 3, h: height / 3 }}
        />
      </div>
    </IonContent>
  )
}

export default ConfettiPage
