import React from 'react'
import { IonToolbar, IonContent, IonPage, IonButton, IonRouterOutlet, IonCardHeader, IonCardTitle } from '@ionic/react'
import LoginCard from '../../init/Bootstrapper/LoginCard'
import { useEnvironmentContext } from '../../api/providers/EnvironmentProvider/context'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { ensureTrailingSlash } from '../../common/utils'
import ManageJwts from './ManageJwts'
import WorkflowSimulator from './WorkflowSimulator'
import ManageTeams from './ManageTeams'
import ManageLeads from './ManageLeads'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import ButtonChangeLeadPreferences from '../profile/ContractorLeadPreferences/ButtonChangeLeadPreferences'
import ButtonResetContractorProfileWorkHistory from '../profile/ContractorLeadPreferences/ButtonResetContractorProfileWorkHistory'
import ResponsiveContentWrapper from '../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

const MenuEnvironmentStatus: React.FC = () => {
  const environment = useEnvironmentContext()
  return <p>Env: <strong>{environment.app.domain}</strong></p>
}

const DeveloperSettings: React.FC = () => {
  const history = useHistory()

  return (
    <IonPage id="dev-settings">
      <GlobalHeader/>
      <IonCardHeader>
        <IonToolbar>
          <IonCardTitle>Developer Settings</IonCardTitle>
        </IonToolbar>
      </IonCardHeader>
      <IonContent>
        <ResponsiveContentWrapper>
          <MenuEnvironmentStatus/>
          <IonButton onClick={() => history.push('/dev-settings/jwt')}>Manage JWTs</IonButton>
          <IonButton onClick={() => history.push('/dev-settings/workflow')}>Simulate Workflow</IonButton>
          <IonButton onClick={() => history.push('/dev-settings/teams')}>Manage Teams</IonButton>
          <IonButton onClick={() => history.push('/dev-settings/leads')}>Manage Leads</IonButton>
          <ButtonChangeLeadPreferences />
          <ButtonResetContractorProfileWorkHistory />
          <IonButton onClick={() => { throw new Error("Intentional error for Sentry testing.")}}>Trigger Error for Sentry</IonButton>
          <LoginCard />
        </ResponsiveContentWrapper>
      </IonContent>
    </IonPage>
  )
}

const DeveloperSettingsTab: React.FC = () => {
  const match = useRouteMatch()
  console.debug(`[devSettings.DeveloperSettings] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="devSettings">
        <Route exact path={match.url} component={DeveloperSettings} />
        <Route path={`${ensureTrailingSlash(match.url)}jwt`} component={ManageJwts} />
        <Route path={`${ensureTrailingSlash(match.url)}workflow`} component={WorkflowSimulator} />
        <Route path={`${ensureTrailingSlash(match.url)}teams`} component={ManageTeams} />
        <Route path={`${ensureTrailingSlash(match.url)}leads`} component={ManageLeads} />
      </IonRouterOutlet>
    </IonPage>
  )
}
export default DeveloperSettingsTab
