import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { refreshOutline, warningOutline } from 'ionicons/icons'
import CentralisedBlock from '../CentralisedBlock'

interface ContainerProps {
  onRefresh: () => void,
}

const ErrorBlock: React.FC<ContainerProps> = ({ onRefresh }) =>
  <CentralisedBlock>
    <IonIcon icon={warningOutline} size="large" color="danger" />
    <p>Oops! That didn&apos;t work.</p>
    <p><IonButton onClick={onRefresh} size="small" color="danger"><IonIcon icon={refreshOutline} /> Try Again</IonButton></p>
  </CentralisedBlock>

export default ErrorBlock
