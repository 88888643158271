import React from 'react'
import { AuthContext } from '..'
import { useEnvironmentContext } from '../../EnvironmentProvider'
import CachingJwtSelector from './CachingJwtSelector'
import useCachedAuth from './useCachedAuth'

export const CachedJwtProvider: React.FC = ({ children }) => {
  const environment = useEnvironmentContext()
  const [ cachedAuth, setCachedJwts ] = useCachedAuth()

  if (environment.account.type !== 'dev') {
    throw new Error('Auth Read Only mode not available outside of dev accounts')
  }

  return cachedAuth === undefined
    ? <CachingJwtSelector setCachedJwts={setCachedJwts} />
    : <AuthContext.Provider value={{
      engine: 'auth0ReadOnlyCache',
      isLoggedIn: true,
      logout: async () => {
        setCachedJwts(undefined)
      },
      forceRefresh: async () => {
        setCachedJwts(undefined)
      },
      ...cachedAuth,
    }}>
      {children}
    </AuthContext.Provider>
}

export default CachedJwtProvider
