import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"
import { closeCircleOutline, reloadOutline } from 'ionicons/icons'

type ThumbnailPhotoErroredProps = {
  onRetryClicked?: () => void,
}

const ThumbnailPhotoErrored: React.FC<ThumbnailPhotoErroredProps> = ({ onRetryClicked }) => {
  return (
    <IonButton className={Styles.thumbnailPhotoContainer} fill="clear" size="small" onClick={ () => { onRetryClicked ? onRetryClicked() : undefined } }>
      <IonIcon size='large' icon={reloadOutline} className={Styles.retryButton} />
      <IonButton fill="clear" size="small" className={Styles.topRightIcon}>
        <IonIcon icon={closeCircleOutline}/>
      </IonButton>
    </IonButton>
  )
}

export default ThumbnailPhotoErrored

