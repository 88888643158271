import React, { useCallback, useState } from "react"
import { IonButton, IonContent, IonFooter, IonIcon, IonPage, IonToolbar } from "@ionic/react"
import { useRouteToUnlockContractorBudgetRange } from "../../../../../../routes"
import { useHistory, useParams } from "react-router"
import { getWorkHistoryById, useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import { useScreens } from "../../../../../../common/hooks/useScreens"
import { alwaysArray } from "../../../../../../common/utils"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import ConfettiPage from "../../../../../../common/components/ConfettiPage"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import ProvidePhotosScreen from "./ScreenProvidePhotos"
import { BudgetRange, UploadedFileStatus, useCreateContractorProfileWorkHistoryPhotoMutation } from "../../../../../../graphql/generated"
import { UploadPhotoButton } from "./UploadPhotoButton"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import { DisplayUploadedFileAsPhoto } from "./DisplayUploadedFileAsPhoto"
import AnimatedBudgetRangeBadgePage from "../../../ContractorBudgetReferences/AnimatedBudgetRangeBadgePage"
import { UploadFile } from "./UploadFile"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import Styles from './WorkHistoryPhotos.module.scss'

enum WorkHistoryPhotosScreens {
  ProvidePhotosInstructions = "ProvidePhotosInstructions",
  UploadPhotos = "UploadPhotos",
  Finished = "Finished",
  Badge = "Badge",
}

const WorkHistoryPhotos: React.FC = () => {
  const history = useHistory()
  const { budgetRange, workHistoryId } = useParams<{ budgetRange: BudgetRange, workHistoryId: string }>()

  const myContractorProfile = useMyContractorProfile()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createContractorProfileWorkHistoryPhoto = useCreateContractorProfileWorkHistoryPhotoMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()
  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const goToUnlockContractorBudgetRange = useRouteToUnlockContractorBudgetRange()

  const [ Screen, activeScreen ] = useScreens<WorkHistoryPhotosScreens>({
    init: WorkHistoryPhotosScreens.ProvidePhotosInstructions,
    resetWhenValueChanges: history.location.search,
    screens: {
      ProvidePhotosInstructions: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <ProvidePhotosScreen />
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.toolbar}>
                <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRange(budgetRange)}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      UploadPhotos: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          const [ filesToUpload, setFilesToUpload ] = useState<File[]>([])

          const photos = alwaysArray(workHistory?.photos)

          const onFilesSelectedByUser = (files: File[]) => {
            setFilesToUpload(files)
          }

          const addUploadFileToWorkHistoryItemPhotos = (uploadedFileId: string) =>
            createContractorProfileWorkHistoryPhoto.mutateAsync({
              workHistoryId,
              uploadedFileId,
            })

          const onPhotoUploaded = async (uploadFileId: string) => {
            await addUploadFileToWorkHistoryItemPhotos(uploadFileId)
            invalidateMyContractorProfile()
          }

          const onPhotoArchived = () => {
            invalidateMyContractorProfile()
          }

          return <>
            <IonContent className="ion-padding" fullscreen>
              <ResponsiveContentWrapper>
                <h3>Upload Photos</h3>
                <UploadPhotoButton onFilesSelectedByUserCallback={onFilesSelectedByUser} multipleSelection={true}/>
                <div className={Styles.photosContainer}>
                  {
                    photos
                      .filter(photo => photo.status !== UploadedFileStatus.Archived)
                      .map(photo => <DisplayUploadedFileAsPhoto key={photo.id} uploadedFileId={photo.id} onFileArchived={onPhotoArchived} shouldDisplayDeleteButton={true} />)
                  }
                  {
                    filesToUpload
                      .map(file => <UploadFile key={file.name} fileToUpload={file} onUploadComplete={onPhotoUploaded} />)
                  }
                </div>
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      Finished: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <ConfettiPage caption='Amazing!!!' message='You have added a reference project' />
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={onClickNext}>Continue</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      Badge: {
        render: useCallback(() => {
          const triggerEvent = useAnalyticsEvent('WorkHistory_References_Badge_Awarded')

          const triggerEventThenGoToUnlockContractorBudgetRange = (budgetRange: BudgetRange) => async () => {
            await triggerEvent({
              budgetRange: budgetRange,
            })
            goToUnlockContractorBudgetRange(budgetRange)()
          }

          return <AnimatedBudgetRangeBadgePage
            description='You have added a reference project in the'
            actionButtonProps={{
              children: <>Continue</>,
              expand: 'block',
              size: 'large',
            }}
            budgetRange={budgetRange}
            onClick={triggerEventThenGoToUnlockContractorBudgetRange(budgetRange)}
          />
        }, [ budgetRange ]),
      },
    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default WorkHistoryPhotos
