import { useCallback, useContext, useEffect, useMemo } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEnvironmentContext } from '../EnvironmentProvider'
import { MyIndividual } from '../MyIndividualProvider/MyIndividualProvider'
import { Team } from '../../../graphql/generated'
import { SegmentContext } from './SegmentProvider'
import { useAuthContext, WeaverIdTokenClaims } from '../AuthProvider'
import { EventProperties, NoData } from './events'
import { convertIsoDateToEpochQuietly } from '../../../common/utils/date'

export type EventName = keyof EventProperties

/**
 * Initialises the Segment AnalyticsBrowser.
 * Call this hook very early in the rendering of the app.
 */
export const useSegmentSetup = () => {
  const environment = useEnvironmentContext()
  const writeKey = environment.integrations.segment.frontend.writeKey

  return useMemo(() => {
    const analytics = AnalyticsBrowser.load({ writeKey })
    console.log('[SegmentProvider.useSegmentSetup] Init complete.', analytics)
    return analytics
  }, [ writeKey ])
}

export const useAnalyticsBrowserContext = () => {
  const analytics = useContext(SegmentContext)
  if (!analytics) throw new Error('[SegmentProvider.useAnalyticsBrowser] Cannot use the SegmentContext outside of its Provider!')
  return analytics
}

/**
 * Identify the individual as the analytics user
 * Sets the specified team as the activeTeam on the analytics user's properties.
 * Also connects the analytics user to the team group.
 *
 * @param myIndividual MyIndividual
 * @param team Team that this user is generating activity within.
 */
export const useAnalyticsIndividualWithActiveTeam = (myIndividual?: MyIndividual, team?: Pick<Team, 'id' | 'type' | 'name'>) => {
  console.log('[segment] useAnalyticsIndividualWithActiveTeam', myIndividual, team)
  const analytics = useAnalyticsBrowserContext()
  const auth = useAuthContext()

  // This gets called multiple times if `useMemo` is used instead!
  useEffect(() => {
    if (auth === undefined) return
    if (team === undefined) return
    if (myIndividual === undefined) return

    // Tell segment about the user
    analytics.identify(myIndividual.id, {
      created_at: convertIsoDateToEpochQuietly(myIndividual.createdAt), // Speced by MW-1063
      email: auth.userData?.email,
      phone: auth.userData?.[WeaverIdTokenClaims.WeaverPhoneNumber],
      firstName: myIndividual.givenName,
      lastName: myIndividual.familyName,
      avatar: myIndividual.pictureURL,
      claimedTeamNumber: team.id,
      claimedTeamType: team.type,
      claimedTeamName: team.name,
    })

    console.debug(`[SegmentProvider.useAnalyticsIndividual] Identify '${myIndividual.id}' with team '${team.id}': `, { myIndividual, team, analytics, auth })
  }, [ analytics, auth, team, myIndividual ])
}

/**
 * PRIVATE: internal function for calling any analytics function with any data
 * It's used so that useAnalyticsEvent/useFireAnalyticsEvent can simplify their external type safety without changing functionality
 *
 * It is NOT intented for external consumption - please do NOT consume/export without consultation
 */
const useUnsafeAnalyticsEvent = (eventName: EventName) => {
  const analytics = useAnalyticsBrowserContext()

  const trackFn = useCallback(async (extraData = {}) => {
    console.debug(`[SegmentProvider.useAnalyticsEvent.trigger] Send event '${eventName}': `, extraData)
    await analytics.track(eventName, extraData)
  }, [ analytics ])

  return trackFn
}

/**
 * Use this hook when you need to get a function to call the analytics with.
 * Returns a function which will fire the event named when called with optional extra data.
 *
 * @param eventName EventName
 * @returns A function to fire the event named, with the optional extra data
 */
export const useAnalyticsEvent = <TEventName extends EventName>(eventName: TEventName): (...[ extraData ]: EventProperties[TEventName] extends NoData ? [] : [EventProperties[TEventName]]) => Promise<void> => {
  return useUnsafeAnalyticsEvent(eventName)
}

/**
 * Fires the event named with the optional extra data whenever the dependencies specified change.
 *
 * @param props EventName, optional extra data, and change dependency array
 */

type UseFireAnalyticsEventProps<TEventName extends EventName> =  {
  eventName: TEventName,
  extraData?:  EventProperties[TEventName] extends NoData ? never : EventProperties[TEventName],
  deps?: unknown[],
}
export const useFireAnalyticsEvent = <TEventName extends EventName>({ eventName, extraData, deps = [] }: UseFireAnalyticsEventProps<TEventName>) => {
  const triggerEvent = useUnsafeAnalyticsEvent(eventName)

  // This gets called multiple times if `useMemo` is used instead!
  useEffect(() => {
    triggerEvent(extraData)
  }, deps)
}
