import {
  IonIcon,
  IonItem,
  IonLabel,
  IonSearchbar,
} from "@ionic/react"
import React, { useCallback, useState } from "react"
import { useGraphQLDataSource } from "../../../../api/graphql"
import { CompanySummary, useGetPossibleCompanyNumbersForPartialCompanyNameQuery } from "../../../../graphql/generated"
import Styles from "./AutoSearchCompany.module.scss"
import { Virtuoso } from "react-virtuoso"
import { businessOutline } from "ionicons/icons"
import classnames from "classnames"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"

type AutoSearchCompanyProps = {
  selectedCompany?: CompanySummary,
  companyNameSelected: (company?: CompanySummary) => void,
}

const DEBOUNCE_TIME = 350

const AutoSearchCompany: React.FC<AutoSearchCompanyProps> = ({ selectedCompany, companyNameSelected }) => {
  const [ selected, setSelected ] = useState<CompanySummary | undefined>(undefined)
  const [ searchPartial, setSearchPartial ] = React.useState("")
  const apiRefreshCount = React.useRef(0)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const triggerEvent = useAnalyticsEvent('Onboarding_Company_Selected')

  const { data } = useGetPossibleCompanyNumbersForPartialCompanyNameQuery(
    gqlDataSource,
    { partialCompanyName: searchPartial },
    {
      enabled: searchPartial.length > 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
  const companies = data?.getPossibleCompanyNumbersForPartialCompanyName

  const handleSearchUpdate = useCallback(async ({ value: search }) => {
    if (search === "" || selectedCompany !== undefined) return
    apiRefreshCount.current++
    setSearchPartial(search)
  }, [ setSearchPartial ])

  const handleSelect = (company: CompanySummary | undefined) => {
    setSelected(company)
    companyNameSelected(company)
    triggerEvent({
      numberOfResponsesOfApi: apiRefreshCount.current,
    })
  }

  return <div className={Styles.container}>
    <IonLabel>Company Name *</IonLabel>
    <IonSearchbar
      onIonChange={e => handleSearchUpdate(e.detail ?? "")}
      onIonClear={() => {
        companyNameSelected(undefined)
        setSearchPartial("")
      }}
      debounce={DEBOUNCE_TIME}
    >
    </IonSearchbar>
    <div className={Styles.virtuosoContainer}>
      {companies &&
      <Virtuoso
        className={Styles.companySelector}
        data={companies}
        itemContent={index =>
          <IonItem button lines='none' className={classnames({ [ Styles.childItem ]: true, [Styles.selected]: selected?.companyName.includes(companies[index]?.companyName) })}
            onClick={() => handleSelect(companies[index])}>
            <IonIcon icon={businessOutline} style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}/>
            <p>
              {companies[index]?.companyName}
            </p>
          </IonItem>
        }
      />
      }
    </div>
  </div>
}

export default AutoSearchCompany
