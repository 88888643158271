import { useMutation } from "react-query"
import { z } from "zod"
import { useEnvironmentContext } from "../../providers/EnvironmentProvider"
import { decryptEncryptedJWT } from "./crypto"
import { JwtListItem } from "./useJwtManagerListItems"

const zJwtGetItem = z.object({
  id: z.string(),
  savedAt: z.string(),
  email: z.string(),
  encryptedJWT: z.string(),
})

export const useJwtManagerGetDecryptedJwt = () => {
  const environment = useEnvironmentContext()
  if (environment.apiGateway.devSupport.url === undefined) {
    throw new Error('[useJwtManager] Unable to find the devSupport API url')
  }
  const devSupportURL = environment.apiGateway.devSupport.url

  // Decrypt a JWT

  type DecrypGetDecryptedJwtProps = {
    jwtListItem: JwtListItem,
    email: string,
    passcode: string,
  }
  const getDecryptedJwt = useMutation([ 'jwtManager', 'decrypt' ], async ({ jwtListItem, email, passcode }: DecrypGetDecryptedJwtProps) => {
    const response = await fetch(`${devSupportURL}/${jwtListItem.id}?${email}`, {
      method: 'GET',
    })

    const jsonBody = await response.json()
    if (response.status !== 200) {
      return {
        errorCode: response.status,
        jsonBody,
      }
    }

    const jwtGetItem = zJwtGetItem.parse(jsonBody)

    return decryptEncryptedJWT(jwtGetItem.encryptedJWT, passcode)
  })

  return getDecryptedJwt
}
