import React, { useRef } from 'react'

import { folderOutline } from 'ionicons/icons'
import { IonButton, IonIcon } from '@ionic/react'
import Styles from "./BrowseFilesButton.module.scss"

type BrowseFilesButtonProps = {
  onFileSelectedByUserCallback: (selectedFile: File) => void,
}

const BrowseFilesButton: React.FC<BrowseFilesButtonProps> = ({ onFileSelectedByUserCallback }) => {
  const inputThatOpensNativeFilePicker = useRef<HTMLInputElement>(null)

  const openNativeFilePicker = () => {
    inputThatOpensNativeFilePicker.current?.click()
  }

  const onFileSelectedByUser = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    if (target.files && target.files.length > 0) {
      const userSelectedFile: File = target.files[0]
      onFileSelectedByUserCallback(userSelectedFile)
    }
  }

  return (
    <div className={Styles.browseFilesButton}>
      <input hidden ref={inputThatOpensNativeFilePicker} type="file" id="nativeFilePicker" onChange={onFileSelectedByUser} />
      <IonButton onClick={openNativeFilePicker} fill="clear" size="small" >
        <IonIcon icon={folderOutline} color="primary" />
        Browse Documents
      </IonButton>
    </div>
  )
}

export default BrowseFilesButton
