import React, { SVGAttributes } from "react"
import { objectEntries } from "../../../common/utils/objects"
import { ProgressionStatus } from "../../../graphql/generated"
import { convertToLockedBudgetRangeStatuses, GetContractorProfileWorkHistory } from "../ContractorLeadPreferences/ContractorBudgetRanges/datasource"
import RangeBadge from "../ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge"

type ContractorProfileRangeBadgesProps = {
  workHistory?: GetContractorProfileWorkHistory[] | null,
} & SVGAttributes<SVGSVGElement>

const acceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

const ContractorProfileRangeBadges: React.FC<ContractorProfileRangeBadgesProps> = ({ workHistory, ...svnAttrs }) => {
  const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(workHistory)

  return <>
    {objectEntries(lockedBudgetRangeStatuses)
      .filter(([ , status ]) => acceptableStatuses.includes(status))
      .map(([ budgetRange ]) => <RangeBadge key={budgetRange} {...{ budgetRange, ...svnAttrs }} />)
    }
  </>
}

export default ContractorProfileRangeBadges
