import React from 'react'
import { useRouteMatch } from 'react-router'
import NotImplementedPage from '../../../common/components/NotImplementedPage'
import { TeamType } from '../../../graphql/generated'
import OnboardingArchitect from './flows/OnboardingArchitect'
import OnboardingContractor from './flows/OnboardingContractor'
import OnboardingHomeowner from './flows/OnboardingHomeowner'
import OnboardingWeaver from './flows/OnboardingWeaver'

export const OnboardingTeamIndex: React.FC = () => {
  console.debug(`[OnboardingTeamIndex]: Render`)

  const match = useRouteMatch<{ teamType?: TeamType }>()
  const teamType = match.params.teamType

  switch (teamType) {
    case TeamType.Architect: return <OnboardingArchitect />
    case TeamType.Contractor: return <OnboardingContractor />
    case TeamType.Homeowner: return <OnboardingHomeowner />
    case TeamType.Weaver: return <OnboardingWeaver />
  }

  return <NotImplementedPage />
}

export default OnboardingTeamIndex
