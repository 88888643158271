import { useEffect, useState } from "react"
import { sortBy } from "../../../common/utils"
import { ChatRoomMessageProps } from "./MyChatsRow"

const findLastMessage = (messages: ChatRoomMessageProps[]) => {
  const copiedMessages = [ ...messages ]
  copiedMessages.sort(sortBy(msg => msg.sentAt))
  const lastMessage = copiedMessages[copiedMessages.length - 1]
  return lastMessage
}

export const useLastMessage = (messages: ChatRoomMessageProps[]) => {
  const [ lastMessage, setLastMessage ] = useState<ChatRoomMessageProps>()

  useEffect(() => {
    setLastMessage(findLastMessage(messages))
  }, [ messages ])

  return lastMessage
}