import React, { useEffect } from 'react'
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { TAuth_LoggedIn, TAuth_LoggedOut } from '..'
import { buildLoggedInAuthState, buildLoggedOutAuthState } from '../helpers'

type UseRegisterCustomURLHandlerProps = {
  auth0Client?: Auth0Client,
  auth0ClientOptions?: Auth0ClientOptions,
  authState: TAuth_LoggedOut | TAuth_LoggedIn | undefined,
  setAuthState: React.Dispatch<React.SetStateAction<TAuth_LoggedOut | TAuth_LoggedIn | undefined>>,
}
const useRegisterCustomURLHandler = ({ auth0Client, auth0ClientOptions, authState, setAuthState }: UseRegisterCustomURLHandlerProps) =>
  useEffect(() => {
    // Dependency Guard
    if (auth0Client === undefined) return
    if (auth0ClientOptions === undefined) return

    // We only register the handler if we are logged out
    if (authState?.isLoggedIn !== false) return

    // Register the custom URL handler function
    App.addListener('appUrlOpen', curryAppUrlOpenFn({
      auth0Client,
      auth0ClientOptions,
      setAuthState,
    }))
  }, [ auth0Client, auth0ClientOptions, authState ])

type CurryAppUrlOpenFnProps = {
  auth0Client: Auth0Client,
  auth0ClientOptions: Auth0ClientOptions,
  setAuthState: React.Dispatch<React.SetStateAction<TAuth_LoggedOut | TAuth_LoggedIn | undefined>>,
}
const curryAppUrlOpenFn = ({ auth0Client, auth0ClientOptions, setAuthState }: CurryAppUrlOpenFnProps) => async ({ url }: URLOpenListenerEvent) => {
  if (url.startsWith(auth0ClientOptions.redirect_uri ?? '')) {
    const isSigningInCallback = url.includes("state") && (url.includes("code") || url.includes("error"))
    if (isSigningInCallback) {
      // Ask the auth0Client to handle the callback, then update state to logged in
      auth0Client.handleRedirectCallback(url)
        .then(() => buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState }))
        .then(setAuthState)
    } else {
      // If not sign in then it's log out
      buildLoggedOutAuthState({ auth0Client, auth0ClientOptions, setAuthState })
        .then(setAuthState)
    }
    await Browser.close()
  }
}

export default useRegisterCustomURLHandler
