import React, { useCallback } from "react"
import { useParams } from "react-router"
import { IonButton, IonContent, IonFooter, IonIcon, IonPage, IonText, IonToolbar } from "@ionic/react"
import { useRouteToContractorBudgetRanges } from "../../../../../routes"
import ThisBudgetRangeIsLockedScreen from "./ScreenThisBudgetRangeIsLocked"
import { useScreens } from "../../../../../common/hooks/useScreens"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import RangeBadge from "../../ContractorBudgetReferences/RangeBadge"
import { budgetRangeLabels } from "../budgetRanges.i18n"
import { getFirstWorkHistoryByBudgetRange, useMyContractorProfile } from "../datasource"
import ScreenWorkHistoryMenu from "./ScreenWorkHistoryMenu"
import { BudgetRange } from "../../../../../graphql/generated"
import IndicatorBar from "../../../../projects/CreateProject/IndicatorBar"
import { alwaysArray } from "../../../../../common/utils"
import { WorkHistoryMenuItem } from "./ScreenWorkHistoryMenu/config"
import Styles from "./UnlockContractorBudgetRange.module.scss"
import ResponsiveContentWrapper from "../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { useAnalyticsEvent } from "../../../../../api/providers/SegmentProvider/hooks"

enum UnlockScreens {
  ThisBudgetRangeIsLocked = "ThisBudgetRangeIsLocked",
  UnlockMenu = "UnlockMenu",
}

const UnlockContractorBudgetRange: React.FC = () => {
  const { budgetRange } = useParams<{ budgetRange: BudgetRange }>()

  const goToContractorBudgetRanges = useRouteToContractorBudgetRanges()

  const myContractorProfile = useMyContractorProfile()
  const workHistory = getFirstWorkHistoryByBudgetRange(myContractorProfile, budgetRange)
  const isNewWorkHistory = workHistory === undefined

  const [ Screen, activeScreen ] = useScreens<UnlockScreens>({
    isWaiting: myContractorProfile.getContractorProfileQuery.isLoading,
    init: () => isNewWorkHistory ? UnlockScreens.ThisBudgetRangeIsLocked : UnlockScreens.UnlockMenu,
    resetWhenValueChanges: budgetRange,
    screens: {

      ThisBudgetRangeIsLocked: {
        render: useCallback(({ useChangeScreen }) => {
          const triggerEventWorkHistoryReferencesAddedBudgetPreferences = useAnalyticsEvent('WorkHistory_References_Added_Budget_Preferences')
          const changeScreenToUnlockMenu = useChangeScreen(UnlockScreens.UnlockMenu)
          const triggerEventThenChangeScreen = async () => {
            triggerEventWorkHistoryReferencesAddedBudgetPreferences({ budgetRange })
            changeScreenToUnlockMenu()
          }

          return <>
            <IonContent className="ion-padding" fullscreen>
              <ThisBudgetRangeIsLockedScreen budgetRange={budgetRange} />
              <br />
              <br />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToContractorBudgetRanges()}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={triggerEventThenChangeScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ budgetRange ]),
      },

      UnlockMenu: {
        render: useCallback(({ useChangeScreen }) => {
          const changeScreenToUnlockMenu = useChangeScreen(UnlockScreens.ThisBudgetRangeIsLocked)
          const hasWorkHistory = workHistory !== undefined

          const doesWorkHistoryHaveAnyReferences = alwaysArray(workHistory?.references).length > 0
          const doesWorkHistoryHaveAnyPhotos = alwaysArray(workHistory?.photos).length > 0
          const currentProgressIndex = !hasWorkHistory ? 0 : [ hasWorkHistory, doesWorkHistoryHaveAnyReferences, doesWorkHistoryHaveAnyPhotos ].filter(Boolean).length
          const totalPages = Object.keys(WorkHistoryMenuItem).length

          const onBackClick = isNewWorkHistory
            ? changeScreenToUnlockMenu
            : goToContractorBudgetRanges()

          return <>
            <div className={`${Styles.pastProjectHeaderContainer} ion-text-center}`}>
              { budgetRange && <RangeBadge budgetRange={budgetRange} />}
              <h5>Unlock {budgetRangeLabels[budgetRange]} leads</h5>
            </div>
            <div className={Styles.indicatorContainer}>
              <IndicatorBar currentPageIndex={currentProgressIndex} totalPages={totalPages} maxAvailableIndex={totalPages} onNavigate={() => null} />
            </div>
            <IonContent className="ion-padding" >
              <ResponsiveContentWrapper >
                <IonText>To unlock {budgetRangeLabels[budgetRange]} leads, you need to have all the tasks marked as done</IonText>
                <ScreenWorkHistoryMenu budgetRange={budgetRange} workHistory={workHistory} />
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onBackClick}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ budgetRange ]),
      },
    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default UnlockContractorBudgetRange
