import React from 'react'
import { IonBadge, IonContent, IonIcon, IonItem, IonPage  } from '@ionic/react'
import { alwaysArray, uniqueFilter } from '../../../common/utils'
import { GetContractorProfileWorkHistory } from '../ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { moneyToText } from '../../../common/utils/string'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import ProfileHeader from '../ProfileHeader'
import { projectTypeLabels } from '../../onboarding/team/onboarding.i18n'
import Styles from './ContractorWorkHistory.module.scss'
import { DisplayUploadedFileAsPhoto } from '../ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type ContractorWorkHistoryProps = {
  contractorProfileId: string,
  workHistoryItem: GetContractorProfileWorkHistory,
}

const ContractorWorkHistory: React.FC<ContractorWorkHistoryProps> = ({ contractorProfileId, workHistoryItem }) => {
  console.debug(`[ContractorWorkHistory] Render: `, { contractorProfileId, workHistoryItem })

  return <IonPage>
    <ProfileHeader isMyTeam={false} />
    <IonContent className={`${Styles.ionContent} ion-padding`} fullscreen>
      <ResponsiveContentWrapper>
        <h3>{workHistoryItem.projectAddress?.formatted_address ?? 'Work History'}</h3>

        <IonItem className={Styles.costOfWorkContainer}>
          <IonIcon slot='start' icon={allIonIcons.cashOutline} />
          <span>
            <p>Cost of Work</p>
            <p>{moneyToText(workHistoryItem.constructionValue, { withPennies: true })}</p>
          </span>
        </IonItem>

        <IonItem className={Styles.typeOfWorkContainer}>
          <IonIcon icon={allIonIcons.hammerOutline} slot="start"/>
          <span>
            <p>Type</p>
            {alwaysArray(workHistoryItem.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
          </span>
        </IonItem>

        <h3>Reference</h3>
        {workHistoryItem?.references !== undefined ? alwaysArray(workHistoryItem.references)
          .map(reference => reference.teamType)
          .filter(uniqueFilter)
          .map(teamType =>
            <>
              <p  key={teamType}>{teamType}</p>
            </>,
          )
          : <p>No references have been uploaded</p>
        }

        <h3>Photos</h3>
        <div className={Styles.photoContainer}>
          { workHistoryItem.photos ? alwaysArray(workHistoryItem.photos)
            .map(photo =>
              <div key={photo.id} className={Styles.photoItemContainer}>
                <DisplayUploadedFileAsPhoto uploadedFileId={photo.id}  shouldDisplayDeleteButton={false} />
              </div>,
            )
            : <p>No photos have been uploaded</p>
          }
        </div>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}

export default ContractorWorkHistory
