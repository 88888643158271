import React from "react"
import { IonFab, IonFabButton, IonFabList, IonIcon } from "@ionic/react"
import { addOutline, createOutline } from "ionicons/icons"
import { useIonRouter } from "@ionic/react"

import Styles from "./GlobalActionButton.module.scss"
import { getKnownRoutePathForPages, Page } from "../../../routes"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { TeamType } from "../../../graphql/generated"

// Represents the pathnames of the routes and sub-routes in which the FAB has to be hidden.
const hideButtonOnThesePages = [
  Page.MyChats,
  Page.Onboarding,
  Page.CreateProject,
  Page.Success,
  Page.Profile,
]

const GlobalActionButton: React.FC = () => {
  const activeTeam = useMyIndividualActiveTeam()
  const isContractor = activeTeam?.type === TeamType.Contractor

  const router = useIonRouter()
  const routePathsToHide = getKnownRoutePathForPages(...hideButtonOnThesePages)
  const shouldHideFabButton = routePathsToHide.find(route => router.routeInfo?.pathname.includes(route))

  return (
    <IonFab slot='fixed' className={Styles.addButton} hidden={isContractor || !!shouldHideFabButton} >
      <IonFabButton>
        <IonIcon icon={addOutline} />
      </IonFabButton>
      <IonFabList side="top">
        <IonFabButton data-label="Start a New Project" routerLink="/new-project"><IonIcon icon={createOutline} /></IonFabButton>
      </IonFabList>
    </IonFab>
  )
}

export default GlobalActionButton
