import React from 'react'
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'
import { Browser } from '@capacitor/browser'
import { TAuth_LoggedIn, TAuth_LoggedOut } from '.'
import { isMobile } from '../../../common/utils/ionic'

const PACKAGE = 'Auth0Provider.helpers'

type BuildLoggedOutAuthStateProps = {
  auth0Client: Auth0Client,
  auth0ClientOptions: Auth0ClientOptions,
  setAuthState: React.Dispatch<React.SetStateAction<TAuth_LoggedOut | TAuth_LoggedIn | undefined>>,
}
export const buildLoggedOutAuthState = async ({ auth0Client, auth0ClientOptions, setAuthState }: BuildLoggedOutAuthStateProps): Promise<TAuth_LoggedOut> => ({
  engine: 'auth0',
  isLoggedIn: false,
  login: async () => {
    if (isMobile) {
      // Redirect to Auth0 as part of the Authorization Code Flow
      // Later, it will redirect back to this app, based on the Auth0ClientConfig settings
      return auth0Client.buildAuthorizeUrl()
        .then(url => Browser.open({ url, windowName: "_self" }))
    } else {
      // Use the loginWithPopup Auth0Client approach (popup window)
      // Then update the logged in state
      return auth0Client.loginWithPopup()
        .then(() => buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState }))
        .then(setAuthState)
    }
  },
})

type BuildLoggedInAuthStateProps = {
  auth0Client: Auth0Client,
  auth0ClientOptions: Auth0ClientOptions,
  setAuthState: React.Dispatch<React.SetStateAction<TAuth_LoggedOut | TAuth_LoggedIn | undefined>>,
}
export const buildLoggedInAuthState = async ({ auth0Client, auth0ClientOptions, setAuthState }: BuildLoggedInAuthStateProps): Promise<TAuth_LoggedIn> => {
  const userData = await auth0Client.getUser()
  const gravatar = userData?.picture
  const accessToken = await auth0Client.getTokenSilently()
  const idToken = await auth0Client.getIdTokenClaims()
  console.debug(`[${PACKAGE}.buildLoggedInAuthState] Logged in auth state `, { auth0Client, userData, gravatar, accessToken, idToken })
  return {
    engine: 'auth0',
    isLoggedIn: true,
    logout: async () => {
      await Browser.open({
        url: auth0Client.buildLogoutUrl({
          returnTo: isMobile ? auth0ClientOptions.redirect_uri : window.location.origin,
        }),
        windowName: isMobile ? undefined : "_self",
      })
      await auth0Client.logout({ localOnly: true })
    },
    forceRefresh: async () => {
      console.debug(`[${PACKAGE}.buildLoggedInAuthState.forceRefresh] Refreshing... `, { auth0Client, userData, gravatar, accessToken, idToken })
      const newToken = await auth0Client.getTokenSilently({ ignoreCache: true })
      const updatedAuthState = await buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState })
      console.debug(`[${PACKAGE}.buildLoggedInAuthState.forceRefresh] Setting new auth state: `, { newToken, updatedAuthState })
      setAuthState(updatedAuthState)
    },
    userData,
    gravatar,
    accessToken,
    idToken,
  }
}
