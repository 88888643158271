
import React, { useCallback, useState } from "react"
import { IonButton, IonContent, IonPage, useIonRouter } from "@ionic/react"
import { useScreens } from "../../../common/hooks/useScreens"
import { BudgetRange, useAcceptLeadMutation } from "../../../graphql/generated"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { moneyToText } from "../../../common/utils/string"
import { budgetRangeLabels } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n"
import { useHistory } from "react-router"
import { budgetRangeLeadFee, budgetRangeLeadFeeStripeProduct, budgetRangeMonthlySubscriptionFee, budgetRangeMonthlySubscriptionStripeProduct } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { useGraphQLDataSource } from "../../../api/graphql"
import MembershipPayPerLead from "./MembershipPayPerLead"
import SelectMembershipPlan from "./SelectMembershipPlan"
import useStripePaymentForLeadPaymentHandler from "../../../api/thirdParty/stripe/useStripePaymentHandler"

enum LeadAcceptanceScreens {
  SelectMembershipPlan = "SelectMembershipPlan",
  BuyMembership = "BuyMembership",
  BuyLead = "BuyLead",
  Finished = "Finished",
}

type ContractorLeadAcceptorProps = {
  leadId: string,
  budgetRange: BudgetRange,
  hasBudgetRangeSubscription: boolean,
}

export enum FeeOption { Subscription, PerLead }

const ContractorLeadAcceptor: React.FC<ContractorLeadAcceptorProps> = ({ leadId, budgetRange, hasBudgetRangeSubscription }) => {
  const weaverFlags = useWeaverFlags()

  const skipToEnd = !weaverFlags["contractor-lead-acceptor"].enabled || hasBudgetRangeSubscription

  const [ Screen, activeScreen ] = useScreens<LeadAcceptanceScreens>({
    init: () => skipToEnd ? LeadAcceptanceScreens.Finished : LeadAcceptanceScreens.SelectMembershipPlan,
    screens: {

      SelectMembershipPlan: {
        render: useCallback(({ useChangeScreen }) => {

          const changeScreenToBuyMembership = useChangeScreen(LeadAcceptanceScreens.BuyMembership)
          const changeScreenToBuyLead = useChangeScreen(LeadAcceptanceScreens.BuyLead)

          const handleNextClick = (selected: FeeOption | undefined) => {
            if (selected !== undefined) {
              return  selected === FeeOption.Subscription ? changeScreenToBuyMembership() : changeScreenToBuyLead()
            }
          }

          return <SelectMembershipPlan
            budgetRange={budgetRange}
            budgetRangeLabel={budgetRangeLabels[budgetRange]}
            budgetRangeLeadFee={moneyToText(budgetRangeLeadFee[budgetRange])}
            budgetRangeMonthlySubscriptionFee={moneyToText(budgetRangeMonthlySubscriptionFee[budgetRange])}
            handleNextClick={handleNextClick}
          />
        }, [ budgetRange ]),
      },

      BuyMembership: {
        render: useCallback(() => {
          const router = useIonRouter()
          const goBack = () => router.goBack()

          const product = budgetRangeMonthlySubscriptionStripeProduct[budgetRange]
          const makeStripePayment = useStripePaymentForLeadPaymentHandler()

          const [ isPaymentInProgress, setIsPaymentInProgress ] = useState(false)
          const onButtonClick = () => {
            if (!isPaymentInProgress) {
              setIsPaymentInProgress(true)
              makeStripePayment({ product, leadId })
            }
          }

          const subscriptionPerksList = [
            `Receive leads in the ${budgetRangeLabels[budgetRange]} budget bracket for 12 months`,
            "Free to tender",
            "Satisfaction Money back guarantee!*",
            "Cancel your subscription at any time!*",
            "No further fees even if you win a tender",
          ]
          const title = `Get unlimited ${budgetRangeLabels[budgetRange]} leads`
          const paymentFee = moneyToText(budgetRangeMonthlySubscriptionFee[budgetRange])

          return <MembershipPayPerLead
            title={title}
            list={subscriptionPerksList}
            budgetRange={budgetRangeLabels[budgetRange]}
            paymentFee={paymentFee}
            isSubscriptionFee={true}
            navigateTo={onButtonClick}
            goBack={goBack}
          />
        }, [ budgetRange ]),
      },

      BuyLead: {
        render: useCallback(() => {
          const router = useIonRouter()
          const goBack = () => router.goBack()

          const makeStripePayment = useStripePaymentForLeadPaymentHandler()
          const product = budgetRangeLeadFeeStripeProduct[budgetRange]

          const [ isPaymentInProgress, setIsPaymentInProgress ] = useState(false)
          const onButtonClick = () => {
            if (!isPaymentInProgress) {
              setIsPaymentInProgress(true)
              makeStripePayment({ product, leadId })
            }
          }

          const payPerLeadPerksList = [
            "Free to tender",
            "Satisfaction Money back guarantee!*",
            "No monthly commitment",
            "No further fees even if you win a tender",
          ]

          const paymentFee = moneyToText(budgetRangeLeadFee[budgetRange])

          return <MembershipPayPerLead
            title={"Pay per lead"}
            list={payPerLeadPerksList}
            budgetRange={budgetRangeLabels[budgetRange]}
            paymentFee={paymentFee}
            isSubscriptionFee={false}
            navigateTo={onButtonClick}
            goBack={goBack}
          />
        }, [ budgetRange ]),
      },

      Finished: {
        render: useCallback(() => {
          const history = useHistory()

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const acceptLead = useAcceptLeadMutation(gqlDataSource)

          return <>
            <IonContent className="ion-padding">
              {!weaverFlags["contractor-lead-acceptor"].enabled
                ? <IonButton onClick={() => {
                  // TODO: redirect to the success screen URL
                  // (which is where we also redirect to after Stripe)

                  history.push('/projects')
                }}>
                  All Done!
                </IonButton>
                : <IonButton onClick={async () => {
                  // Legacy button for accepting leads if we're disabled
                  await acceptLead.mutateAsync({ id: leadId })
                  history.push('/projects')
                }}>
                  Accept Lead
                </IonButton>
              }
            </IonContent>
          </>
        }, [ weaverFlags ]),
      },
    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default ContractorLeadAcceptor
