import { useState } from "react"
import { Duration, DurationObjectUnits } from "luxon"
import useAbortableEffect from "./useAbortableEffect"

export const useTickingTimer = (durationObject: DurationObjectUnits): boolean => {
  const [ hasTimePassed, setHasTimePassed ] = useState(false)

  useAbortableEffect(status => {
    const duration = Duration.fromObject(durationObject)
    window.setTimeout(() => {
      // If I am still mounted, after the duration has passed, update the state
      if (!status.aborted) setHasTimePassed(true)
    }, duration.as("milliseconds"))
  }, [])

  return hasTimePassed
}

export default useTickingTimer
