import React from "react"
import { IonButton, IonContent, IonFooter, IonIcon, IonPage, IonToolbar } from "@ionic/react"
import { useRouteToContractorLeadPreferences, useRouteToUnlockContractorBudgetRange } from "../../../../routes"
import  { GenericSelectorOption } from "../../../../common/components/GenericSelectorList"
import ListBudgetRanges from "./ListBudgetRanges"
import { budgetRangeLabels } from "./budgetRanges.i18n"
import { arrowBackOutline, lockClosed, lockOpen } from 'ionicons/icons'
import { createValidCssClassname } from "../../../../common/utils/string"
import { convertToLockedBudgetRangeStatuses, useMyContractorProfile } from "./datasource"
import { BudgetRange, ProgressionStatus } from "../../../../graphql/generated"
import { objectEntries } from "../../../../common/utils/objects"
import Styles from "./ContractorBudgetRanges.module.scss"
import { progressionStatusSubtextLabel } from "./progressionStatus.i18n"
import ResponsiveContentWrapper from "../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"

type IonIconPropsIcon = Parameters<typeof IonIcon>['0']['icon']
export const progressionStatusIcons: Partial<Record<ProgressionStatus, IonIconPropsIcon>> = {
  [ProgressionStatus.NotStarted]: lockClosed,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
  [ProgressionStatus.WaitingOnWeaver]: lockOpen,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
}

// Local Helpers
const getLockedBudgetRangeGenericSelectorOptions = (lockedBudgetRangeStatuses: Record<BudgetRange, ProgressionStatus>, filterTo?: ProgressionStatus[]): GenericSelectorOption<BudgetRange>[] =>
  objectEntries(lockedBudgetRangeStatuses)
    .filter(([ , lockedBudgetRangeStatus ]) => filterTo === undefined || filterTo.includes(lockedBudgetRangeStatus))
    .map(([ budgetRange, lockedBudgetRangeStatus ]) => ({
      key: budgetRange,
      label: budgetRangeLabels[budgetRange],
      itemSubtext: progressionStatusSubtextLabel[lockedBudgetRangeStatus],
      value: budgetRange,
      itemClassName: Styles[createValidCssClassname(lockedBudgetRangeStatus )],
      itemSuffix: <IonIcon icon={progressionStatusIcons[lockedBudgetRangeStatus]} />,
    }))

const ContractorBudgetRanges: React.FC = () => {
  const goToContractorLeadPreferences = useRouteToContractorLeadPreferences()
  const goToUnlockContractorBudgetRange = useRouteToUnlockContractorBudgetRange()

  const myContractorProfile = useMyContractorProfile()
  const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.workHistory)

  return (
    <IonPage>
      <IonContent className={`ion-padding ${Styles.content}`} fullscreen>
        <ResponsiveContentWrapper >
          <h3>Pick a budget range to unlock leads</h3>
          <ListBudgetRanges
            heading="Unlocked Budget Ranges"
            options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.Completed ])}
            onSelect={selectedBudgetRange => selectedBudgetRange && goToUnlockContractorBudgetRange(selectedBudgetRange)()}
          />
          <ListBudgetRanges
            heading="Locked Budget Ranges"
            options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.WaitingOnUser, ProgressionStatus.WaitingOnWeaver, ProgressionStatus.NotStarted ])}
            onSelect={selectedBudgetRange => selectedBudgetRange && goToUnlockContractorBudgetRange(selectedBudgetRange)()}
          />
        </ResponsiveContentWrapper>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="secondary" slot="start" onClick={goToContractorLeadPreferences()}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default ContractorBudgetRanges
