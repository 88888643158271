import React, { useEffect, useState } from 'react'

import UploadableFileChipErrored from './UploadableFileChipErrored'
import UploadableFileChipInProgress from './UploadableFileChipInProgress'
import UploadableFileSuccededChip from './UploadableFileChipSucceded'
import UploadableFileChipUnstarted from './UploadableFileChipUnstarted'

type UploadableFileChipProps = {
  // The file to be uploaded
  file: File,
  // The function to create a Document in the server. This function is expected to return the id of the document created and the Url to upload the file
  createServerDocument: (file: File) => Promise<{ id: string, signedUrlForUpload: string } | undefined>,
  // This function is called when a Chip is removed if there was a Document associated to it.
  archiveServerDocument: (documentId: string) => void,
  // This function is called when a file is removed by the user.
  fileRemovedCallback: (file: File) => void,
}

export enum UploadStatus {
  Unstarted = 'Unstarted',
  InProgress = 'InProgress',
  Error = 'Error',
  Success = 'Success',
}

/**
 * Represents a Chip that the first time that's rendered it will call the createDocument function in order to obtain a upload Url. If the previous step is successful the specified File is uploaded using the Url.
 * In this scenario a Document represents something in the Database server, while File is something local, associated to the device's File system.
 */
export const UploadableFileChip: React.FC<UploadableFileChipProps> = ({ file, createServerDocument, archiveServerDocument, fileRemovedCallback }) => {
  const [ uploadStatus, setUploadStatus ] = useState<UploadStatus>(UploadStatus.Unstarted)
  const [ uploadUrl, setUploadUrl ] = useState("")
  const [ documentId, setDocumentId ] = useState<string | undefined>(undefined)

  const onRemoveFileClicked = (file: File) => {
    // Don't let the user remove the card while the upload is in progress
    if (uploadStatus === UploadStatus.InProgress)
      return undefined

    const wasDocumentCreated = documentId !== undefined
    if (wasDocumentCreated){
      archiveServerDocument(documentId)
    }

    fileRemovedCallback(file)
  }

  const retryUpload = () => uploadFile(uploadUrl)

  const uploadFile = async (uploadUrl: string) => {
    setUploadStatus(UploadStatus.InProgress)
    await fetch(uploadUrl, { method: "PUT", body: file })
      .then(result => {
        setUploadStatus(result.status === 200 ? UploadStatus.Success : UploadStatus.Error)
      }).catch(error => {
        setUploadStatus(UploadStatus.Error)
        console.log(error)
      })
  }

  useEffect(() => {
    const attemptToCreateDocument = async () => {
      const result = await createServerDocument(file)
        .catch((error: Error) => {
          console.log('Error while creating the document ', error)
          throw error
        })
      if (result) {
        setDocumentId(result.id)
        setUploadUrl(result.signedUrlForUpload)
        await uploadFile(result.signedUrlForUpload)
      }
    }
    if (UploadStatus.Unstarted) {
      attemptToCreateDocument()
    }
  }, [])

  if (uploadStatus === UploadStatus.InProgress)
    return <UploadableFileChipInProgress file={file} onRemoveFileClicked={onRemoveFileClicked} />

  if (uploadStatus === UploadStatus.Error)
    return <UploadableFileChipErrored file={file} onRemoveFileClicked={onRemoveFileClicked} retryUpload={retryUpload}  />

  if (uploadStatus === UploadStatus.Success)
    return <UploadableFileSuccededChip file={file} onRemoveFileClicked={onRemoveFileClicked} />

  return <UploadableFileChipUnstarted file={file} onRemoveFileClicked={onRemoveFileClicked}/>
}
