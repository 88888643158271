import React, { useCallback } from "react"
import { useReinitStateAfterWaiting } from "./useReinitStateAfterWaiting"
import { useResettableState } from "./useResettableState"

/** A type representing any enum. */
type BaseEnumType = string | number

/** The properties type for the `ScreenConfig.render(props)` function. */
type ScreenRenderProps<ScreenName> = {
  /**
   * Change the Screen
   * @param {ScreenName} screen The screen to change to when the returned function is called.
   * @returns A stable function which changes to the defined screen.
   */
  useChangeScreen: (screen?: ScreenName) => () => Promise<void> | void,
  /** ScreenName of the previous screen, or undefined if this is the first screen. */
  previousScreen: ScreenName | undefined,
  /** ScreenName of the next screen, or undefined if this is the last screen. */
  nextScreen: ScreenName | undefined,
}

/** The configuration for a given screen. */
type ScreenConfig<ScreenName> = {
  /**
   * Render this screen (very similar to `<Route render />` in React Router 5).
   * Make sure the reference to the component is stable!
   */
  render: React.FC<ScreenRenderProps<ScreenName>>,
}

/** The properties type for the `useScreen<ScreenName>(props)` hook. */
type UseScreenProps<ScreenName extends BaseEnumType> = {
  /** While true, hide the screen and rerun the init when changing from true > false */
  isWaiting?: boolean,
  /** The initial ScreenName to show. */
  init: (() => ScreenName) | ScreenName,
  /** When this value changes in a === comparison, we re-run init. */
  resetWhenValueChanges?: unknown,
  /** The record of the ScreenConfig for each ScreenName. */
  screens: Record<ScreenName, ScreenConfig<ScreenName>>,
}

/**
 * A hook which acts like an in-memory router, rendering an initial screen and supports switching between the screens.
 * @param props.init The initial screen to show.
 * @param props.screens The record of the ScreenConfig for each ScreenName.
 * @returns [
 *   1. The rendered active screen element.
 *   2. The active screen. Useful to use in a wrapping `<IonPage key={activeScreen}>` to trigger animations.
 * ]
 */
export const useScreens = <ScreenName extends BaseEnumType>({ isWaiting, init, resetWhenValueChanges, screens }: UseScreenProps<ScreenName>): [ JSX.Element, ScreenName ] => {
  const [ activeScreen, setActiveScreen ] = useResettableState<ScreenName>(init, resetWhenValueChanges)

  useReinitStateAfterWaiting(isWaiting, setActiveScreen, init)

  // We can be sure that all the screens keys are of type ScreenName
  const screenKeys = Object.keys(screens) as ScreenName[]
  const currentScreenKeyIndex = screenKeys.indexOf(activeScreen)

  // Work out what the next and previous screens are
  const { previousScreen, nextScreen }: Pick<ScreenRenderProps<ScreenName>, 'previousScreen' | 'nextScreen'> =
      // Active Screen not in screens / there are 0-1 screens
      currentScreenKeyIndex === -1 || screenKeys.length < 2
        ? { previousScreen: undefined, nextScreen: undefined }
        // First Screen
        : currentScreenKeyIndex === 0
          ? { previousScreen: undefined, nextScreen: screenKeys[currentScreenKeyIndex + 1] }
          // Last Screen
          : currentScreenKeyIndex === screenKeys.length - 1
            ? { previousScreen: screenKeys[currentScreenKeyIndex - 1], nextScreen: undefined }
            // Middle Screens
            : { previousScreen: screenKeys[currentScreenKeyIndex - 1], nextScreen: screenKeys[currentScreenKeyIndex + 1] }

  const useChangeScreen: ScreenRenderProps<ScreenName>['useChangeScreen'] = screen => useCallback(() => {
    console.debug('[useScreen.useChangeScreen] Change to: ', { activeScreen, screen })
    if (screen === undefined) throw new Error('Unable to change to an undefined screen!')
    setActiveScreen(screen)
  }, [ screen ])

  console.debug('[useScreen] Render: ', { isWaiting, resetWhenValueChanges, init, activeScreen })

  return [
    isWaiting === true
      ? <></>
      : React.createElement(screens[activeScreen].render, { useChangeScreen, previousScreen, nextScreen }),
    activeScreen,
  ]
}
