import React, { useCallback, useState } from "react"
import { IonButton, IonContent, IonFooter, IonIcon, IonImg, IonItem, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { useRouteToUnlockContractorBudgetRange } from "../../../../../../routes"
import { useHistory, useParams } from "react-router"
import { useScreens } from "../../../../../../common/hooks/useScreens"
import { zodResolver } from "@hookform/resolvers/zod"
import { zWorkHistoryReference } from "../workHistoryTypes"
import { useForm } from "react-hook-form"
import { getWorkHistoryById, useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import { alwaysArray, sleep } from "../../../../../../common/utils"
import { z } from "zod"
import { BudgetRange, TeamType, useCreateContractorProfileWorkHistoryReferenceMutation } from "../../../../../../graphql/generated"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { arrowBackOutline, arrowForwardOutline, personAddOutline } from "ionicons/icons"
import ProjectAvatar from "../../../../../projects/ProjectAvatar"
import FormInputAndErrors from "./FormInputAndErrors"
import MobileNumberCapture from "../../../../../../common/components/MobileNumberCapture"
import { E164Number } from "libphonenumber-js/types"
import searchSvg from "../../../../../../assets/icons/search.svg"
import IndicatorBar from "../../../../../projects/CreateProject/IndicatorBar"
import ConfettiPage from "../../../../../../common/components/ConfettiPage"
import TitleWithDescriptionPage from "../../../TitleWithDescriptionPage"
import Styles from "./WorkHistoryReferences.module.scss"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import LoadingSpinner from "../../../../../../common/components/LoadingSpinner"
import SingleClickButton from "../../../../../../common/components/SingleClickButton"
import { useMemo } from "@storybook/addons"

enum WorkHistoryReferencesScreens {
  ListReferences = "ListReferences",
  ReferenceNameInstructions = "ReferenceNameInstructions",
  SelectReferenceRole = "SelectReferenceRole",
  EnterArchitectDetails = "EnterArchitectDetails",
  EnterHomeownerDetails = "EnterHomeownerDetails",
  Finished = "Finished",
}

const zWorkHistoryReferenceForm = z.object({ reference: zWorkHistoryReference })
type WorkHistoryReferenceForm = z.infer<typeof zWorkHistoryReferenceForm>

const WorkHistoryReferences: React.FC = () => {
  const history = useHistory()
  const { budgetRange, workHistoryId } = useParams<{ budgetRange: BudgetRange, workHistoryId: string }>()

  const myContractorProfile = useMyContractorProfile()
  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const triggerEventWorkHistoryReferencesAddedPastProjectReference = useAnalyticsEvent('WorkHistory_References_Added_Past_Project_Reference')
  const triggerEventWorkHistoryReferenceAdded = useAnalyticsEvent('WorkHistory_References_Added')

  const goToUnlockContractorBudgetRange = useRouteToUnlockContractorBudgetRange()

  const { reset, register, trigger, getValues, setValue, formState: { errors } } = useForm<WorkHistoryReferenceForm>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(zWorkHistoryReferenceForm),
  })

  const [ Screen, activeScreen ] = useScreens<WorkHistoryReferencesScreens>({
    init: () => {
      reset()
      return WorkHistoryReferencesScreens.ListReferences
    },
    resetWhenValueChanges: history.location.search,
    screens: {

      ListReferences: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)
          const onClickFinish = useChangeScreen(WorkHistoryReferencesScreens.Finished)
          const references = alwaysArray(workHistory?.references)

          return <>
            <IonContent className={`${Styles.addReferenceContainer} ion-padding`} fullscreen>
              <ResponsiveContentWrapper>
                <h3><b>References</b></h3>
                {references.map(reference =>
                  <IonItem
                    key={reference.id}
                    button
                    detail={false}
                    className={Styles.referenceDetailItem}>
                    <ProjectAvatar title={reference.givenName} slot={"start"} />
                    <div className={Styles.labelContainer}>
                      <p key={reference.id}>{reference.givenName} {reference.familyName}</p>
                      <p>{reference.teamType}</p>
                    </div>
                  </IonItem>,
                )}
                <IonItem
                  button
                  detail={false}
                  className={Styles.addReferenceButton}
                  onClick={onClickNext}>
                  <IonIcon icon={personAddOutline} />
                  <p>Add {references.length ? 'another ' : 'a '}reference name for this project</p>
                </IonItem>
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRange(budgetRange)}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                {references.length > 0 && <IonButton slot="end" onClick={onClickFinish}>Finish<IonIcon icon={arrowForwardOutline} /></IonButton>}
              </IonToolbar>
            </IonFooter>
          </>
        }, [ workHistory, budgetRange ]),
      },

      ReferenceNameInstructions: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <IonContent fullscreen className={Styles.referenceInputCoverPage}>
              <ResponsiveContentWrapper>
                <TitleWithDescriptionPage
                  topDivContents={<IonImg src={searchSvg} className={Styles.customIonImg} />}
                  title='Input a reference name'
                  titleClassName='ion-text-center'
                  description={`Obtaining references helps build trust with the project owners and makes your profile stand out from the rest.
  Note, you will only be able to accept leads once this step is marked as complete.`}
                />
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      SelectReferenceRole: {
        render: useCallback(({ useChangeScreen, previousScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickArchitect = useChangeScreen(WorkHistoryReferencesScreens.EnterArchitectDetails)
          const onClickHomeowner = useChangeScreen(WorkHistoryReferencesScreens.EnterHomeownerDetails)

          const resetFormThen = (then: () => unknown) => () => {
            reset()
            then()
          }

          return <>
            <IonContent className={`${Styles.selectReferenceRoleContainer} ion-padding`} fullscreen>
              <ResponsiveContentWrapper>
                <div className={Styles.indicatorContainer}>
                  <IndicatorBar currentPageIndex={0} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
                </div>
                <h3>Select the reference role for this past project</h3>
                <div className={Styles.referenceButtonsContainer}>
                  <IonButton onClick={resetFormThen(onClickArchitect)}><span>Architect</span></IonButton>
                  <IonButton onClick={resetFormThen(onClickHomeowner)}><span>Homeowner</span></IonButton>
                </div>
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar} >
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      EnterArchitectDetails: {
        render: useCallback(({ useChangeScreen }) => {
          const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
          const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const createMutation = useCreateContractorProfileWorkHistoryReferenceMutation(gqlDataSource)
          const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

          const references = alwaysArray(workHistory?.references)

          const handleChange = (value: E164Number | undefined) => {
            const result = value !== undefined ? `${value}` : ""
            setValue('reference.phoneNumber', result)
          }

          const [ clickInProgress, setClickInProgress ] = useState(false)

          const onClickNextIfFieldIsValid = async () => {
            setClickInProgress(true)

            const isValid = await trigger('reference')

            if (isValid) {
              await createMutation.mutateAsync({
                input: {
                  id: workHistoryId,
                  reference: {
                    teamType: getValues('reference.teamType'),
                    companyName: getValues('reference.companyName'),
                    givenName: getValues('reference.givenName'),
                    familyName: getValues('reference.familyName'),
                    email: getValues('reference.email'),
                    phoneNumber: getValues('reference.phoneNumber'),
                  },
                },
              })

              await triggerEventWorkHistoryReferencesAddedPastProjectReference({
                type: 'Architect',
                totalNumberOfReferences: references.length,
              })

              await triggerEventWorkHistoryReferenceAdded({
                referenceType: getValues('reference.teamType'),
                referenceId: workHistoryId,
                referenceEmail: getValues('reference.email'),
                referencePhone: getValues('reference.phoneNumber'),
                referenceGivenName: getValues('reference.givenName'),
                referenceFamilyName: getValues('reference.familyName'),
                referenceCompanyName: getValues('reference.companyName'),
                totalNumberOfReferences: references.length,
              })

              await invalidateMyContractorProfile()

              onClickNext()
            }

            setClickInProgress(false)
          }

          return <>
            <IonContent className={`ion-padding ${Styles.referenceInputContainer}`} fullscreen>
              <ResponsiveContentWrapper>
                <div className={Styles.indicatorContainer}>
                  <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
                </div>
                <h3>Enter Architect&apos;s Details</h3>
                <FormInputAndErrors
                  label={"Architect Company Name*"}
                  name={"reference.companyName"}
                  register={register}
                  disabled={clickInProgress}
                  errorMessage={errors.reference && errors.reference.companyName} />
                <FormInputAndErrors
                  label={"First name*"}
                  name={"reference.givenName"}
                  register={register}
                  disabled={clickInProgress}
                  errorMessage={errors.reference && errors.reference.givenName} />
                <FormInputAndErrors
                  label={"Last name*"}
                  name={"reference.familyName"}
                  register={register}
                  disabled={clickInProgress}
                  errorMessage={errors.reference && errors.reference.familyName} />
                <FormInputAndErrors
                  label={"Email*"}
                  name={"reference.email"}
                  register={register}
                  disabled={clickInProgress}
                  errorMessage={errors.reference && errors.reference.email} type={"email"} />
                <MobileNumberCapture
                  customClassName={Styles.customMobileInput}
                  textHeading="Phone number*"
                  textFieldPhoneNumberTitle=""
                  value={getValues('reference.phoneNumber')}
                  onChange={handleChange} error={errors.reference && errors.reference.phoneNumber}
                  disabled={clickInProgress}
                  errorMessage={errors.reference && errors.reference.phoneNumber?.message} />
                <input value={TeamType.Architect} {...register("reference.teamType")} type="hidden" />
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack} disabled={clickInProgress}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <SingleClickButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ workHistory, trigger, errors ]),
      },

      EnterHomeownerDetails: {
        render: useCallback(({ useChangeScreen }) => {
          const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
          const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const createMutation = useCreateContractorProfileWorkHistoryReferenceMutation(gqlDataSource)
          const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

          const references = alwaysArray(workHistory?.references)

          const handleChange = (value: E164Number | undefined) => {
            const result = value !== undefined ? `${value}` : ""
            setValue('reference.phoneNumber', result)
          }

          const onClickNextIfFieldIsValid = async () => {
            const isValid = await trigger('reference')
            if (isValid) {
              await createMutation.mutateAsync({
                input: {
                  id: workHistoryId,
                  reference: {
                    teamType: getValues('reference.teamType'),
                    givenName: getValues('reference.givenName'),
                    familyName: getValues('reference.familyName'),
                    email: getValues('reference.email'),
                    phoneNumber: getValues('reference.phoneNumber'),
                  },
                },
              })

              await triggerEventWorkHistoryReferencesAddedPastProjectReference({
                type: 'Homeowner',
                totalNumberOfReferences: references.length,
              })

              await triggerEventWorkHistoryReferenceAdded({
                referenceType: getValues('reference.teamType'),
                referenceId: workHistoryId,
                referenceEmail: getValues('reference.email'),
                referencePhone: getValues('reference.phoneNumber'),
                referenceGivenName: getValues('reference.givenName'),
                referenceFamilyName: getValues('reference.familyName'),
                referenceCompanyName: getValues('reference.companyName'),
                totalNumberOfReferences: references.length,
              })

              await invalidateMyContractorProfile()

              onClickNext()
            }
          }

          return <>
            <IonContent className={`ion-padding ${Styles.referenceInputContainer}`} fullscreen>
              <div className={Styles.indicatorContainer}>
                <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
              </div>
              <ResponsiveContentWrapper>
                <h3>Enter Homeowner Details</h3>
                <input  {...register("reference.teamType")} value={TeamType.Homeowner} type="hidden" />
                <FormInputAndErrors
                  label={"First name*"}
                  name={"reference.givenName"}
                  register={register}
                  errorMessage={errors.reference && errors.reference.givenName} />
                <FormInputAndErrors
                  label={"Last name*"}
                  name={"reference.familyName"}
                  register={register}
                  errorMessage={errors.reference && errors.reference.familyName} />
                <FormInputAndErrors
                  label={"Email*"}
                  name={"reference.email"}
                  register={register}
                  type={"email"}
                  errorMessage={errors.reference && errors.reference.email} />
                <MobileNumberCapture
                  customClassName={Styles.customMobileInput}
                  textHeading="Phone number*"
                  textFieldPhoneNumberTitle=""
                  value={getValues('reference.phoneNumber')}
                  onChange={handleChange} error={errors.reference && errors.reference.phoneNumber}
                  errorMessage={errors.reference && errors.reference.phoneNumber?.message} />
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ workHistory, trigger, errors ]),
      },

      Finished: {
        render: useCallback(() => {
          return <>
            <ConfettiPage caption='Amazing!!!' message='
            You can speed things along by letting your reference know that Weaver will will be in touch.
            You will get a notification once the details have been verified.
            This process usually takes about a week, depending on how quickly your reference responds to us.'/>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={goToUnlockContractorBudgetRange(budgetRange)}>Continue</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ budgetRange ]),
      },

    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default WorkHistoryReferences
