import { useEffect, useRef } from "react"

const useHasBeenUnmounted = () => {
  const hasBeenUnmounted = useRef(false)

  // The effect that detects if we've been unmounted
  useEffect(() => {
    return () => {
      hasBeenUnmounted.current = true
    }
  }, [])

  return hasBeenUnmounted
}

export default useHasBeenUnmounted
