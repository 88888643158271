import React from 'react'
import { IonSpinner } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"

const ThumbnailPhotoInProgress: React.FC = () => {
  return (
    <div className={`${Styles.thumbnailPhotoContainer} ${Styles.success}`}>
      <IonSpinner className={Styles.spinner} color="black" />
    </div>
  )
}

export default ThumbnailPhotoInProgress
