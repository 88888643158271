import React from 'react'
import {  IonContent, IonPage  } from '@ionic/react'
import { useParams } from 'react-router'
import { useShowLeadQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { alwaysArray } from '../../../common/utils'
import ContractorLeadAcceptor from './ContractorLeadAcceptor'
import { getBudgetRangeByAmountInPennies } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { useMyContractorProfile } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'

const DisplayContractorLeadAcceptor: React.FC = () => {
  const { leadId } = useParams<{ leadId: string }>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const useShowLeadResponse = useShowLeadQuery(gqlDataSource , { id: leadId }, { refetchOnWindowFocus: false })
  const leadBudgetAmountInPennies = useShowLeadResponse.data?.getLead.budgetValue.amountInPence
  const leadBudgetRange = getBudgetRangeByAmountInPennies(leadBudgetAmountInPennies)

  const { getContractorProfileQuery: useGetContractorProfileResponse } = useMyContractorProfile()
  const hasBudgetRangeSubscription = alwaysArray(useGetContractorProfileResponse.data?.getContractorProfile?.budgetRangeSubscriptions)
    .some(subscription => subscription.budgetRange === leadBudgetRange)

  console.debug(`[DisplayContractorLeadAcceptor] Render: `, { leadId, useShowLeadResponse, useGetContractorProfileResponse, hasBudgetRangeSubscription })

  if (useShowLeadResponse.isFetching || useGetContractorProfileResponse.isFetching) {
    return <LoadingSpinner name="DisplayContractorLeadAcceptor" />
  }

  if (useShowLeadResponse.error || useGetContractorProfileResponse.error || leadBudgetRange == null) {
    console.error(`[DisplayContractorLeadAcceptor] Failed to load Contractor WorkHistory item: `, { useShowLeadResponse, useGetContractorProfileResponse, leadBudgetAmountInPennies, leadBudgetRange })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={() => {
          useShowLeadResponse.refetch()
          useGetContractorProfileResponse.refetch()
        }} />
      </IonContent>
    </IonPage>
  }

  return <ContractorLeadAcceptor
    leadId={leadId}
    budgetRange={leadBudgetRange}
    hasBudgetRangeSubscription={hasBudgetRangeSubscription}
  />
}

export default DisplayContractorLeadAcceptor
