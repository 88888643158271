import React from 'react'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import ChatFooter from './footer/ChatFooter'
import { Route, useParams, useRouteMatch } from 'react-router'
import ChatContent from './ChatContent'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useGetChatRoom } from '../../../api/services/chat/useGetChatRoom'
import { ensureTrailingSlash } from '../../../common/utils'
import { FilePicker } from './filePicker/FilePicker'
import ChatRoomProvider  from './services/ChatRoomProvider'
import Styles from "./Chat.module.scss"
import GlobalHeader from '../../../common/components/GlobalHeader'
import { isRunningLocallyInDevelopment } from '../../../common/utils/ionic'
import ChatRoomPresenceTracker from './ChatRoomPresenceTracker'

const PACKAGE = 'chat'

export const ChatRoomLoader: React.FC = () => {
  const LOCAL_PACKAGE = `${PACKAGE}.ChatRoomLoader`
  const { id: chatRoomId } = useParams<{id: string}>()

  const { chatRoom } = useGetChatRoom({ chatRoomId })

  console.debug(`[${LOCAL_PACKAGE}] Render `, { chatRoomId, chatRoom })

  if (chatRoom === undefined)
    return <LoadingSpinner name={`${LOCAL_PACKAGE}.undefined`} />

  if (chatRoom === null)
    return <p>There is an error loading the chatroom!</p>

  return <ChatRouter chatRoomName={chatRoom.name} chatRoomId={chatRoom.id} />
}

const ChatRouter: React.FC<{ chatRoomId: string, chatRoomName: string | null | undefined }> = ({ chatRoomId, chatRoomName }) => {
  const match = useRouteMatch()

  return (
    <IonPage>
      <ChatRoomProvider chatRoomId={chatRoomId} chatRoomName={chatRoomName}>
        <ChatRoomPresenceChecker>
          <IonRouterOutlet key="chat">
            <Route path={match.url} exact >
              <ChatRoom chatRoomName={chatRoomName}  />
            </Route>
            <Route path={`${ensureTrailingSlash(match.url)}file-picker`} component={FilePicker} exact />
          </IonRouterOutlet>
        </ChatRoomPresenceChecker>
      </ChatRoomProvider>
    </IonPage>
  )
}

const ChatRoomPresenceChecker : React.FC = ({ children }) => {
  const shouldTrackUserPresence = !isRunningLocallyInDevelopment
  return shouldTrackUserPresence
    ? <ChatRoomPresenceTracker>
      <>{children}</>
    </ChatRoomPresenceTracker>
    :  <>{children}</>
}

const ChatRoom: React.FC<{ chatRoomName: string | null | undefined }> = ({ chatRoomName }) => {
  return (
    <IonPage className={Styles.chatPage}>
      <GlobalHeader pageTitle={chatRoomName|| 'My Chat'} />
      <ChatContent />
      <ChatFooter />
    </IonPage>
  )
}

export default ChatRoomLoader
