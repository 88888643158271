import React, { useState } from 'react'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButton, IonIcon } from '@ionic/react'
import { ProjectMemberRole, TeamType, useAddProjectInviteMutation, useDevListAllProjectsForSelectQuery, useDevListProjectsForSelectQuery, useDevRemoveIndividualFromTeamMutation } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { getActiveTeam, useMyIndividual } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useAuthContext } from '../../../api/providers/AuthProvider'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import { warningOutline } from 'ionicons/icons'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { alwaysArray } from '../../../common/utils'

const DevSettingsPage: React.FC = ({ children }) =>
  <IonPage id="dev-settings">
    <IonHeader>
      <IonToolbar>
        <IonTitle>Developer Settings: Manage Leads</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className='ion-padding'>
      {children}
    </IonContent>
  </IonPage>

const NopeBlock: React.FC = ({ children }) =>
  <DevSettingsPage>
    <CentralisedBlock>
      <IonIcon icon={warningOutline} size="large" color="danger" />
      <p>{children}</p>
    </CentralisedBlock>
  </DevSettingsPage>

const ManageLeads: React.FC = () => {
  const auth = useAuthContext()

  const myIndividual = useMyIndividual()
  const activeTeam = getActiveTeam(myIndividual)
  const activeTeamId = activeTeam?.id

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const devListAllProjects = useDevListAllProjectsForSelectQuery(gqlDataSource, {}, { staleTime: 500_000 })
  const createLead = useAddProjectInviteMutation(gqlDataSource)

  const [ selectedProjectId, setSelectedProjectId ] = useState<string | undefined>(undefined)

  // Guards
  if (!(auth.userData?.email ?? '').endsWith('@weaver.build')) {
    return <NopeBlock>You must be a Weaver user.</NopeBlock>
  }

  if (!activeTeamId || activeTeam?.type !== TeamType.Contractor) {
    return <NopeBlock>You must be a Contractor.</NopeBlock>
  }

  if (devListAllProjects.isFetching) {
    return <LoadingSpinner name='ManageLeads.devListProjects' />
  }

  const projects = alwaysArray(devListAllProjects.data?.listAllProjects)
  const cannotCreateLead = !selectedProjectId
  const doCreateLead = async () => {
    if (!selectedProjectId) {
      console.warn('[ManageLeads] Cannot create a lead for a missing Project')
      return
    }

    console.log('[ManageLeads] Creating a project lead for: ', { selectedProjectId, activeTeamId })

    await createLead.mutateAsync({
      projectId: selectedProjectId,
      teamId: activeTeamId,
      projectMemberRole: ProjectMemberRole.CandidateProfessional,
    })

    console.log('[ManageLeads] Done')
  }

  return (
    <DevSettingsPage>
      <h3>Add Lead for Project</h3>
      <p>Select from the projects ({projects.length}), add a lead for the currently logged in Contractor.</p>
      <br />
      <select onChange={e => { setSelectedProjectId(e.target.value == 'none' ? undefined : e.target.value) }}>
        <option value="none">Pick a Project</option>
        {projects.map(project => <option key={project.id} value={project.id}>{project.title} 1</option>)}
      </select>
      <br />
      <br />
      <IonButton disabled={cannotCreateLead} onClick={doCreateLead}>Create Lead (Add Project Invite)</IonButton>
    </DevSettingsPage>
  )
}

export default ManageLeads
