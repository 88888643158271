import { IonContent, IonIcon, IonItem, IonPage, IonText } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ensureTrailingSlash } from '../../../common/utils'
import TeamMembers from '../TeamMembers'
import Styles from '../Profile.module.scss'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import ErrorBlock from '../../../common/components/ErrorBlock'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ProfileHeader from '../ProfileHeader'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type ArchitectProfileProps = {
  id: string,
  canEdit: boolean,
}

export const ArchitectProfile: React.FC<ArchitectProfileProps> = ({ id, canEdit }) => {
  const myTeam = useMyIndividualActiveTeam()
  const isMyTeam = myTeam?.id === id
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(gqlDataSource, { architectTeamId: id })

  const history = useHistory()
  const match = useRouteMatch()

  if (isFetching) {
    return <LoadingSpinner name="ArchitectProfile" />
  }

  if (error) {
    console.error(`[ArchitectProfile] Failed to load ArchitectProfile Profile: `, { id, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[ArchitectProfile] Architect Profile is missing: `, { id, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <p>Unable to find the Architect Profile.</p>
      </IonContent>
    </IonPage>
  }

  const profile = data.getArchitectProfile

  return (
    <>
      <IonPage>
        <ProfileHeader isMyTeam={isMyTeam} />
        <IonContent className={`${Styles.ionContent} ion-padding`} fullscreen>
          <ResponsiveContentWrapper>
            {isMyTeam && canEdit
              ? (
                <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={event => {
                  event.preventDefault()
                  const newLocation = `${ensureTrailingSlash(match.url)}edit`
                  history.push(newLocation)
                }}>
                  <h3>{profile.companyTradingAs}</h3>
                  <IonIcon className={Styles.editIcon} color='primary' src={createOutline} ></IonIcon>
                </IonItem>
              )
              : (
                <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
                  <h3>{profile.companyTradingAs}</h3>
                </IonItem>
              )}

            <IonText className={Styles.lightGrey}>
              <p> Architect </p>
            </IonText>
            <IonText className={Styles.lightGrey}>
              <p>{profile.companyAddress?.formatted_address ?? <em>No Address</em>}</p>
            </IonText>
            <TeamMembers teamId={id} isMyTeam={isMyTeam} />
          </ResponsiveContentWrapper>
        </IonContent>
      </IonPage>
    </>
  )
}

