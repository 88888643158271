import React from "react"
import { IonItem, IonInput, IonText, InputChangeEventDetail } from "@ionic/react"
import Styles from './SumOfWork.module.scss'
import IndicatorBar, { IndicatorBarProps } from "../../../../../../projects/CreateProject/IndicatorBar"
import { FieldError } from "react-hook-form"
import { budgetRangesConfigs } from "../../../budgetRanges"
import { BudgetRange } from "../../../../../../../graphql/generated"
import ResponsiveContentWrapper from "../../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"

type SumOfWorkProps = {
  value: number | undefined | null,
  setValue: (value?: number | undefined | null) => Promise<void> | void,
  indicatorBarProps: IndicatorBarProps,
  budgetRange: BudgetRange,
  error?: FieldError | undefined,
  handleDot?: (value?: boolean | undefined | null) => void,
}

const limitKeyPressesToPattern = (pattern: RegExp): React.KeyboardEventHandler<HTMLIonInputElement> => event => {
  // If invalid character, prevent input
  if (!pattern.test(event.key)) event.preventDefault()
}

const SumOfWork: React.FC<SumOfWorkProps> = ({ value, setValue, indicatorBarProps, error, budgetRange }) => {
  const valueInPounds = value == null ? value : value / 100
  const minBudgetRangeInPounds = Number(budgetRangesConfigs[ budgetRange ].fromInclusive / 100)
  const maxBudgetRangeInPounds = Number(budgetRangesConfigs[ budgetRange ].toExclusive / 100)

  const handleOnIonChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const updatedValueInPounds = Number(e.detail.value)
    const updatedValueInPence = updatedValueInPounds * 100
    setValue(updatedValueInPence)
  }

  return (
    <>
      <IndicatorBar {...indicatorBarProps} />
      <ResponsiveContentWrapper>
        <div className="ion-padding">
          <h3><b>Enter past project&apos;s Sum of Work</b></h3>
          <IonText className={Styles.ionInputLabel}>Sum of Work excluding VAT*</IonText>
          <IonItem className={`${Styles.ionItemInput}`} lines="none">
            <IonInput
              className={Styles.ionInput}
              placeholder={`£${minBudgetRangeInPounds}`}
              value={valueInPounds}
              type="number"
              min={minBudgetRangeInPounds}
              max={maxBudgetRangeInPounds}
              step="1"
              onIonChange={handleOnIonChange}
              onKeyPress={limitKeyPressesToPattern(/[0-9]/)}
            />
          </IonItem>
          <p className={Styles.errorMessage}>{error && error.message}</p>
        </div>
      </ResponsiveContentWrapper>
    </>
  )
}

export default SumOfWork
