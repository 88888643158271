import {  IonAlert, IonButton, IonContent, IonItem, IonLabel, IonList, IonPage, IonText } from '@ionic/react'
import React, { useState } from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import ErrorBlock from '../../../common/components/ErrorBlock'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useGetAllTeamsQuery } from '../../../graphql/generated'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import TeamMemberAvatar from './TeamMemberAvatar'
import Styles from './TeamMembers.module.scss'

interface TeamMembersProps {
  isMyTeam: boolean,
  teamId: string,
}

const TeamMembers: React.FC<TeamMembersProps> = ({ isMyTeam, teamId }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetAllTeamsQuery(gqlDataSource, {}, { enabled: true, refetchOnWindowFocus: false  })
  const [ showNotImplementedAlert, setShowNotImplementedAlert ] = useState(false)

  if (isFetching) {
    return <LoadingSpinner name="TeamMembers" />
  }

  if (error) {
    console.error(`[TeamMembers] Failed to load Team`, { teamId, data, error })
    return <IonPage>
      <IonContent>
        <ErrorBlock onRefresh={refetch}/>
      </IonContent>
    </IonPage>
  }

  if (data?.listAllTeams == null) {
    return <IonPage>
      <IonContent fullscreen>
        <CentralisedBlock>
          <p> Unable to list all the teams</p>
        </CentralisedBlock>
      </IonContent>
    </IonPage>
  }

  const team = data?.listAllTeams.filter(team => team.id === teamId)[0]
  const individualConnections = team.individualConnections

  return (
    <IonList className={Styles.ionList}>
      <IonText>
        <h3> Team members</h3>
      </IonText>
      {isMyTeam ?
        <div className={Styles.inviteTeamMemberText}>
          <p className={Styles.inviteTeamMemberSubtitle}> You can invite additional team members (optional)</p>
          <IonButton onClick={() => setShowNotImplementedAlert(true)}>
            Invite Team Member
          </IonButton>
        </div>
        : null
      }
      { individualConnections.map(individualConnection => {
        const teamName = `${individualConnection.individual?.givenName}${individualConnection.individual?.familyName}`
        return (
          <IonItem key={individualConnection.individual?.id} className={`${Styles.individualConnectionItem} ion-no-padding`} routerLink={`/profile/${team.type}/${team.id}`}>
            <TeamMemberAvatar title={teamName} />
            <IonLabel><h2> {individualConnection.individual?.givenName} {individualConnection.individual?.familyName}</h2></IonLabel>
          </IonItem>
        )
      })
      }

      <IonAlert
        isOpen={showNotImplementedAlert}
        onDidDismiss={() => setShowNotImplementedAlert(false)}
        header={'Not Implemented yet.'}
        buttons={[ 'Dismiss' ]}
      />
    </IonList>
  )
}

export default TeamMembers
