import React from 'react'
import { environment, EnvironmentContext } from '.'

const PACKAGE = 'EnvironmentProvider'

export const EnvironmentProvider: React.FC = ({ children }) => {
  console.log(`[${PACKAGE}] Returning `, { environment })
  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export default EnvironmentProvider
