import React from 'react'
import { v4 as uuid } from 'uuid'
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { RouteComponentProps } from 'react-router'
import { ChatRoomMessage as TChatRoomMessage } from '../../../api/services/chat/useRealtimeChatMessages'
import { ChatRoom as TChatRoom, useGetChatRoom } from '../../../api/services/chat/useGetChatRoom'
import ChatRoomProvider, { useChatRoom } from '../../chats/chatRoom/services/ChatRoomProvider'

const PACKAGE = 'techDemo.chat'

const ChatRoomLoader: React.FC<RouteComponentProps<{ chatRoomId: string }>> = ({ match }) => {
  const LOCAL_PACKAGE = `${PACKAGE}.ChatRoom`

  const chatRoomId = match.params.chatRoomId
  const { chatRoom } = useGetChatRoom({ chatRoomId })
  const { chatRoomMessages, sendChatRoomMessage } = useChatRoom()

  console.debug(`[${LOCAL_PACKAGE}] Render `, { chatRoomId, chatRoom })

  return (
    <ChatRoomProvider chatRoomId={chatRoomId} chatRoomName={chatRoom?.name}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Tech Demo: Chatter</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Tech Demo: Chatter</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div style={{ marginLeft: '1rem' }}>
            {chatRoom === undefined || chatRoomMessages === undefined
              ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useChatRoomWithMessages`} />
              : chatRoom === null
                ? <p>There is an error loading the chatroom!</p>
                : <ChatRoom chatRoom={chatRoom} chatRoomMessages={chatRoomMessages} />
            }
            <IonButton style={{ marginTop: '2em' }} onClick={() => sendChatRoomMessage({
              chatRoomId,
              idFromClient: uuid(),
              content: "A Tech Demo Message",
              sentAt: new Date().toISOString(),
            }, [])}>Send Message</IonButton>
          </div>
        </IonContent>
      </IonPage>
    </ChatRoomProvider>
  )
}

const ChatRoom: React.FC<{ chatRoom: TChatRoom, chatRoomMessages: TChatRoomMessage[] }> = ({ chatRoom, chatRoomMessages }) => {
  return <>
    <h2>Chat Room: {chatRoom.name}</h2>
    {chatRoomMessages.map(message => <ChatRoomMessage key={message.idFromClient} message={message} />)}
  </>
}

const ChatRoomMessage: React.FC<{ message: TChatRoomMessage }> = ({ message }) => {
  return <>
    <h3>Message</h3>
    <div>{message.content}</div>
  </>
}

export default ChatRoomLoader
