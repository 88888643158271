import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'

export const useCamera = () => {
  const takePhoto = () => Camera.getPhoto({
    resultType: CameraResultType.Base64,
    source: CameraSource.Camera,
    allowEditing: false,
    saveToGallery: true,
    quality: 100,
  })

  return {
    takePhoto,
  }
}
