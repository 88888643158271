
import React, { useState } from "react"
import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useDevListProjectsForSelectQuery, useDevProcessProjectActionMutation } from "../../graphql/generated"
import { useGraphQLDataSource } from "../../api/graphql"

const WorkflowSimulator: React.FC = () => {
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const projects = useDevListProjectsForSelectQuery(gqlDataSource)
  const processProjectAction = useDevProcessProjectActionMutation(gqlDataSource)

  const [ selectedProjectId, setSelectedProjectId ] = useState<string|undefined>()
  const [ customActionId, setCustomActionId ] = useState<string|null|undefined>("")
  const [ log, setLog ] = useState<string[]>([])
  const logAppend = (input: string) => setLog([ input, ...log ])

  const handleSendActionButton = async (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    // nothing selected, noop
    if (!selectedProjectId) return

    const actionId = e.currentTarget.dataset.actionId
    if (!actionId) throw new Error("[WorkflowSimulator] badly configured event button (could not locate actionId data)")

    await processProjectAction.mutateAsync({ action: { projectId: selectedProjectId, actionId } })
    logAppend(`Sent ${actionId}`)
  }

  const handleCustomActionSend = async() => {
    if (!customActionId) return
    if (!selectedProjectId) return

    await processProjectAction.mutateAsync({ action: { projectId: selectedProjectId, actionId: customActionId } })
    logAppend(`Sent ${customActionId}`)
  }

  return (
    <IonPage>
      <IonContent>
        <IonList>
          <IonItem>
            <IonSelect
              interface="action-sheet"
              placeholder="Select a Project"
              value={selectedProjectId}
              onIonChange={(e) => setSelectedProjectId(e.detail.value)}
            >
              {projects.data?.listMyProjects.map(project => <IonSelectOption key={project.id} value={project.id}>{project.title}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
        </IonList>

        {selectedProjectId && <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonButton data-action-id="DOCUMENTS_UPLOADED" onClick={handleSendActionButton}>Send DOCUMENTS_UPLOADED</IonButton>
                    <p>In the default workflow:</p>
                    <ul>
                      <li>moves actionableType UPLOAD_DOCUMENTS to Completed</li>
                      <li>moves actionableType SITE_VISIT_AVAILABILITY to NotStarted</li>
                    </ul>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonButton data-action-id="SITE_VISIT_AVAILABILITY_PROVIDED" onClick={handleSendActionButton}>Send SITE_VISIT_AVAILABILITY_PROVIDED</IonButton>
                    <p>In the default workflow:</p>
                    <ul>
                      <li>moves actionableType SITE_VISIT_AVAILABILITY to Completed</li>
                    </ul>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonInput value={customActionId} onIonChange={(e) => setCustomActionId(e.detail.value)} placeholder="A_CUSTOM_WORKFLOW_NAME"></IonInput>
                    <IonButton onClick={handleCustomActionSend}>Send Custom Action</IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonList>
                  {log.map((logItem, ix) => <IonItem key={ix}><IonLabel>{logItem}</IonLabel></IonItem>)}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
        }
      </IonContent>
    </IonPage>
  )
}

export default WorkflowSimulator
