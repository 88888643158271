import React from 'react'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage } from '@ionic/react'
import JwtList from './ListJwts'
import NewJwtForm from './NewJwtForm'

const ManageJWTs: React.FC = () =>
  <IonPage id="dev-settings">
    <IonHeader>
      <IonToolbar>
        <IonTitle>Developer Settings</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <JwtList />
      <NewJwtForm />
    </IonContent>
  </IonPage>

export default ManageJWTs
