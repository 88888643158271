import { DateTime } from 'luxon'

/**
 * Returns a string representation of the DateTime passed as parameter according to the format 'h:mm a'
 *  i.e.: to a string including hours, minutes and the meridiem (am or pm). The meridiem is returned in lowercase.
 * For example: with an input of 2022-03-11T08:55:20+00:00 it should return 08:55 am
 * @param date The input date in ISO 8601 format.
 * @returns A string including hours, minutes and the meridiem in lowercase (am, pm).
 */
export const formatDateToHoursAndMinutesWithMeridiem = (date: string): string => {
  const sentDate = DateTime.fromISO(date)
  const formattedDate = `${sentDate.toFormat('h:mm a').toLowerCase()}`
  return formattedDate
}

/**
 * Returns a string representation of a date into a relative form ('yesterday', '2 days ago', etc.). When the date represents a moment
 * within today it returns a string in the format 'h:mm a'.
 * @param date The input date in ISO 8601 format.
 * @returns A string including hours, minutes and the meridiem in lowercase (am, pm) if the date represents a moment within day. Otherwise returns
 * its relative form.
 */
export const formatDateAsDuration = (date: string): string => {
  const sentDate = DateTime.fromISO(date)
  const relativeDate = sentDate.toRelativeCalendar() || ''
  return relativeDate === 'today' ? formatDateToHoursAndMinutesWithMeridiem(date) : relativeDate
}

/**
 * Converts an ISO 8601 formatted date into a UNIX Epoch number.
 * Where the string passed is not a valid date, it returns undefined (quietly swallowing any errors).
 * This behaviour is to support customer.io unix epoch formats, such as in MW-1063.
 * @param iso ISO 8601 formatted date
 * @returns UNIX Epoch number if valid or undefined in all other cases
 */
export const convertIsoDateToEpochQuietly = (iso: string): number | undefined => {
  const date = DateTime.fromISO(iso)
  const epoch = date.toMillis()
  return isNaN(epoch) ? undefined : epoch
}
