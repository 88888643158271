import { IonButton, IonIcon } from '@ionic/react'
import React from 'react'

import Styles from "./UploadableFileChip.module.scss"
import { closeCircleOutline } from 'ionicons/icons'

export type UploadableFileChipSuccededProps  =
{
  file: File,
  onRemoveFileClicked: (file: File) => void ,
}

const UploadableFileSuccededChip: React.FC<UploadableFileChipSuccededProps> = ({ file, onRemoveFileClicked }) => {
  return (
    <IonButton className={`${Styles.uploadBubble} ${Styles.success}`} fill="clear" size="small" onClick={() => onRemoveFileClicked(file)}>
      <span>{file.name}</span>
      <IonIcon icon={closeCircleOutline}  />
    </IonButton>
  )
}

export default UploadableFileSuccededChip
