import { IonPage, IonRouterOutlet } from '@ionic/react'
import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import NotImplementedPage from '../../../../../common/components/NotImplementedPage'
import { TeamType } from '../../../../../graphql/generated'
import OnboardingHomeownerStageOne from './OnboardingHomeownerStageOne'
import OnboardingHomeownerStageSelector from './OnboardingHomeownerStageSelector'

const baseURL = `/onboarding/team/${TeamType.Homeowner}/`

const OnboardingHomeowner: React.FC = () => {
  const match = useRouteMatch()
  console.debug(`[OnboardingHomeowner] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="onboardingHomeowner">
        <Route path={`${baseURL}`} component={OnboardingHomeownerStageSelector} exact />
        <Route path={`${baseURL}stage1`} component={OnboardingHomeownerStageOne} />
        <Route component={NotImplementedPage} />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default OnboardingHomeowner
