import React from 'react'
import { getFullNameFromIndividual } from './utils'
import Styles from "./Chat.module.scss"
import { TChatRoomMessage, TChatRoomMessageDetailsProps, TChatRoomMessageSentByCurrentUserProp } from './ChatRoomTypes'

export type TChatRoomMessageProps = TChatRoomMessage & TChatRoomMessageDetailsProps  & TChatRoomMessageSentByCurrentUserProp

const ChatRoomMessageDetails: React.FC<TChatRoomMessageDetailsProps> = ({ sentByIndividual }) => {
  return (
    <span className={Styles.chatBottomDetails}>
      <span className={Styles.chatBottomSenderName}>{getFullNameFromIndividual(sentByIndividual)}</span>
    </span>
  )
}

export const ChatMessageBubble: React.FC<TChatRoomMessageProps> = ({ id, content, sentByIndividual, sentByCurrentUser }) => {
  const bubbleParentClass = sentByCurrentUser ? Styles.chatBubbleParentSent : Styles.chatBubbleParentReceived
  const bubbleSentStatusClass = sentByCurrentUser ? Styles.bubbleSent : Styles.bubbleReceived
  return (
    <div className={`${bubbleParentClass}`}>
      <div key={id} className={`${Styles.chatBubble} ${bubbleSentStatusClass}`}>
        <div>
          {content}
        </div>
        <ChatRoomMessageDetails sentByIndividual={sentByIndividual} />
      </div>
    </div>
  )
}

export default ChatMessageBubble
