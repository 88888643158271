import { IonIcon } from "@ionic/react"
import { golfOutline, personAddOutline } from "ionicons/icons"

export enum WorkHistoryMenuItem {
  AddPastProject = "AddPastProject",
  AddReference = "AddReference",
  AddPhotos = "AddPhotos",
}

type IonIconPropsIcon = Parameters<typeof IonIcon>['0']['icon']

export const progressionStatusIcons: Record<WorkHistoryMenuItem, IonIconPropsIcon> = {
  [ WorkHistoryMenuItem.AddPastProject ]: golfOutline,
  [ WorkHistoryMenuItem.AddPhotos ]: personAddOutline,
  [ WorkHistoryMenuItem.AddReference ]: personAddOutline,
}

