import React from 'react'
import { IonItem, IonLabel } from '@ionic/react'
import Styles from './NestedMenu.module.scss'
import { isSomething } from '../../utils'

export type NestedMenuItemOption<T> = {
  key: string,
  title: string,
  prefix?: JSX.Element,
  value: T,
  suffix?: JSX.Element,
  itemClassname?: string,
  isDisabled?: boolean,
}

type NestedMenuItemProps<T> = {
  option: NestedMenuItemOption<T>,
  onSelect: (value : T) => void,
}

const NestedMenuItem =  <T,> ({ option, onSelect } : NestedMenuItemProps<T>) => {
  return (
    <div className={Styles.itemContainer}>
      <IonItem button
        lines='none'
        detail={false}
        disabled={option.isDisabled}
        className={[ Styles.childItem, option.itemClassname ].filter(isSomething).join(' ')}
        onClick={() => onSelect(option.value)}>
        {option.prefix}
        <IonLabel className={Styles.label}>{option.title}</IonLabel>
        {option.suffix}
      </IonItem>
    </div>)
}

export default NestedMenuItem
