import React from 'react'
import * as Ably from "ably/promises"
import useStatefulEffect from '../../../common/hooks/useStatefulEffect'
import { Realtime } from 'ably/promises'
import { useEnvironmentContext } from '../EnvironmentProvider'
import { useAuthorizationHeader } from '../AuthProvider'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { Types } from 'ably'

export const AblyRealtimeContext = React.createContext<Realtime | undefined>(undefined)

const AblyProvider: React.FC = ({ children }) => {
  const environment = useEnvironmentContext()
  const authUrl = environment.apiGateway.ably.url

  const Authorization = useAuthorizationHeader()

  const { state } = useStatefulEffect<Types.RealtimePromise>('ablyRealtime', ({ setState }) => {
    if (authUrl === undefined) return

    console.debug('[AblyProvider.useStatefulEffect] Initalising Ably Realtime Connection')
    const realtime = new Ably.Realtime.Promise({
      authUrl,
      authHeaders: { Authorization },
      closeOnUnload: true,
    })
    setState(realtime)
    console.debug('[AblyProvider.useStatefulEffect] Ably Realtime Connection ready ', realtime)

    return () => {
      console.debug('[AblyProvider.useStatefulEffect] Closing the Ably Realtime Connection')
      realtime.close()
    }
  }, [ environment, Authorization ])

  return state === undefined
    ? <LoadingSpinner name='ablyCreating' />
    : <AblyRealtimeContext.Provider value={state}>
      {children}
    </AblyRealtimeContext.Provider>
}

export default AblyProvider
