import { useEffect } from "react"
import { Types } from "ably"
import { useAblyRealtimeContext } from "./context"

const PACKAGE = 'api.services.core.ably'

export const channelPrefix = 'myweaver:v1'
export const channelPrefix_partyLine = `${channelPrefix}:partyline`

export type AblyChannelSubscriptionCallback = NonNullable<Parameters<Types.RealtimeChannelPromise['subscribe']>[1]>

type UseAblyPartyLineProps = {
  partyLineId: string,
  onMessageFn: AblyChannelSubscriptionCallback,
}

type UseAblyPartyLinePresenceProps = {
  partyLineId: string,
}

const getAblyChannelName = (partyLineId: string) => `${channelPrefix_partyLine}:${partyLineId}`

export const useAblyPartyLinePresence = ({
  partyLineId,
}: UseAblyPartyLinePresenceProps) => {
  const ablyRealtime = useAblyRealtimeContext()

  const enterChannel = async () => {
    const channel = ablyRealtime.channels.get(getAblyChannelName(partyLineId))
    return channel.presence.enter()
  }

  const leaveChannel = async () => {
    const channel = ablyRealtime.channels.get(getAblyChannelName(partyLineId))
    return channel.presence.leave()
  }

  const getMembersPresentInChannel = async (): Promise<Types.PresenceMessage []> => {
    const channel = ablyRealtime.channels.get(getAblyChannelName(partyLineId))
    const members = await channel.presence.get()
    if (!members) return []
    return members
  }

  return { enterChannel, leaveChannel, getMembersPresentInChannel }
}

export const useAblyPartyLine = ({ partyLineId, onMessageFn }: UseAblyPartyLineProps) => {
  const LOCAL_PACKAGE = `${PACKAGE}.useAblyPartyLine`
  const ablyRealtime = useAblyRealtimeContext()

  useEffect(() => {
    console.log('useAblyPartyLine useEffect: partyLineId', partyLineId)

    const channelName = getAblyChannelName(partyLineId)
    const channel = ablyRealtime.channels.get(channelName)

    channel.subscribe('message', onMessageFn)

    console.debug(`[${LOCAL_PACKAGE}] Subscribed to channel `, { channelName, partyLineId })
    return () => {
      console.debug(`[${LOCAL_PACKAGE}] Unsubscribed from channel `, { channelName, partyLineId })
      channel.unsubscribe('message', onMessageFn)
    }
  }, [ ablyRealtime, partyLineId, onMessageFn ])
}

type UseAblyPartyLinePublishProps = {
  partyLineId: string,
}

type AblyPartyLinePublishProps = {
  message: object,
}

export const useAblyPartyLinePublish = ({ partyLineId }: UseAblyPartyLinePublishProps) => {
  const LOCAL_PACKAGE = `${PACKAGE}.ablyPartyLinePublish`
  const ablyRealtime = useAblyRealtimeContext()

  return ({ message }: AblyPartyLinePublishProps) => {
    const channelName = getAblyChannelName(partyLineId)
    console.debug(`[${LOCAL_PACKAGE}] Published to channel `, { channelName, message })
    const channel = ablyRealtime.channels.get(channelName)
    return channel.publish('message', message)
  }
}
