import React from "react"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from "@ionic/react"
import GlobalHeader from "./GlobalHeader/GlobalHeader"

/**
 * Add a generic "this is clearly broken"/404 page For development purposes.
 * This should be useful as a placeholder for deeplink functionality
 */
export const NotImplementedPage: React.FC = () => (
  <IonPage>
    <GlobalHeader/>
    <IonContent>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Not Implemented</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <div>Not Found</div>
          <IonButton expand='full' routerLink='/'>Back</IonButton>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>
)

export default NotImplementedPage
