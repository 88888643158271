import React from "react"
import { useQueryClient } from "react-query"
import { useGraphQLDataSource } from "../../../../api/graphql"
import { useMyIndividualActiveTeam } from "../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { alwaysArray } from "../../../../common/utils"
import { BudgetRange, GetContractorProfileQuery, ProgressionStatus, Team, TeamType, useGetContractorProfileQuery } from "../../../../graphql/generated"
import { budgetRangesConfigs } from "./budgetRanges"

export const useMyContractorProfile = () => {
  const myTeam = useMyIndividualActiveTeam()
  return useContractorProfile(myTeam)
}

export const useContractorProfile = (team?: Pick<Team, 'id' | 'type'>) => {
  const isTeamOfTypeContractor = team?.type === TeamType.Contractor

  const thirtyMinutesInMilliseconds = 30 * 60 * 1000
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getContractorProfileQuery = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: team?.id ?? '' }, {
    enabled: isTeamOfTypeContractor,
    staleTime: thirtyMinutesInMilliseconds,
  })

  return {
    isTeamOfTypeContractor,
    getContractorProfileQuery,
  }
}

export type GetContractorProfileWorkHistory = NonNullable<NonNullable<GetContractorProfileQuery['getContractorProfile']>['workHistory']>[number]

export const useInvalidateMyContractorProfile = () => {
  const myTeam = useMyIndividualActiveTeam()
  const isMyTeamOfTypeContractor = myTeam?.type === TeamType.Contractor

  const queryClient = useQueryClient()
  return () => {
    if (isMyTeamOfTypeContractor) {
      return queryClient.invalidateQueries([ 'getContractorProfile', { contractorTeamId: myTeam.id } ])
    } else {
      return queryClient.invalidateQueries([ 'getContractorProfile' ])
    }
  }
}

// Helpers

export const doesWorkHistoryMatchBudgetRange = (budgetRange: BudgetRange) => (workHistory: GetContractorProfileWorkHistory) => {
  const { fromInclusive, toExclusive } = budgetRangesConfigs[budgetRange]
  const { amountInPence } = workHistory.constructionValue ?? { amountInPence: 0 }
  return amountInPence >= fromInclusive && amountInPence < toExclusive
}

export const getFirstWorkHistoryByBudgetRange = (useMyContractorProfileResponse: ReturnType<typeof useContractorProfile>, budgetRange: BudgetRange): GetContractorProfileWorkHistory | undefined => {
  if (!useMyContractorProfileResponse.isTeamOfTypeContractor) return undefined

  const contractorWorkHistorys = alwaysArray(useMyContractorProfileResponse.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const workHistory = contractorWorkHistorys.find(doesWorkHistoryMatchBudgetRange(budgetRange))

  console.log('[ContractorBudgetRanges.datasource.getFirstWorkHistoryByBudgetRange]', { contractorWorkHistorys, workHistory, budgetRange })
  return workHistory
}

export const getWorkHistoryById = (useMyContractorProfileResponse: ReturnType<typeof useContractorProfile>, workHistoryId: string): GetContractorProfileWorkHistory | undefined => {
  if (!useMyContractorProfileResponse.isTeamOfTypeContractor) return undefined

  const contractorWorkHistorys = alwaysArray(useMyContractorProfileResponse.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const workHistory = contractorWorkHistorys.find(each => each.id === workHistoryId)

  console.log('[ContractorBudgetRanges.datasource.getWorkHistoryById]', { contractorWorkHistorys, workHistory, workHistoryId })
  return workHistory
}

export const getProgressionStatusForWorkHistoryItem = (workHistoryItem: GetContractorProfileWorkHistory): ProgressionStatus => {
  const workHistoryHasWaitingOnWeaverReferences = alwaysArray(workHistoryItem.references)
    .filter(reference => reference.status === ProgressionStatus.WaitingOnWeaver).length > 0
  const workHistoryHasCompletedReferences = alwaysArray(workHistoryItem.references)
    .filter(reference => reference.status === ProgressionStatus.Completed).length > 0

  const workHistoryHasAnyPhotos = alwaysArray(workHistoryItem.photos).length > 0

  // Is the WorkHistory item complete?
  if (workHistoryHasCompletedReferences && workHistoryHasAnyPhotos) return ProgressionStatus.Completed

  // Is the WorkHistory item Waiting on Weaver?
  if (workHistoryHasWaitingOnWeaverReferences && workHistoryHasAnyPhotos) return ProgressionStatus.WaitingOnWeaver

  // As the WorkHistory item exists, in all other cases, we are Waiting on User
  return ProgressionStatus.WaitingOnUser
}

export const getProgressionStatusForWorkHistory = (workHistory?: GetContractorProfileWorkHistory[] | null): ProgressionStatus =>
  alwaysArray(workHistory).reduce(reduceWorkHistoryToSingleProgressionStatus, ProgressionStatus.NotStarted)

export const reduceWorkHistoryToSingleProgressionStatus = (resultingStatus: ProgressionStatus, workHistoryItem: GetContractorProfileWorkHistory): ProgressionStatus => {
  // If I'm already Completed, fast-forward
  if (resultingStatus === ProgressionStatus.Completed) return resultingStatus

  // Upgrade the resulting status where the current item status is greater
  const itemStatus = getProgressionStatusForWorkHistoryItem(workHistoryItem)
  switch (resultingStatus) {
    case ProgressionStatus.WaitingOnWeaver:
      return [ ProgressionStatus.Completed ].includes(itemStatus) ? itemStatus : resultingStatus
    case ProgressionStatus.WaitingOnUser:
      return [ ProgressionStatus.Completed, ProgressionStatus.WaitingOnWeaver ].includes(itemStatus) ? itemStatus : resultingStatus
    case ProgressionStatus.NotStarted:
      return [ ProgressionStatus.Completed, ProgressionStatus.WaitingOnWeaver, ProgressionStatus.WaitingOnUser ].includes(itemStatus) ? itemStatus : resultingStatus
    default: return itemStatus
  }
}

export const convertToLockedBudgetRangeStatuses = (workHistory?: GetContractorProfileWorkHistory[] | null): Record<BudgetRange, ProgressionStatus> => {
  const contractorWorkHistorys = alwaysArray(workHistory)

  const getProgressionStatusForBudgetRange = (budgetRange: BudgetRange): ProgressionStatus =>
    contractorWorkHistorys
      .filter(doesWorkHistoryMatchBudgetRange(budgetRange))
      .reduce(reduceWorkHistoryToSingleProgressionStatus, ProgressionStatus.NotStarted)

  const statuses: Record<BudgetRange, ProgressionStatus> = {
    [BudgetRange.F30T100]: getProgressionStatusForBudgetRange(BudgetRange.F30T100),
    [BudgetRange.F100T250]: getProgressionStatusForBudgetRange(BudgetRange.F100T250),
    [BudgetRange.F250T500]: getProgressionStatusForBudgetRange(BudgetRange.F250T500),
    [BudgetRange.F500T1000]: getProgressionStatusForBudgetRange(BudgetRange.F500T1000),
    [BudgetRange.F1000T3000]: getProgressionStatusForBudgetRange(BudgetRange.F1000T3000),
  }

  console.log('[ContractorBudgetRanges.datasource.convertToLockedBudgetRangeStatuses]', { contractorWorkHistorys, statuses })

  return statuses
}

const unlockedBudgetRangeAcceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

export const hasUnlockedBudgetRange = (workHistory: GetContractorProfileWorkHistory[] | undefined | null, budgetRange: BudgetRange | undefined): boolean | undefined => {
  if (workHistory == null || budgetRange == null) return undefined

  const progressionStatus = workHistory
    .filter(doesWorkHistoryMatchBudgetRange(budgetRange))
    .reduce(reduceWorkHistoryToSingleProgressionStatus, ProgressionStatus.NotStarted)

  return unlockedBudgetRangeAcceptableStatuses.includes(progressionStatus)
}
