import React from 'react'
import { DateTime } from 'luxon'
import { fileIconSelector } from '../../../../common/utils/files'
import { formatBytes } from '../../../../common/utils/index'
import { IonThumbnail, IonCard, IonItem, IonLabel, IonImg, IonCheckbox } from '@ionic/react'
import { StoredFile } from '../../../../common/hooks/useDeviceStorageFiles'
import Styles from "./FilePicker.module.scss"

type DeviceStorageFileRowProps = {
  storedFile: StoredFile,
  selected: boolean,
  setSelected: (selected: boolean) => void,
  multiAddState: boolean,
  selectFile: (storedFile: StoredFile) => void,
}

export const DeviceStorageFileRow: React.FC<DeviceStorageFileRowProps> = ({ storedFile, selected, setSelected, multiAddState, selectFile }) =>
  <IonCard className={Styles.deviceStorageFileRow} onClick={() => selectFile(storedFile)}>
    <IonItem>
      {
        multiAddState && <IonCheckbox slot="start" checked={selected} color='light' onIonChange={() => { setSelected(!selected) }} />
      }
      <IonThumbnail>
        <IonImg src={fileIconSelector(storedFile.name)} />
      </IonThumbnail>
      <IonLabel>
        <h3>{storedFile.name}</h3>
        <p>{formatBytes(storedFile.size)}</p>
      </IonLabel>
      <IonLabel slot='end'>
        <p>{DateTime.fromMillis(storedFile.modifiedTime).toLocaleString()}</p>
      </IonLabel>
    </IonItem>
  </IonCard>
