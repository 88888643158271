import React from "react"
import { IonContent, IonGrid, IonImg, IonList, IonText } from "@ionic/react"
import Styles from './ScreenProvidePhotos.module.scss'
import ProjectPhoto from "../../../../../../assets/images/project-photo.png"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"

const ProvidePhotosScreen: React.FC = () => {
  return (
    <div className={Styles.pageContainer}>
      <IonImg className={`${Styles.projectPhoto} ${Styles.centerText}`} src={ProjectPhoto}/>
      <div className="ion-justify-content-center  ion-text-center">
        <IonContent className={`${Styles.textContainer} ion-padding`}>
          <ResponsiveContentWrapper>
            <h3 className={`${Styles.title} ion-text-center`}>
              Provide Project Photos
            </h3>
            <IonGrid>
              <IonList className={`${Styles.centeredText}`}>
                <h6>There are 2 benefits to sharing project photos:</h6>
                <IonText className="ion-padding">
                  <p>1. It helps us conduct reference checks more quickly, so you can start seeing leads.</p>
                </IonText>
                <IonText className="ion-padding">
                  <p>2. It helps homeowners understand - and get excited by - the quality of your work.</p>
                </IonText>
              </IonList>
            </IonGrid>
          </ResponsiveContentWrapper>
        </IonContent>
      </div>
    </div>
  )
}

export default ProvidePhotosScreen
