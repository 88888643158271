import React, { useContext, useEffect, useState } from 'react'
import { Storage, Drivers } from '@ionic/storage'
import { convertNullToUndefined, convertUndefinedToNull } from '../../common/utils'
import useStatefulEffect from '../../common/hooks/useStatefulEffect'
import LoadingSpinner from '../../common/components/LoadingSpinner'

export const StorageContext = React.createContext<Storage>(new Storage())

const StorageProvider: React.FC<{ dbName?: string }> = ({ dbName = 'mwDB', children }) => {
  const { state } = useStatefulEffect<Storage>('storage', ({ setState }) => {
    new Storage({
      name: dbName,
      driverOrder: [ Drivers.IndexedDB, Drivers.LocalStorage ],
    })
      .create()
      .then(setState)
  }, [])

  return state === undefined
    ? <LoadingSpinner name='storageCreating' />
    : <StorageContext.Provider value={state}>
      {children}
    </StorageContext.Provider>
}

export type StorableValue<T> = T | undefined

type StorageAccessorFns<T> = {
  get: () => Promise<StorableValue<T>>,
  put: (newValue: StorableValue<T>) => Promise<void>,
}

type UseStoredValueProps<T> = {
  key: string,
  initialValue?: T,
}
type UseStoredValueReturns<T> = [
  StorableValue<T>,
  (newValue: StorableValue<T>) => Promise<void> | void,
]
/**
 * Use a Browser DB to retrieve and store values against a given key, optionally initialised.
 * Value can be `T | undefined` where `undefined` is transparently converted to `null` for storage.
 *
 * This hook is backed by an async api, so initialValue WILL be be undefined on first render:
 * 1: undefined (unknown, waiting for storage retrieval)
 * 2: initialValue (retrieved initial value)
 *
 * The recommended usage to this is to store a null-object initialState so that your component can tell the difference between
 * unknown and initial states
 *
 * Ionic Storage also CANNOT store nested objects - if you are storing a nested data structure you must stringify and parse it yourself.
 *
 * _**Note:** This should only be used within a `<StorageProvider>` block for defined results._
 *
 * @param props.key Key of the database object to retrieve
 * @param props.initialValue If set, and there's no value in the DB keyspace, it will use this value to initialise with
 * @returns `{ get, put }` accessors to get and set the keyspace value
 */
export const useStoredValue = <T extends object>({ key, initialValue }: UseStoredValueProps<T>): UseStoredValueReturns<T> => {
  const storage = useContext(StorageContext)
  const [ value, setValue ] = useState<StorableValue<T>>()
  console.log('useStoredValue -- starting ', { initialValue, value })

  const accessorFns: StorageAccessorFns<T> = {
    get: () => storage.get(key).then(convertNullToUndefined),
    put: newValue => storage.set(key, convertUndefinedToNull(newValue)),
  }

  useInitialValueIfValueMissing({ accessorFns, initialValue, setValue, deps: [ storage ] })

  return [
    value,
    (newValue: StorableValue<T>) => {
      accessorFns.put(newValue)
      setValue(newValue)
    },
  ]
}

type UseInitialValueIfValueMissingProps<T> = {
  accessorFns: StorageAccessorFns<T>,
  initialValue?: T,
  setValue: UseStoredValueReturns<T>[1],
  deps?: Parameters<typeof useEffect>[1],
}
const useInitialValueIfValueMissing = <T extends object>({ accessorFns, initialValue, setValue, deps }: UseInitialValueIfValueMissingProps<T>) =>
  useEffect(() => {
    // Load the currently stored value
    accessorFns.get()
      .then(storedValue => {
        // Is the currently stored value not missing?
        // Do I have an initialValue to set?
        // Then return the current value
        if (storedValue !== undefined || initialValue === undefined) {
          return storedValue
        }

        // Set the initial value
        // Then return the initial value
        return accessorFns.put(initialValue).then(() => initialValue)
      })
      .then(setValue)
  }, deps)

export default StorageProvider
