import React from "react"
import { IonBackButton, IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar, useIonRouter } from "@ionic/react"
import { getKnownRoutePathForPages, Page } from "../../../routes"
import Styles from "./GlobalHeader.module.scss"

export type GlobalHeaderProps = {
  pageTitle? : string,
}

const GlobalHeader: React.FC <GlobalHeaderProps>= ( { pageTitle } ) => {

  const hideButtonOnThesePages = [
    Page.Projects,
    Page.MyChats,
    Page.Onboarding,
    Page.Profile,
    Page.Help,
    Page.Success,
  ]

  const router = useIonRouter()
  const routePathsToHide = getKnownRoutePathForPages(...hideButtonOnThesePages)
  const hideBackButton = routePathsToHide.find(route => router.routeInfo?.pathname === route)

  const hamburgerOrBackButton = () => {
    if (!hideBackButton) {
      return <IonBackButton className={Styles.backButton} mode="ios" defaultHref="/projects"/>
    } else {
      return <IonMenuButton autoHide={false}></IonMenuButton>
    }
  }

  return (
    <IonHeader className={Styles.globalHeader}>
      <IonToolbar>
        <IonButtons slot="start">
          {hamburgerOrBackButton()}
        </IonButtons>
        { pageTitle && <IonTitle>{pageTitle}</IonTitle> }
      </IonToolbar>
    </IonHeader>
  )
}

export default GlobalHeader
