import { useIonAlert } from '@ionic/react'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useStoredValue } from '../../../api/providers/StorageProvider'
import { useClaimInviteMutation } from '../../../graphql/generated'
import { EMPTY_INVITE, StoredInvite, STORED_INVITE_KEY } from './common'

/**
 * A component for listening to invites in storage, and claiming them
 * Needs to be mounted fairly high up so that the process is triggered early on. (after bootstrapping)
 *
 * Extracted into a component so that listening to location/querystring changes doesn't cause re-renders at a high-level of the app.
 * Renders null
 */
export const InviteClaimer: React.FC = () => {
  const [ storedInvite, setStoredInvite ] = useStoredValue<StoredInvite>({ key: STORED_INVITE_KEY })
  const queryClient = useQueryClient()
  const [ present ] = useIonAlert()

  const activeTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const claimInvite = useClaimInviteMutation(gqlDataSource)

  useEffect(() => {
    async function claim() {
      // not onboarded yet
      if (!activeTeam?.type) return

      const inviteId = storedInvite?.inviteId
      if (!inviteId) return

      if (claimInvite.isLoading) return

      console.debug("[InviteClaimer] claiming invite... ", inviteId)
      try {
        await claimInvite.mutateAsync({ inviteId })
      } catch (e) {
        console.error("[InviteClaimer] failed to claim invite.", inviteId, e)
        if (e instanceof Error) {
          present({
            header: "Oops! We couldn't claim your invite.",
            message: e.message,
            buttons: [
              {
                text: "Dismiss",
                role: 'cancel',
              },
            ],
          })
        }
      }

      console.debug("[InviteClaimer] clearing invite storage")
      setStoredInvite(EMPTY_INVITE)
      await queryClient.invalidateQueries([ "listMyProjectsAndLeads" ])

    }
    claim()
  }, [ activeTeam?.type, storedInvite, queryClient ])

  return null
}
