import { useGraphQLDataSource } from "../../graphql"
import { useRegisterMyDeviceForPushNotificationsMutation } from "../../../graphql/generated"

export const usePushNotifications = () => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const mutationRegisterMyDeviceForPushNotifications = useRegisterMyDeviceForPushNotificationsMutation(gqlDataSource)
  
  const registerDevice = (deviceToken: string) => mutationRegisterMyDeviceForPushNotifications.mutateAsync({ deviceToken })
  
  return {
    registerDevice,
  }
}
