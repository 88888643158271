import React, { useEffect, useState } from 'react'
import { useChatRoom } from '../services/ChatRoomProvider'
import { closeCircleOutline, reloadCircleOutline } from 'ionicons/icons'
import Styles from "./ChatFooter.module.scss"
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { TMutationArchiveChatRoomMessageDocumentFn, TMutationCreateChatRoomMessageDocumentFn } from '../../../../api/services/chat/useRealtimeChatMessages'

enum UploadDocumentStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Error = 'Error',
  Success = 'Success',
}

export type SelectedFileBubbleProps = {
  file: File,
  createChatRoomMessageDocument: (file: File) => ReturnType<TMutationCreateChatRoomMessageDocumentFn>,
  archiveChatRoomMessageDocument: (id: string) => ReturnType<TMutationArchiveChatRoomMessageDocumentFn>,
}

export const SelectedFileBubble: React.FC<SelectedFileBubbleProps> = ({ file, createChatRoomMessageDocument, archiveChatRoomMessageDocument }) => {
  const [ uploadStatus, setUploadStatus ] = useState<UploadDocumentStatus>(UploadDocumentStatus.Initial)
  const [ uploadUrl, setUploadUrl ] = useState("")
  const [ chatMessageDocumentId, setChatRoomMessageDocumentId ] = useState<string | undefined>(undefined)

  const { removeFileFromNewMessage } = useChatRoom()

  const onRemoveSelectedFileClicked = async (file: File) => {
    // Don't let the user remove the card while the upload is in progress
    if (uploadStatus === UploadDocumentStatus.InProgress) return undefined
    if (chatMessageDocumentId) archiveChatRoomMessageDocument(chatMessageDocumentId)

    removeFileFromNewMessage(file)
  }

  const uploadImage = async (uploadUrl: string, fileBlob: File) => {
    return fetch(uploadUrl, {
      method: "PUT",
      body: fileBlob,
    })
  }

  const retryUpload = () => {
    uploadAttachment(uploadUrl)
  }

  const uploadAttachment = async (uploadUrl: string) => {
    setUploadStatus(UploadDocumentStatus.InProgress)
    await uploadImage(uploadUrl, file)
      .then(result => {
        setUploadStatus(result.status === 200 ? UploadDocumentStatus.Success : UploadDocumentStatus.Error)
      }).catch(error => {
        setUploadStatus(UploadDocumentStatus.Error)
        console.log(error)
      })
  }

  useEffect(() => {
    const createAttachment = async () => {
      const result = await createChatRoomMessageDocument(file)
        .catch((error: Error) => {
          console.log('Error while creating the chat room message document ', error)
          throw error
        })
      if (result) {
        setChatRoomMessageDocumentId(result.id)
        console.log('createAttachment result', result)
        setUploadUrl(result.signedUrlForUpload)
        await uploadAttachment(result.signedUrlForUpload)
      }
    }
    if (UploadDocumentStatus.Initial) {
      createAttachment()
    }

  }, [])

  if (uploadStatus === UploadDocumentStatus.InProgress) return <UploadInProgressSelectedBubble file={file} onRemoveSelectedFileClicked={onRemoveSelectedFileClicked} />

  if (uploadStatus === UploadDocumentStatus.Error) return <UploadErroredSelectedBubble file={file} retryUpload={retryUpload} onRemoveSelectedFileClicked={onRemoveSelectedFileClicked} />

  if (uploadStatus === UploadDocumentStatus.Success) return <UploadSuccededSelectedBubble file={file} onRemoveSelectedFileClicked={onRemoveSelectedFileClicked} />

  return <UploadInitialSelectedBubble file={file} />
}

const UploadInProgressSelectedBubble: React.FC<{ file: File, onRemoveSelectedFileClicked: (file: File) => void }> = ({ file, onRemoveSelectedFileClicked }) => {
  return (
    <IonButton className={Styles.uploadInProgressSelectedBubble} fill="clear" size="small" onClick={() => onRemoveSelectedFileClicked(file)}>
      {file.name}
      <IonSpinner className={Styles.uploadingFileStatusSpinner} color="black" />
    </IonButton>
  )
}

const UploadErroredSelectedBubble: React.FC<{ file: File, retryUpload: () => void, onRemoveSelectedFileClicked: (file: File) => void }> = ({ file, retryUpload, onRemoveSelectedFileClicked }) => {
  const onRetryClicked = (event: React.MouseEvent) => {
    // Avoid the outer button receive the event.
    event.stopPropagation()
    retryUpload()
  }

  return (
    <IonButton className={Styles.uploadErroredSelectedBubble} fill="clear" size="small" onClick={() => onRemoveSelectedFileClicked(file)}>
      {file.name}
      <IonButton className={Styles.retryButton} onClick={(e) => onRetryClicked(e)} fill="clear" size="small">
        <IonIcon icon={reloadCircleOutline} />
      </IonButton>
    </IonButton>
  )
}

const UploadSuccededSelectedBubble: React.FC<{ file: File, onRemoveSelectedFileClicked: (file: File) => void }> = ({ file, onRemoveSelectedFileClicked }) => {
  return (
    <IonButton className={Styles.uploadSuccededSelectedBubble} fill="clear" size="small" onClick={() => onRemoveSelectedFileClicked(file)}>
      {file.name}
      <IonIcon icon={closeCircleOutline} className={Styles.uploadSuccededIcon} />
    </IonButton>
  )
}

const UploadInitialSelectedBubble: React.FC<{ file: File }> = ({ file }) => {
  const { removeFileFromNewMessage } = useChatRoom()
  return (
    <IonButton className={Styles.uploadSuccededSelectedBubble} fill="clear" size="small" onClick={() => removeFileFromNewMessage(file)}>
      {file.name}
      <IonIcon icon={closeCircleOutline} />
    </IonButton>
  )
}
