import React from 'react'
import { IonInput, IonItem } from "@ionic/react"
import { UseFormRegister, FieldError, Path } from 'react-hook-form'
import Styles from "./FormInputAndErrors.module.scss"

type FormInputAndErrorsProps<TFormValues> = {
  register: UseFormRegister<TFormValues>,
  label?: string,
  name: Path<TFormValues>,
  disabled?: boolean,
  errorMessage?: FieldError,
  type?:  HTMLInputTypeAttribute,
}

type HTMLInputTypeAttribute = "number" | "text" | "email" | "tel"

const FormInputAndErrors = <TFormValues extends Record<string, unknown>>({ register, label, disabled, errorMessage, name, type } : FormInputAndErrorsProps<TFormValues>) =>
  <div className={Styles.inputContainer}>
    <p className={Styles.label}>{label}</p>
    <IonItem lines='none'>
      <IonInput {...register(name)} disabled={disabled} type={type ? type : "text"} />
    </IonItem>
    <p className={Styles.errorMessage}>{errorMessage && errorMessage?.message}</p>
  </div>

export default FormInputAndErrors
