import React from 'react'
import { IonInput, IonItem, IonLabel, IonGrid, IonRow } from '@ionic/react'
import { UseFormRegisterReturn, FieldError } from 'react-hook-form'
import Styles from './ReactHookFormSingleInputPage.module.scss'

export type ReactHookFormSingleInputPageProps = {
  title: string,
  inputSubtext: string | null,
  inputLabel: string | null,
  inputFieldProps: UseFormRegisterReturn,
  inputFieldError: FieldError | undefined,
}

const ReactHookFormSingleInputPage: React.FC<ReactHookFormSingleInputPageProps>  = ({
  title,
  inputSubtext,
  inputLabel,
  inputFieldProps,
  inputFieldError,
}) => {
  return (
    <IonGrid className={Styles.contentContainer}>
      <h3>{title}</h3>
      <IonLabel className={Styles.label} position='stacked'>
        {inputLabel}
      </IonLabel>
      <IonRow className={Styles.item}>
        <IonItem lines='none' className={Styles.inputContainer}>
          <IonInput {...inputFieldProps} />
          <p className={Styles.errorMessage}>{inputFieldError?.message}</p>
        </IonItem>
        {inputSubtext !== null && (
          <p className={Styles.subtext}>{inputSubtext}</p>
        )}
      </IonRow>
    </IonGrid>
  )
}

export default ReactHookFormSingleInputPage
