import { IonPage, IonRouterOutlet } from '@ionic/react'
import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import NotImplementedPage from '../../../../../common/components/NotImplementedPage'
import { TeamType } from '../../../../../graphql/generated'
import OnboardingContractorStageOne from './OnboardingContractorStageOne'
import OnboardingContractorStageSelector from './OnboardingContractorStageSelector'

const baseURL = `/onboarding/team/${TeamType.Contractor}/`

const OnboardingContractor: React.FC = () => {
  const match = useRouteMatch()
  console.debug(`[OnboardingContractor] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="onboardingContractor">
        <Route path={`${baseURL}`} component={OnboardingContractorStageSelector} exact />
        <Route path={`${baseURL}stage1`} component={OnboardingContractorStageOne} />
        <Route component={NotImplementedPage} />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default OnboardingContractor
