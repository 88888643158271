import React from 'react'
import { IonAccordion, IonAccordionGroup, IonContent, IonItem, IonLabel, IonList, IonPage, IonRouterLink } from '@ionic/react'

import Styles from "./Help.module.scss"
import { useSearchState } from '../../common/hooks/pages'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import ResponsiveContentWrapper from '../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

const HelpVideo: React.FC = () => (
  <div className={Styles.videoContainer}>
    <iframe
      className={Styles.video}
      src="https://www.youtube.com/embed/eHd5JPlvqJQ"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen>
    </iframe>
  </div>
)

type THelpItem = {
  title: string,
  body: JSX.Element | string,
}

type THelpSectionData = {
  title: string,
  items: THelpItem[],
}

const HelpSections: THelpSectionData[] = [
  {
    title: "About Weaver",
    items: [
      {
        title: "How do I use this app?",
        body: <>
          <p>
            This is a debug help item for showing what the routerLink support looks like!
          </p>
          <ul>
            <li><IonRouterLink routerLink="/chat">Link to Chat</IonRouterLink></li>
            <li><IonRouterLink routerLink="/projects">Link to Projects</IonRouterLink></li>
          </ul>
        </>,
      },
      {
        title: "What does Weaver do?",
        body: `Weaver is a marketplace for architects, clients and construction professionals that solves the
        challenge of finding trustworthy contractors, consultants & specialist fabricators to get your jobs done.`,
      },
      {
        title: "Who uses Weaver?",
        body: `Weaver is principally used by architect teams in the UK, their clients and the professionals that work on construction project.`,
      },
      {
        title: "How does weaver make money?",
        body: `Weaver is paid by the contractors, consultants & specialist fabricators who are successfully introduced to project work on the Weaver platform.`,
      },
      {
        title: "How does weaver maintain quality of professionals?",
        body: `Architect endorsements lie at the heart of our vetting process, which each professional joining Weaver must pass. We also collect reviews during and after each project.`,
      },
    ],
  },
  {
    title: "Pricing",
    items: [
      {
        title: "What Does the Architect Pay to Use Weaver?",
        body: `The architect offering their projects for quotation does not pay a subscription for use of the Weaver platform, marketplace or any other service provided.`,
      },
      {
        title: "What Does the Professional Pay?",
        body: `The fee is a fixed charge that is quoted clearly on every project lead that is sent out to professionals.
        The charge ranges from 1-5% of contract value as it is calculated by an algorithm each time using a mixture of size of project,
        complexity and the demand for the work. There is no monthly subscription fee.`,
      },
      {
        title: "When Does the Professional Pay?",
        body: `Once the Professional has been selected for the project role, the fee is charged by direct debit spread across a number of months.`,
      },
    ],
  },
  {
    title: "Vetting Process",
    items: [
      {
        title: "How Do You Qualify Weaver Professionals?",
        body: <>We&apos;re extremely picky. Only 1 in 10 professionals make it through our <a href="https://weaver.build/vetting-pros">industry-defining vetting process</a>, at the heart of which are ratings given by design professionals.</>,
      },
    ],
  },
]

type THelpBlockProps = THelpSectionData;

const HelpSection: React.FC<THelpBlockProps> = ({ title, items }) => (
  <>
    <h1>{title}</h1>
    {items.map( ({ title, body }) => {
      const id = title
      return (
        <IonAccordion key={id} value={id}>
          <IonItem slot="header">
            <IonLabel>{title}</IonLabel>
          </IonItem>
          <IonList slot="content">
            <IonItem><main>{body}</main></IonItem>
          </IonList>
        </IonAccordion>
      )
    })}
  </>
)

type THelpPageState = {
  topic: string|undefined,
}

const Help: React.FC = () => {
  const [ state, mergeState ] = useSearchState<THelpPageState>({ topic: undefined })

  return (
    <IonPage>
      <GlobalHeader/>
      <IonContent fullscreen>
        <ResponsiveContentWrapper>
          <HelpVideo />
          <IonAccordionGroup value={state.topic} onIonChange={(e) => mergeState({ topic: e.detail.value })} className={Styles.helpAccordion}>
            {HelpSections.map(({ title, items }) => <HelpSection key={title} title={title} items={items}/>)}
          </IonAccordionGroup>
        </ResponsiveContentWrapper>
      </IonContent>
    </IonPage>
  )
}

export default Help
