import { GenericSelectorOption } from "./GenericSelectorList"

type EnumValue<T> = T[keyof T]
/**
 * Creates an array of GenericSelectorOption<E>[] from:
 * 1. a string EnumType (like what GraphQL generates)
 * 2. (optionally) a Record<E, string> of the mapping from E to whatever label to use
 * 3. (optionally) an order array can be passed, which is a (potentially incomplete, avoid duplicated) list of E
 *
 * Two generics are in use:
 * 1. T is the EnumType
 * 2. E is each EnumType value (e.g. EnumType[keyof EnumType])
 *
 * See demo in `GenericSelectorList.stories.tsx`
 *
 * @param enumType The string EnumType to use. If there's no labels/order passed, then the order of the enum is used.
 * @param labels A mapping Record from EnumType and the label string. If there's no order passed, the order of these labels is used.
 * @param order An EnumType array which overrides the order.
 * @returns List of GenericSelectorOption<EnumType> if labels specified, GenericSelectorOption<string> if not
 */
export const getGenericSelectorOptionsForEnum = <T extends Record<string, string>, E extends EnumValue<T>>(enumType: T, labels?: Partial<Record<E, string>>, order?: E[]): GenericSelectorOption<EnumValue<T>>[] => {
  // Use the enum values for the labels, if none are passed
  const defaultedLabels = labels ?? {} as Record<E, string>
  const defaultedLabelKeyValues = labels ? Object.keys(labels) : Object.values(enumType)

  const options = Object.entries(enumType)
    // Build the options from the enumType using the defaultedLabels
    // casting is OK here, because enums are mappings of [keyof T] => E
    // as E extends string, typescript can't figure out that they're ALL E, otherwise there'd be a circular type dependency ( T = Record<keyof T, T[keyof T]> )
    .map(([ key, value ]) => ({
      key,
      label: defaultedLabels[value as E],
      value: value as E,
    }))
    // If we have been given labels, omit any options with missing labels (if not, include them all via defaultedLabels)
    // This is so we can reuse the types, even if we don't want to include them in or options
    // Missing enum values from the order does not have any effect
    .filter(option => defaultedLabelKeyValues.includes(option.value))

  // If we have an order, use that to order the options
  if (order) return options.sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value))

  // If we have labels, use that to order the options
  if (labels) {
    return options.sort((a, b) => defaultedLabelKeyValues.indexOf(a.value) - defaultedLabelKeyValues.indexOf(b.value))
  }

  // Without an explicit order or labels to infer order from, we take the natural enum object order
  return options
}
