import React, { useContext, useEffect } from 'react'
import { SplashScreenStateContext } from './context'

const SplashScreenSetReady: React.FC = ({ children }) => {
  const splashScreenState = useContext(SplashScreenStateContext)

  useEffect(splashScreenState.setIsReadyIfNotAlreadyReady, [])

  return <>{children}</>
}

export default SplashScreenSetReady
