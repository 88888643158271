import React, { useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { IonButton, IonContent, IonFooter, IonIcon, IonPage, IonToolbar, useIonAlert } from '@ionic/react'
import { useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { prettifyCompanyName } from '../../companyNameHelpers'
import { useSwitchToOnboardingTeam } from '../../..'
import { useOnboardContractorTeam } from '../../hooks'
import { useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { zGoogleGeocodedAddressInput } from '../../../../../graphql/zod'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import CompanyDetailsFind from '../../pages/CompanyDetailsFind'
import CompanyDetailsEdit from '../../pages/CompanyDetailsEdit'
import TitleWithBulletsPage from "../../pages/TitleWithBulletsPage"
import CorporateStructureSelector from '../../../CorporateStructureSelector'
import { CorporateStructureType, TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { contractorSteps } from '../../pages/OnboardingSignupSteps/config'
import PickAddress from '../../../../../common/components/PickAddress'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import ConfettiPage from '../../../../../common/components/ConfettiPage'
import { useContactSupportContext } from '../../../../../api/providers/ContactSupportProvider/ContactSupportProvider'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'
import { pageConfig_Profile_ContractorLeadPreferences, useRouteTo } from '../../../../../routes'
import { useScreens } from '../../../../../common/hooks/useScreens'
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import Styles from "./OnboardingContractorStageOne.module.scss"

enum ContractorOnboardingScreens {
  Introduction = "Introduction",
  SignupSteps = "SignupSteps",
  SignpostCompanyDetails = "SignpostCompanyDetails",
  SelectCorporateStructure = "SelectCorporateStructure",
  FindCompanyDetails = "FindCompanyDetails",
  EditCompanyDetails = "EditCompanyDetails",
  ConfirmCompanyAddress = "ConfirmCompanyAddress",
  Finished = "Finished",
}

const zFormSchema = z.object({
  corporateStructure: z.enum([ CorporateStructureType.LimitedCompany, CorporateStructureType.SoleTrader, CorporateStructureType.Partnership, CorporateStructureType.Other ]),
  company: z.object({
    companyName: z.string(),
    companyNumber: z.string(),
  }),
  companyTradingAs: z.string().min(1, 'Required'),
  companyAddress: zGoogleGeocodedAddressInput,
})

type FormSchema = z.infer<typeof zFormSchema>

const OnboardingContractorStageOne: React.FC = () => {
  const match = useRouteMatch()

  const { register, trigger, setValue, getValues, resetField, formState: { errors } } = useForm<FormSchema>({
    defaultValues: {},
    resolver: zodResolver(zFormSchema),
  })

  console.debug('[OnboardingContractorStageOne] Render ', { match, formValues: getValues() })

  const [ Screen, activeScreen ] = useScreens<ContractorOnboardingScreens>({
    init: () => ContractorOnboardingScreens.Introduction,
    screens: {

      Introduction: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const changeToNextScreen = useChangeScreen(nextScreen)
          const switchToOnboardingTeam = useSwitchToOnboardingTeam()

          return <>
            <IonContent fullscreen>
              <SuccessNotice
                caption="Brilliant!"
                message="To start receiving tailored leads on Weaver, please complete the next few steps"
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={switchToOnboardingTeam}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      SignupSteps: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <IonContent fullscreen>
              <OnboardingSignupSteps
                caption="Sign up to Weaver"
                message="Completing these steps will allow you to receive tailored leads"
                steps={contractorSteps}
                currentPageIndex={1}
                goToNextPage={changeToNextScreen}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      SignpostCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <IonContent fullscreen>
              <TitleWithBulletsPage
                title='Next enter your company details'
                imgProps={{
                  src: builderPng,
                }}
                bullets={[
                  { description: 'Find your company name on Companies House Services', icon: shieldCheckmark },
                  { description: 'Confirm a trading name that clients on Weaver will see', icon: shieldCheckmark },
                ]}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, []),
      },

      SelectCorporateStructure: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const contactSupportAPI = useContactSupportContext()

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger('corporateStructure')
            if (!isValid) return

            const corporateStructure = getValues('corporateStructure')
            const contactSupport = corporateStructure !== CorporateStructureType.LimitedCompany

            if (contactSupport) {
              contactSupportAPI.showContactSupport()
            } else {
              changeToNextScreen()
            }
          }

          return <>
            <IonContent fullscreen>
              <CorporateStructureSelector
                value={getValues("corporateStructure")}
                setValue={async corporateStructure => {
                  if (corporateStructure) {
                    setValue("corporateStructure", corporateStructure)
                    await trigger([ 'corporateStructure' ])
                  } else {
                    resetField('corporateStructure')
                  }
                }}
                hasError={errors.corporateStructure !== undefined}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ trigger, getValues, setValue, resetField ]),
      },

      FindCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger([ 'company', 'companyTradingAs' ])
            if (!isValid) return

            changeToNextScreen()
          }

          return <>
            <IonContent fullscreen>
              <CompanyDetailsFind
                value={getValues("company")}
                setValue={company => {
                  if (company) {
                    setValue("company", company)
                    setValue("companyTradingAs", prettifyCompanyName(company.companyName))
                  } else {
                    resetField('company')
                    resetField('companyTradingAs')
                  }
                }}
                hasError={errors.companyTradingAs !== undefined || errors.company !== undefined}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ trigger, getValues, setValue, resetField, errors, prettifyCompanyName ]),
      },

      EditCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger([ 'companyTradingAs' ])
            if (!isValid) return

            changeToNextScreen()
          }

          return <>
            <IonContent fullscreen>
              <CompanyDetailsEdit
                company={getValues("company")}
                register={{
                  companyTradingAs: register('companyTradingAs'),
                }}
                errors={errors}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ trigger, getValues, register, errors ]),
      },

      ConfirmCompanyAddress: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
          const onboardContractorTeamFn = useOnboardContractorTeam()

          const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('WorkHistory_References_Added_Past_Project_Address')

          const [ present ] = useIonAlert()

          const submitIfFieldIsValid = async () => {
            const isValid = await trigger()
            if (!isValid) return

            const value = getValues('companyAddress')
            await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
              city: (value?.address_components?.find(address_component => address_component.types.includes("postal_town")))?.long_name,
              firstPartOfPostCode: (value?.address_components?.find(address_component => address_component.types.includes("postal_code")))?.long_name.slice(0,4),
            })

            const data = getValues()

            try {
              const onboardResult = await onboardContractorTeamFn({
                companyNumber: data.company.companyNumber,
                companyRegisteredName: data.company.companyName,
                companyTradingAs: data.companyTradingAs,
                companyAddress: data.companyAddress,
              })

              await setOnboardingCompleteMutation.mutateAsync({
                teamId: onboardResult.team.id,
                isOnboardingComplete: true,
              })

              changeToNextScreen()
            } catch (e) {
              if (e instanceof Error) {
                present({
                  header: "Failed to Onboard Contractor Team",
                  message: e.message,
                  buttons: [
                    {
                      text: "Dismiss",
                      role: 'cancel',
                    },
                  ],
                })
              }
            }
          }

          return <>
            <IonContent fullscreen>
              <PickAddress
                title="Enter your Company Address"
                value={getValues("companyAddress")}
                setValue={companyAddress => {
                  if (companyAddress) {
                    setValue("companyAddress", companyAddress)
                  } else {
                    resetField('companyAddress')
                  }
                }}
                hasError={errors.companyAddress !== undefined}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={submitIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ trigger, getValues, setValue, resetField, errors ]),
      },

      Finished: {
        render: useCallback(() => {
          const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Complete')
          const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()
          const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)

          return <>
            <IonContent fullscreen>
              <ConfettiPage
                caption="Amazing!"
                message="Thank you for adding your company information."
                onClick={async () => {
                  await triggerEventOnboardingCompanyComplete({ teamType: TeamType.Contractor })
                  await useMyIndividualInvalidateCacheFn()
                  goToContractorLeadPreferences({})
                }}
              />
            </IonContent>
          </>
        }, []),
      },

    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default OnboardingContractorStageOne
