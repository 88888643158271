import React from 'react'
import { useChatRoom } from '../services/ChatRoomProvider'
import { SelectedFileBubble } from './SelectedFileBubble'

import Styles from "./ChatFooter.module.scss"
import { TMutationArchiveChatRoomMessageDocumentFn, TMutationCreateChatRoomMessageDocumentFn } from '../../../../api/services/chat/useRealtimeChatMessages'

type SelectedFilesProps = {
  createChatRoomMessageAttachment: (file: File) => ReturnType<TMutationCreateChatRoomMessageDocumentFn>,
  archiveChatRoomMessageDocument: (id: string) => ReturnType<TMutationArchiveChatRoomMessageDocumentFn>,
}

export const SelectedFiles: React.FC<SelectedFilesProps> = ({ createChatRoomMessageAttachment, archiveChatRoomMessageDocument }) => {
  const { newMessageFiles } = useChatRoom()

  if ( newMessageFiles?.length === 0 ) return <></>

  return (
    <div className={Styles.selectedFilesContainer}>
      {
        newMessageFiles.map((file, index) => <SelectedFileBubble createChatRoomMessageDocument={createChatRoomMessageAttachment} archiveChatRoomMessageDocument={archiveChatRoomMessageDocument} key={index} file={file} />)
      }
    </div>
  )
}
