import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

/**
 * A wrapper for `useState` which resets the state to the `initialState` whenever the `resetWhenValueChanges` parameter changes.
 * @param initialState Same as what is passed as `useState(initialState)`. If a function, will rerun when `resetWhenValueChanges` changes.
 * @param resetWhenValueChanges When `resetWhenValueChanges` changes, apart from on the first run, the state is reset to `initialState`.
 * @returns The same as `useState` returns.
 */
export const useResettableState = <S,>(initialState: S | (() => S), resetWhenValueChanges?: unknown): [S, Dispatch<SetStateAction<S>>] => {
  const [ value, setValue ] = useState<S>(initialState)

  // Detect when resetWhenValueChanges changes, apart from on the first run, and reset the state to the initial value
  const firstRun = useRef(true)
  useEffect(() => {
    if (firstRun.current === true) {
      firstRun.current = false
    } else {
      setValue(initialState instanceof Function ? initialState() : initialState)
    }
  }, [ resetWhenValueChanges ])

  return [
    value,
    setValue,
  ]
}
