import jwt_decode from "jwt-decode"
import { z } from "zod"
import { useStoredValue } from "../../StorageProvider"
import { TAuth_LoggedIn } from ".."

export type CachedAuthValue = Pick<TAuth_LoggedIn, 'accessToken' | 'idToken' | 'gravatar' | 'userData'> | undefined
export type SetCachedAuth = (jwts: StoredJwts | undefined) => void

type CachedAuth = [
  CachedAuthValue,
  SetCachedAuth,
]

const IdTokenSchema = z.object({
  given_name: z.string(),
  family_name: z.string(),
  email: z.string(),
  picture: z.string(),
})

const useCachedAuth = (): CachedAuth => {
  const [ storedJwts, setStoredJwts ] = useStoredJwts()

  const cachedAuth: CachedAuth[0] = (() => {
    if (storedJwts === undefined) {
      return undefined
    }

    // Decode the Cached JWT
    try {
      const idTokenDecoded = jwt_decode(storedJwts.idTokenRaw)
      const idToken = IdTokenSchema.parse(idTokenDecoded)

      return {
        accessToken: storedJwts.accessToken,
        idToken: {
          ...idToken,
          __raw: storedJwts.idTokenRaw,
        },
        gravatar: 'undefined',
        userData: idToken,
      }
    } catch (error) {
      console.error("[useCachedAuth] Unable to decode auth tokens: ", { storedJwts })
      throw error
    }
  })()

  return [
    cachedAuth,
    setStoredJwts,
  ]
}

type StoredJwts = {
  accessToken: string,
  idTokenRaw: string,
}
const useStoredJwts = () => useStoredValue<StoredJwts>({ key: 'mwStoredJwts' })

export default useCachedAuth
