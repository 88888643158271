import { IonIcon } from "@ionic/react"
import React from "react"
import { getIconByName, IonIconName } from "./icons"

// Because @ionic/react doesn't want to make this easy
type IonIconProps = Parameters<typeof IonIcon>[0]
type IonIconPropsWithoutIcon = Omit<IonIconProps, 'icon'>

type IonIconTypedProps = IonIconPropsWithoutIcon & {
  iconName: IonIconName,
}

const IonIconTyped: React.FC<IonIconTypedProps> = (props) =>
  <IonIcon {...props} icon={getIconByName(props.iconName)} />

export default IonIconTyped
