import React from 'react'
import {  IonContent, IonIcon, IonItem, IonPage  } from '@ionic/react'
import { addCircleOutline, createOutline,informationCircleOutline } from 'ionicons/icons'
import { useHistory, useRouteMatch } from 'react-router'
import { useGetContractorProfileQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import CreateMyContractorProfileButton from './CreateMyContractorProfileButton'
import TeamMembers from '../TeamMembers/TeamMembers'
import { ensureTrailingSlash } from '../../../common/utils'
import ButtonChangeLeadPreferences from '../ContractorLeadPreferences/ButtonChangeLeadPreferences'
import ProfileHeader from '../ProfileHeader'
import ContractorProfileRangeBadges from './ContractorProfileRangeBadges'
import ContractorProfileWorkHistory from './ContractorProfileWorkHistory'
import Styles from './DisplayContractorProfile.module.scss'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type ContractorProfileProps = {
  id: string,
  canEdit: boolean,
}

const ContractorProfile: React.FC<ContractorProfileProps> = ({ id, canEdit }) => {
  const myTeam = useMyIndividualActiveTeam()
  const isMyTeam = myTeam?.id === id
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: id })
  const history = useHistory()
  const match = useRouteMatch()
  console.debug(`[ContractorProfile] Render: `, { id, data })

  if (isFetching) {
    return <LoadingSpinner name="ContractorProfile" />
  }

  if (error) {
    console.error(`[ContractorProfile] Failed to load Contractor Profile: `, { id, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  if (data?.getContractorProfile == null) {
    console.warn(`[ContractorProfile] Contractor Profile is missing: `, { id, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <CentralisedBlock>
          <IonIcon icon={informationCircleOutline} size="large" color="primary" />
          <p>Unable to find the Contract Profile.</p>
          {isMyTeam && <p>
            <CreateMyContractorProfileButton size="small" color="secondary">
              <IonIcon icon={addCircleOutline} className={Styles.buttonIcon} /> Create Contractor Profile
            </CreateMyContractorProfileButton>
          </p>}
        </CentralisedBlock>
      </IonContent>
    </IonPage>
  }

  const profile = data.getContractorProfile

  return <IonPage>
    <ProfileHeader isMyTeam={isMyTeam} />
    <IonContent className={`${Styles.ionContent}`} fullscreen>
      <ResponsiveContentWrapper>
        <section className={Styles.companyInfoContainer}>
          {isMyTeam && canEdit
            ? (
              <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={event => {
                event.preventDefault()
                const newLocation = `${ensureTrailingSlash(match.url)}edit`
                history.push(newLocation)
              }}>
                <h3 className={Styles.companyTradingName}>{profile.companyTradingAs}</h3>
                <IonIcon className={Styles.editIcon} src={createOutline}></IonIcon>
              </IonItem>
            )
            : (
              <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
                <h3>{profile.companyTradingAs}</h3>
              </IonItem>
            )}
          <p className={Styles.contractorTeamType}>Main Contractor</p>
          <p>{profile.companyAddress?.formatted_address ?? <em>No Address</em>}</p>
          <ContractorProfileRangeBadges workHistory={data.getContractorProfile.workHistory} width="90" height="90" />
        </section>

        <section className={Styles.unlockedBudgetRangesContainer}>
          {isMyTeam && canEdit && <ButtonChangeLeadPreferences />}
        </section>

        <section>
          <ContractorProfileWorkHistory contractorProfileId={data.getContractorProfile.id} workHistory={data.getContractorProfile.workHistory} />
        </section>

        <section>
          <TeamMembers teamId={id} isMyTeam={isMyTeam} />
        </section>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}

export default ContractorProfile
