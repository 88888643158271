import React, { useState } from 'react'
import { DurationObjectUnits } from 'luxon'
import useTickingTimer from '../../hooks/useTickingTimer'

const MIN_TIME: DurationObjectUnits = { seconds: 2 }
const MAX_TIME: DurationObjectUnits = { seconds: 5 }

type SplashScreenState = {
  hideSplashScreen: boolean,
  setIsReadyIfNotAlreadyReady: () => void,
}

const emptyState: SplashScreenState = {
  hideSplashScreen: true,
  setIsReadyIfNotAlreadyReady: () => { /* no-op */ },
}

export const SplashScreenStateContext = React.createContext<SplashScreenState>(emptyState)

export const useSplashScreenState = (): SplashScreenState => {
  const [ isReady, setIsReady ] = useState<boolean>(false)

  const minTimeReached = useTickingTimer(MIN_TIME)
  const maxTimeReached = useTickingTimer(MAX_TIME)

  const hideSplashScreen = (minTimeReached && isReady) || maxTimeReached

  const setIsReadyIfNotAlreadyReady = () => {
    if (!isReady) setIsReady(true)
  }

  return {
    hideSplashScreen,
    setIsReadyIfNotAlreadyReady,
  }
}
