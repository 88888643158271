import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonSpinner, useIonAlert, useIonRouter } from "@ionic/react"
import { calendarClearOutline, calendarOutline, cashOutline, checkmarkCircleOutline, closeOutline, constructOutline } from "ionicons/icons"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { useGraphQLDataSource } from "../../api/graphql"
import { environment } from "../../api/providers/EnvironmentProvider"
import ErrorBlock from "../../common/components/ErrorBlock"
import LoadingSpinner from "../../common/components/LoadingSpinner"
import Styles from "./ShowLead.module.scss"
import { useShowLeadQuery, useAcceptLeadMutation, useRejectLeadMutation } from "../../graphql/generated"

import { Loader } from '@googlemaps/js-api-loader'
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import { getEnumLabel } from "../../common/utils"
import { DateTime } from "luxon"
import { workStartEstimateLabels } from "./common"
import { useRouteToContractorLeadAcceptor } from "../../routes"

const apiKey = environment.integrations.google.apiKey

const ShowLead: React.FC = () => {
  const { id } = useParams<{id: string}>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ present ] = useIonAlert()
  const router = useIonRouter()

  const showLeadQuery = useShowLeadQuery(gqlDataSource , { id }, { refetchOnWindowFocus: false })
  const routeToContractorLeadAcceptor = useRouteToContractorLeadAcceptor()
  const rejectLeadMutation = useRejectLeadMutation(gqlDataSource)
  const [ acceptLoading, setAcceptLoading ] = useState<boolean>(false)
  const [ rejectLoading, setRejectLoading ] = useState<boolean>(false)
  const [ , setMap ] = useState<google.maps.Map | undefined>(undefined)
  const mapDivRef = useRef<HTMLDivElement| null>(null)

  // useContractorLeadSubscriptions(acceptLeadMutation) which should
  // > check if useWeaverFlags() if Lead Subscriptions are enabled
  // > check if this contractor has an active subscription for this budget range
  // > if not, show the buy lead / subscription flow (otherwise skip)
  // > call the accept lead mutation
  // > success!

  useEffect(() => {
    const init = async () => {
      if ( !window.google?.maps ) {
        await new Loader({ apiKey, ...{ libraries: [ 'places' ] } }).load()
      }
      if (!showLeadQuery.data?.getLead.approximateLocation) {
        return
      }
      if (!mapDivRef.current) {
        return
      }

      const [ lat, lng ] = showLeadQuery.data.getLead.approximateLocation.split(" ").map(x => Number(x))
      setMap( new window.google.maps.Map(mapDivRef.current, {
        center: { lat, lng },
        zoom: 10,
        fullscreenControl: false,
        disableDefaultUI: true,
        draggable: false,
        keyboardShortcuts: false,
      }) )
    }

    init()
  }, [ showLeadQuery.data?.getLead?.approximateLocation, mapDivRef.current ])

  const rejectLead = useCallback(async () => {
    setRejectLoading(true)
    try {
      await rejectLeadMutation.mutateAsync({ id })
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Unable to Reject Lead",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }

    setRejectLoading(false)
    router.push("/projects")
  }, [ rejectLeadMutation, id, setRejectLoading, router ])

  if (showLeadQuery.isFetching && !showLeadQuery.data) {
    return <LoadingSpinner name="showLeadQuery"/>
  }

  if (showLeadQuery.error || !showLeadQuery.data) {
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={showLeadQuery.refetch} />
      </IonContent>
    </IonPage>
  }

  const { data: { getLead: lead } } = showLeadQuery

  const loading = acceptLoading || rejectLoading

  return <IonPage>
    <GlobalHeader/>
    <IonContent>
      <IonCard>
        <div className={Styles.map} ref={mapDivRef}></div>
        <IonCardHeader>
          <IonCardSubtitle>LEAD</IonCardSubtitle>
          <IonCardTitle>{lead.title}</IonCardTitle>
        </IonCardHeader>

        <IonItem>
          <IonIcon icon={constructOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Type</IonLabel>
            {lead.projectTypes.map(x => <IonChip key={x} >{getEnumLabel(x)}</IonChip>)}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonIcon icon={cashOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Budget</IonLabel>
            {lead.budgetValue.amountInPence/100} {lead.budgetValue.currency}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonIcon icon={calendarClearOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Work Start Estimate</IonLabel>
            {lead.workStartEstimate && workStartEstimateLabels[lead.workStartEstimate]}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonIcon icon={calendarOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Tender Return Date</IonLabel>
            {lead.tenderReturnDate && DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}
          </IonLabel>
        </IonItem>

        <IonCardContent>
          <h2>Description</h2>
          {lead.description || ""}
        </IonCardContent>

        <div className={Styles.actionButtons}>
          <IonButton disabled={loading} onClick={routeToContractorLeadAcceptor(id)} color="success">
            {acceptLoading ? <IonSpinner /> : <><IonIcon icon={checkmarkCircleOutline} slot="start"/>Accept</>}
          </IonButton>
          <IonButton disabled={loading} onClick={rejectLead} color="danger">
            {rejectLoading ? <IonSpinner /> : <><IonIcon icon={closeOutline} slot="start"/>Reject</>}
          </IonButton>
        </div>
      </IonCard>
    </IonContent>
  </IonPage>
}

export default ShowLead
