import React from 'react'
import Styles from "./CentralisedBlock.module.css"

const CentralisedBlock: React.FC = ({ children }) =>
  <div className={Styles.container}>
    <div className={Styles.body}>
      {children}
    </div>
  </div>

export default CentralisedBlock
