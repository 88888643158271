import React from 'react'
import { IonPage, IonImg, IonInput, IonItem, IonLabel, IonList, IonRouterLink, IonContent } from '@ionic/react'
import { CreateProjectMutation } from '../../../graphql/generated'
import { getKnownRoutePathForPage, Page } from '../../../routes'
import WeaverHardhatWithComments from '../../../assets/images/weaver-hardhat-with-comments.png'

import Styles from "./SuccessSlide.module.scss"

export type SuccessSlideProps = {
  createdProject: CreateProjectMutation["createProject"],
}

export const SuccessSlide: React.FC<SuccessSlideProps> = ({ createdProject: { id, memberInvites: invites } }) => {

  const projectPath = getKnownRoutePathForPage(Page.ShowProject).replace(":id", id)

  return (
    <IonPage className={Styles.pageContainer}>
      <IonContent scrollEvents={true}>
        <div className={Styles.background}>
          <div className={Styles.promoImage}>
            <IonImg  src={WeaverHardhatWithComments}/>
          </div>
        </div>

        <div className={Styles.message}>
          <h1 className={Styles.caption}>Brilliant!</h1>
          <p className={Styles.notification} >
            Complete all tasks and get verified on Weaver Marketplace to receive tailored leads
          </p>
          {invites.length > 0 && <>
            <h3 className={Styles.inviteText}>Invites</h3>
            <IonList>
              {
                invites.map(x => <IonItem key={x.id}>
                  <IonLabel position='stacked'>Invite</IonLabel>
                  <IonInput readonly={true} value={x.id}></IonInput>
                </IonItem>)
              }
            </IonList>
          </>}
          <div className={Styles.button}>
            {/* HACK: Using a href here (the app will reload when activated) instead of routerLink, because of the unknown-cause page-doesn't-render-on-the-right-path routing issue.
             This is an bad hackjob solution but it will ensure that users don't end up in a loop when creating a project (a common flow!)
             See https://weaver.atlassian.net/browse/MW-1125 for updates  */}
            <IonRouterLink href={projectPath}><p className={Styles.routerLinkText}>Continue</p></IonRouterLink>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SuccessSlide
