import React from 'react'
import Styles from './ResponsiveContentWrapper.module.scss'

type  ResponsiveContentWrapperProps = {
  children?: React.ReactNode,
};

const ResponsiveContentWrapper: React.FC<ResponsiveContentWrapperProps> = ({ children }) => {

  return (<div className={Styles.responsiveContentWrapper}>
    {children}
  </div>)
}

export default ResponsiveContentWrapper