import { useFlags } from "launchdarkly-react-client-sdk"
import { z } from "zod"

/**
 * The schema for the LaunchDarkly flags that might come back.
 */
export const zWeaverFlags = z.object({
  'miscellaneous-menu': z.object({
    enabled: z.boolean(),
  }),
  'minimum-individual-profile': z.object({
    enabled: z.boolean(),
    disableAspect: z.object({
      'require-phone-number': z.boolean().optional(),
    }).optional(),
  }),
  'contractor-profile-budget-preferences': z.object({
    enabled: z.boolean(),
  }),
  'contractor-lead-acceptor': z.object({
    enabled: z.boolean(),
  }),
})

export type WeaverFlags = z.infer<typeof zWeaverFlags>

export const defaultWeaverFlags: WeaverFlags = {
  'miscellaneous-menu': {
    enabled: false,
  },
  'minimum-individual-profile': {
    enabled: false,
  },
  'contractor-profile-budget-preferences': {
    enabled: false,
  },
  'contractor-lead-acceptor': {
    enabled: false,
  },
}

/**
 * Returns a strongly typed set of LaunchDarkly flags, or throws an error.
 * WARNING: Changes to LaunchDarkly config will change this behaviour!
 */
export const useWeaverFlags = (): WeaverFlags => {
  const parsedFlags = zWeaverFlags.safeParse(useFlags())

  if (!parsedFlags.success) {
    console.error('[useWeaverFlags] Unable to parse the flags.', parsedFlags.error)
    return defaultWeaverFlags
  }

  return parsedFlags.data
}
