import React, { useCallback } from 'react'
import { cubeSharp, helpBuoy, chatboxOutline, peopleOutline } from 'ionicons/icons'
import { ExtractRouteParams, generatePath, Redirect, Route, useHistory } from 'react-router'
import { ChatRoom, MyChats } from './domains/chats'
import Help from './domains/help'
import ProjectsIndex, { CreateProject, ShowProject } from './domains/projects'
import DeveloperSettings from './domains/devSettings'
import ProjectChat from './domains/projects/ProjectChat'
import { IonIcon, IonLabel, IonRoute, IonRouteProps, IonTabButton } from '@ionic/react'
import OnboardingIndex from './domains/onboarding'
import ShowLead from './domains/projects/ShowLead'
import { BudgetRange, TeamType } from './graphql/generated'
import NotImplementedPage from './common/components/NotImplementedPage'
import TechDemoTab from './domains/techDemo'
import ProfileIndex from './domains/profile/ProfileIndex'
import { UploadDocuments, UploadedProjectDocumentSuccess } from './domains/projects/documents'
import { MyIndividualActiveTeam } from './api/providers/MyIndividualProvider/MyIndividualProvider'
import SuccessPage from './common/components/SuccessPage/SuccessPage'
import ContractorLeadPreferences from './domains/profile/ContractorLeadPreferences'
import ContractorBudgetRanges from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges'
import UnlockContractorBudgetRange from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange'
import NewWorkHistory from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/NewWorkHistory'
import WorkHistoryReferences from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryReferences'
import WorkHistoryPhotos from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos'
import DisplayContractorWorkHistory from './domains/profile/DisplayContractorWorkHistory'
import DisplayContractorLeadAcceptor from './domains/projects/DisplayContractorLeadAcceptor/DisplayContractorLeadAcceptor'
import ContractorLeadLocations from './domains/profile/ContractorLeadPreferences/ContractorLeadLocations'

export enum Page {
  Onboarding = "Onboarding",
  Profile = "Profile",
  Profile_ContractorWorkHistory = "Profile_ContractorWorkHistory",
  Profile_ContractorLeadPreferences = "Profile_ContractorLeadPreferences",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges = "Profile_ContractorLeadPreferences_ContractorBudgetRanges",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos",
  Profile_ContractorLeadPreferences_ContractorLeadLocations = "Profile_ContractorLeadPreferences_ContractorLeadLocations",
  Projects = "Projects",
  Projects_ContractorLeadAcceptor = "Projects_ContractorLeadAcceptor",
  MyChats = "MyChats",
  ChatRoom = "ChatRoom",
  Help = "Help",
  DeveloperSettings = "DeveloperSettings",
  CreateProject = "CreateProject",
  ShowProject = "ShowProject",
  ProjectChat = "ProjectChat",
  ShowLead = "ShowLead",
  TechDemo = "TechDemo",
  NotImplemented = "NotImplemented",
  Success = "Success",
  UploadedProjectDocumentSuccess = 'UploadedProjectDocumentSuccess',
  UploadProjectDocuments = "UploadProjectDocuments",
}

type PageConfig = {
  page: Page,
  path?: IonRouteProps['path'],
  exact?: IonRouteProps['exact'],
}

type PageRender = IonRouteProps['render']

export const pageConfig_Onboarding: {
  page: Page.Onboarding,
  path: "/onboarding",
  exact: false,
} = {
  page: Page.Onboarding,
  path: "/onboarding",
  exact: false,
}

export const pageConfig_Profile: {
  page: Page.Profile,
  path: "/profile",
  exact: false,
} = {
  page: Page.Profile,
  path: "/profile",
  exact: false,
}

export const pageConfig_Profile_ContractorWorkHistory: {
  page: Page.Profile_ContractorWorkHistory,
  path: "/profile2/Contractor/:contractorProfileId/workHistory/:workHistoryId",
} = {
  page: Page.Profile_ContractorWorkHistory,
  path: "/profile2/Contractor/:contractorProfileId/workHistory/:workHistoryId",
}

export const pageConfig_Profile_ContractorLeadPreferences: {
  page: Page.Profile_ContractorLeadPreferences,
  path: "/profile2/contractorLeadPreferences",
} = {
  page: Page.Profile_ContractorLeadPreferences,
  path: "/profile2/contractorLeadPreferences",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges",
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange",
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/new",
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/new",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/references",
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/references",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/photos",
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
  path: "/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/photos",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorLeadLocations: {
  page: Page.Profile_ContractorLeadPreferences_ContractorLeadLocations,
  path: "/profile2/contractorLeadPreferences/contractorLeadLocations",
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorLeadLocations,
  path: "/profile2/contractorLeadPreferences/contractorLeadLocations",
}

export const pageConfig_Projects: {
  page: Page.Projects,
  path: "/projects",
} = {
  page: Page.Projects,
  path: "/projects",
}

export const pageConfig_Projects_ContractorLeadAcceptor: {
  page: Page.Projects_ContractorLeadAcceptor,
  path: "/projects2/lead/:leadId",
} = {
  page: Page.Projects_ContractorLeadAcceptor,
  path: "/projects2/lead/:leadId",
}

export const pageConfig_CreateProject: {
  page: Page.CreateProject,
  path: "/new-project",
} = {
  page: Page.CreateProject,
  path: "/new-project",
}

export const pageConfig_ShowProject: {
  page: Page.ShowProject,
  path: "/projects/:id",
} = {
  page: Page.ShowProject,
  path: "/projects/:id",
}

export const pageConfig_ProjectChat: {
  page: Page.ProjectChat,
  path: "/projects/:projectId/chatd",
} = {
  page: Page.ProjectChat,
  path: "/projects/:projectId/chatd",
}

export const pageConfig_UploadProjectDocuments: {
  page: Page.UploadProjectDocuments,
  path: "/projects/:projectId/upload-documents",
  exact: false,
} = {
  page: Page.UploadProjectDocuments,
  path: "/projects/:projectId/upload-documents",
  exact: false,
}

export const pageConfig_ShowLead: {
  page: Page.ShowLead,
  path: "/leads/:id",
} = {
  page: Page.ShowLead,
  path: "/leads/:id",
}

export const pageConfig_MyChats: {
  page: Page.MyChats,
  path: "/chats",
} = {
  page: Page.MyChats,
  path: "/chats",
}

export const pageConfig_ChatRoom: {
  page: Page.ChatRoom,
  path: "/chats/:id",
  exact: false,
} = {
  page: Page.ChatRoom,
  path: "/chats/:id",
  exact: false,
}

export const pageConfig_Help: {
  page: Page.Help,
  path: "/help",
} = {
  page: Page.Help,
  path: "/help",
}

export const pageConfig_DeveloperSettings: {
  page: Page.DeveloperSettings,
  path: "/dev-settings",
  exact: false,
} = {
  page: Page.DeveloperSettings,
  path: "/dev-settings",
  exact: false,
}

export const pageConfig_TechDemo: {
  page: Page.TechDemo,
  path: "/tech-demo",
  exact: false,
} = {
  page: Page.TechDemo,
  path: "/tech-demo",
  exact: false,
}

export const pageConfig_Success: {
  page: Page.Success,
  path: "/success",
} = {
  page: Page.Success,
  path: "/success",
}

export const pageConfig_UploadedProjectDocumentSuccess: {
  page: Page.UploadedProjectDocumentSuccess,
  path: "/uploadProjectDocumentSuccess",
} = {
  page: Page.UploadedProjectDocumentSuccess,
  path: "/uploadProjectDocumentSuccess",
}

export const pageConfig_NotImplemented: {
  page: Page.NotImplemented,
} = {
  page: Page.NotImplemented,
}

export const allPages: Record<Page, PageConfig> = {
  Onboarding: pageConfig_Onboarding,
  Profile: pageConfig_Profile,
  Profile_ContractorWorkHistory: pageConfig_Profile_ContractorWorkHistory,
  Profile_ContractorLeadPreferences: pageConfig_Profile_ContractorLeadPreferences,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
  Profile_ContractorLeadPreferences_ContractorLeadLocations: pageConfig_Profile_ContractorLeadPreferences_ContractorLeadLocations,
  Projects: pageConfig_Projects,
  Projects_ContractorLeadAcceptor: pageConfig_Projects_ContractorLeadAcceptor,
  CreateProject: pageConfig_CreateProject,
  ShowProject: pageConfig_ShowProject,
  ProjectChat: pageConfig_ProjectChat,
  UploadProjectDocuments: pageConfig_UploadProjectDocuments,
  ShowLead: pageConfig_ShowLead,
  MyChats: pageConfig_MyChats,
  ChatRoom: pageConfig_ChatRoom,
  Help: pageConfig_Help,
  DeveloperSettings: pageConfig_DeveloperSettings,
  TechDemo: pageConfig_TechDemo,
  Success: pageConfig_Success,
  UploadedProjectDocumentSuccess: pageConfig_UploadedProjectDocumentSuccess,
  NotImplemented: pageConfig_NotImplemented,
}

export const allPageRenders: Record<Page, PageRender> = {
  Onboarding: () => <OnboardingIndex />,
  Profile: () => <ProfileIndex />,
  Profile_ContractorWorkHistory: () => <DisplayContractorWorkHistory />,
  Profile_ContractorLeadPreferences: () => <ContractorLeadPreferences />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges: () => <ContractorBudgetRanges />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange: () => <UnlockContractorBudgetRange />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory: () => <NewWorkHistory />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences: () => <WorkHistoryReferences />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos: () => <WorkHistoryPhotos />,
  Profile_ContractorLeadPreferences_ContractorLeadLocations: () => <ContractorLeadLocations />,
  Projects: () => <ProjectsIndex />,
  Projects_ContractorLeadAcceptor: () => <DisplayContractorLeadAcceptor />,
  CreateProject: () => <CreateProject />,
  ShowProject: () => <ShowProject />,
  ProjectChat: () => <ProjectChat />,
  UploadProjectDocuments: () => <UploadDocuments />,
  ShowLead: () => <ShowLead />,
  MyChats: () => <MyChats />,
  ChatRoom: () => <ChatRoom />,
  Help: () => <Help />,
  DeveloperSettings: () => <DeveloperSettings />,
  TechDemo: () => <TechDemoTab />,
  Success: props => <SuccessPage routerLink={props.location.location.state?.routerLink} />,
  UploadedProjectDocumentSuccess: props => <UploadedProjectDocumentSuccess routerLink={props.location.location.state?.routerLink} />,
  NotImplemented: () => <NotImplementedPage />,
}

/**
 * Takes a React Router parameterised path, populates the variables, adds an optional search string and returns the URL.
 *
 * @param path React Router parameterised path. e.g. `/users/:userId`
 * @param params Map of params matching the parameters in the path. e.g. `{ userId: string }` for `/user/:userId` or `{}` for `/users`
 * @param search An optional search string to add to the end of the URL
 * @returns The composed URL, which may be an empty string if the path is undefined
 */
export const populateUrlForPath = <Path extends string>(path: Path, params: ExtractRouteParams<Path>, search?: string) => {
  const populatedPath = generatePath<Path>(path, params)
  return `${populatedPath}${search ? `?${search}` : ''}`
}

/**
 * Takes a React Router parameterised path a function parameterised which builds a handler, which when called will navigate to the path provided.
 *
 * *
 * e.g.
 * ```typescript
 * const goToProjects = useRouteTo(pageConfig_Projects.path)
 * return <IonButton onClick={goToProjects({})} />
 * ```
 * or
 * ```typescript
 * const goToContractorWorkHistory = useRouteTo(pageConfig_Profile_ContractorWorkHistory.path)
 * return <IonButton onClick={goToContractorWorkHistory({ contractorProfileId, workHistoryId })} />
 * ```
 *
 * @param path
 * @returns
 */
export const useRouteTo = <Path extends string>(path: Path) => {
  const history = useHistory()

  return useCallback((params: ExtractRouteParams<Path>, search?: string) => () => {
    const url = populateUrlForPath(path, params, search)
    if (!url) return

    history.push(url)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToProfile = () => {
  const history = useHistory()
  return useCallback(() => () => {
    history.push(`/profile`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToContractorWorkHistory = () => {
  const history = useHistory()
  return useCallback((contractorProfileId: string, workHistoryId: string) => () => {
    history.push(`/profile2/Contractor/${contractorProfileId}/workHistory/${workHistoryId}`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToContractorLeadPreferences = () => {
  const history = useHistory()
  return useCallback(() => () => {
    history.push('/profile2/contractorLeadPreferences')
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToContractorBudgetRanges = () => {
  const history = useHistory()
  return useCallback(() => () => {
    history.push(`/profile2/contractorLeadPreferences/contractorBudgetRanges`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToContractorLeadLocations = () => {
  const history = useHistory()
  return useCallback((search?: string) => () => {
    history.push(`/profile2/contractorLeadPreferences/contractorLeadLocations${search ? `?${search}` : ''}`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToUnlockContractorBudgetRange = () => {
  const history = useHistory()
  return useCallback((budgetRange: BudgetRange) => () => {
    history.push(`/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/${budgetRange}`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToUnlockContractorBudgetRangeNewWorkHistory = () => {
  const history = useHistory()
  return useCallback((budgetRange: BudgetRange, search?: string) => () => {
    history.push(`/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/${budgetRange}/workHistory/new${search ? `?${search}` : ''}`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToUnlockContractorBudgetRangeWorkHistoryReferences = () => {
  const history = useHistory()
  return useCallback((budgetRange: BudgetRange, workHistoryId: string, search?: string) => () => {
    history.push(`/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/${budgetRange}/workHistory/${workHistoryId}/references${search ? `?${search}` : ''}`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToUnlockContractorBudgetRangeWorkHistoryPhotos = () => {
  const history = useHistory()
  return useCallback((budgetRange: BudgetRange, workHistoryId: string, search?: string) => () => {
    history.push(`/profile2/contractorLeadPreferences/contractorBudgetRanges/unlockContractorBudgetRange/${budgetRange}/workHistory/${workHistoryId}/photos${search ? `?${search}` : ''}`)
  }, [ history ])
}

/** @deprecated Use `useRouteTo` */
export const useRouteToContractorLeadAcceptor = () => {
  const history = useHistory()
  return useCallback((leadId: string) => () => {
    history.push(`/projects2/lead/${leadId}`)
  }, [ history ])
}

const redirectRootPathTo = (page: Page): JSX.Element =>
  <Route key="root" path="/" exact>
    <Redirect to={getRoutePathForPage(page)} />
  </Route>

const redirectEverythingTo = (page: Page): JSX.Element =>
  <Route key="everything">
    <Redirect to={getRoutePathForPage(page)} />
  </Route>

export const getRoutePathForPage = (page: Page): string | undefined => allPages[page].path

export const getKnownRoutePathForPage = (page: Page): string => {
  const path = getRoutePathForPage(page)
  if (path === undefined) {
    throw new Error(`[Routes] Config Error: Unable to find a path for ${page}`)
  }
  return path
}

export const getKnownRoutePathForPages = (...pages: Page[]): string[] => pages.map(getKnownRoutePathForPage)

type RoutingWithTabs = {
  pages: (Page | JSX.Element)[],
  tabs: {
    name: string,
    title: string,
    icon: string,
    href?: string,
  }[],
}

const defaultRoutingWithTabs: RoutingWithTabs = {
  pages: [
    Page.Profile,
    Page.Profile_ContractorWorkHistory,
    Page.Profile_ContractorLeadPreferences,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
    Page.Profile_ContractorLeadPreferences_ContractorLeadLocations,
    Page.Projects,
    Page.Projects_ContractorLeadAcceptor,
    Page.ShowProject,
    Page.ShowLead,
    Page.ProjectChat,
    Page.MyChats,
    Page.ChatRoom,
    Page.Help,
    Page.DeveloperSettings,
    Page.TechDemo,
    Page.Success,
    redirectRootPathTo(Page.Projects),
    Page.NotImplemented,
    Page.UploadProjectDocuments,
    Page.UploadedProjectDocumentSuccess,
  ],
  tabs: [
    {
      name: Page.Projects,
      title: "Projects",
      icon: cubeSharp,
      href: getRoutePathForPage(Page.Projects),
    },
    {
      name: Page.MyChats,
      title: "Chats",
      icon: chatboxOutline,
      href: getRoutePathForPage(Page.MyChats),
    },
    {
      name: Page.Profile,
      title: "Profile",
      icon: peopleOutline,
      href: getRoutePathForPage(Page.Profile),
    },
    {
      name: Page.Help,
      title: "Help",
      icon: helpBuoy,
      href: getRoutePathForPage(Page.Help),
    },
  ],
}

export const teamRoutingWithTabs: Record<TeamType | 'no-team', RoutingWithTabs> = {
  "no-team": {
    pages: [
      Page.Onboarding,
      redirectEverythingTo(Page.Onboarding),
    ],
    tabs: [
    ],
  },
  ARCHITECT: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      Page.CreateProject,
    ],
  },
  CONTRACTOR: {
    ...defaultRoutingWithTabs,
  },
  HOMEOWNER: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      Page.CreateProject,
    ],
  },
  WEAVER: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      Page.CreateProject,
    ],
  },
}

const getRoutingWithTabsKey = (myTeam?: MyIndividualActiveTeam): TeamType | 'no-team' =>
  myTeam === undefined || myTeam.isOnboardingComplete !== true
    ? 'no-team'
    : myTeam.type

export const getRouterRoutes = (myTeam?: MyIndividualActiveTeam) =>
  teamRoutingWithTabs[getRoutingWithTabsKey(myTeam)].pages
    .map(pageOrElement => {
      const isPage = typeof pageOrElement === 'string'
      if (isPage) {
        const pageConfig = allPages[pageOrElement]
        const pageRender = allPageRenders[pageOrElement]
        return <IonRoute key={pageConfig.path} path={pageConfig.path} exact={pageConfig.exact ?? true} render={pageRender} />
      } else {
        return pageOrElement
      }
    })

export const getRouterTabButtons = (myTeam?: MyIndividualActiveTeam) =>
  teamRoutingWithTabs[getRoutingWithTabsKey(myTeam)].tabs
    .map(tab => (
      <IonTabButton key={tab.name} tab={tab.name} href={tab.href}>
        <IonIcon icon={tab.icon} />
        <IonLabel>{tab.title}</IonLabel>
      </IonTabButton>
    ))

export const useNavigateToPage = () => {
  const history = useHistory()
  return (page: Page) => {
    const path = getRoutePathForPage(page)

    if (!path) {
      console.error(`[useNavigateToPage] Page '${page}' has no path!`)
      return
    }

    history.push(path)
  }
}
