import { z } from "zod"
import { Currency } from "./generated"

/**
 * These are currently manually created to map onto the `generated.ts` types
 */

export const zMoney = z.object({
  currency: z.nativeEnum(Currency),
  amountInPence: z.number().nonnegative().min(0).max(Number.MAX_SAFE_INTEGER, "Number to large"),
})

export const zPlusCodeInput = z.object({
  compound_code: z.string().nullish(),
  global_code: z.string(),
})

export const zLocationInput = z.object({
  lat: z.number(),
  lng: z.number(),
})

export const zViewportInput = z.object({
  northeast: zLocationInput,
  southwest: zLocationInput,
})

export const zGeometryInput = z.object({
  location: zLocationInput,
  location_type: z.string().nullish(),
  viewport: zViewportInput.nullish(),
})

export const zAddressComponentInput = z.object({
  long_name: z.string(),
  short_name: z.string(),
  types: z.array(z.string()),
})

export const zGoogleGeocodedAddressInput = z.object({
  address_components: z.array(zAddressComponentInput).nullish(),
  formatted_address: z.string().nullish(),
  geometry: zGeometryInput,
  place_id: z.string().nullish(),
  plus_code: zPlusCodeInput.nullish(),
  types: z.array(z.string()).nullish(),
})
