import React from 'react'
import { useSetupSentry } from './useSetupSentry'

export const SentryContext = React.createContext<boolean | undefined>(undefined)

export const SentryProvider: React.FC= ({ children }) => {
  // This component exposes isSentryInitialised via SentryContext.Provider because in the future we will want to capture errors explicitly
  // using (Sentry.captureException) and we'll need to know whether or not Sentry was initialised.
  const isSentryInitialised = useSetupSentry()
  return (
    <SentryContext.Provider value={isSentryInitialised}>
      {children}
    </SentryContext.Provider>
  )
}

export default SentryProvider
