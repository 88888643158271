import React from 'react'
import classnames from 'classnames'
import { getFullNameFromIndividual } from './utils'
import ChatMessageDocumentItem from './ChatMessageDocumentItem'
import Styles from "./Chat.module.scss"
import { TChatRoomMessage, TChatRoomMessageDetailsProps, TChatRoomMessageSentByCurrentUserProp } from './ChatRoomTypes'
import { isFilenameExtensionAnImageExtension } from '../../../common/utils/files'
import ChatMessageImageItem from './ChatMessageImageItem'

export type ChatMessageWithDocumentsBubbleProps = TChatRoomMessage & TChatRoomMessageDetailsProps & TChatRoomMessageSentByCurrentUserProp

const ChatMessageWithDocumentsBubbleDetails: React.FC<TChatRoomMessageDetailsProps> = ({ sentByIndividual }) => {
  return (
    <span className={Styles.chatBottomDetails}>
      <span className={Styles.chatBottomSenderName}>{getFullNameFromIndividual(sentByIndividual)}</span>
    </span>
  )
}

export const ChatMessageWithDocumentsBubble: React.FC<ChatMessageWithDocumentsBubbleProps> = ({ id, content, sentByIndividual, chatRoomMessageDocuments, sentByCurrentUser }) => {
  return (
    <div className={classnames({ [Styles.chatBubbleParentSent]: sentByCurrentUser, [Styles.chatBubbleParentReceived]: !sentByCurrentUser })}>
      <div key={id} className={classnames({ [Styles.chatMessageWithDocumentsBubble]: true, [Styles.bubbleSent]: sentByCurrentUser, [Styles.bubbleReceived]: !sentByCurrentUser })} >
        {
          chatRoomMessageDocuments &&
          chatRoomMessageDocuments.map((document) => {

            if (isFilenameExtensionAnImageExtension(document.fileName)){
              return <ChatMessageImageItem key={document.id} {...document}  />
            }
            return <ChatMessageDocumentItem key={document.id} {...document} />
          },
          )
        }
        {content}
        <ChatMessageWithDocumentsBubbleDetails sentByIndividual={sentByIndividual} />
      </div>
    </div>
  )
}

export default ChatMessageWithDocumentsBubble
