import React, { useState } from 'react'
import { IonGrid, IonRow, IonCol, IonIcon, IonSpinner, IonButton, isPlatform } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import Styles from "./Chat.module.scss"
import { formatBytes } from '../../../common/utils'
import { TChatRoomMessageDocument } from './ChatRoomTypes'
import { convertBlobToFile, openFile, saveFileToDeviceStorage, saveFileToDownloadsFolder } from '../../../common/utils/files'

const ChatMessageDocumentItem: React.FC<TChatRoomMessageDocument> = ({ fileName, fileSizeInBytes, signedUrlForDownload, fileContentType }) => {
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)

  const downloadFile = async (downloadUrl: string, fileName: string) => {
    try {
      setIsDownloadingInProgress(true)

      const result = await fetch(downloadUrl)
      const blob = await result.blob()

      if (isPlatform('desktop')) {
        saveFileToDownloadsFolder(fileName, blob)
      }

      const file = convertBlobToFile(blob, fileName)
      await saveFileToDeviceStorage(file)
      setIsDownloadingInProgress(false)
    } catch (err) {
      setIsDownloadingInProgress(false)
      throw new Error(`File ${fileName} could not be saved. ${err}`)
    }
  }

  return (
    <div className={Styles.chatDocumentItem}>
      <IonGrid>
        <IonRow>
          <IonCol size="9"  onClick={() => {openFile(fileName, fileContentType)}}>
            <div className={Styles.chatMessageDocumentName}>{fileName}</div>
            {
              fileSizeInBytes &&
              <div className={Styles.chatMessageDocumentSize}><p>{formatBytes(fileSizeInBytes)}</p></div>
            }
          </IonCol>
          <IonCol size="2">
            <div>
              {
                isDownloadingInProgress
                  ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
                  : <IonButton fill='clear' size='small' onClick={() => downloadFile(signedUrlForDownload, fileName)}><IonIcon className={Styles.downloadIcon} icon={cloudDownloadOutline} /></IonButton>
              }
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default ChatMessageDocumentItem
