import React, { useState } from 'react'
import { IonButton } from '@ionic/react'
import { useCreateContractorProfileMutation } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'

type CreateMyContractorProfileButtonProps = Parameters<typeof IonButton>[0]

/**
 * HACK: This button shouldn't be necessary and is flawed.
 *
 * 1. It only works for the logged in team
 * 2. It assumes you are a CONTRACTOR (no check)
 * 3. CompanyRegisteredName should come from the API (we default it poorly here) ... we need to expose the lookup API
 *
 * Really, we should have a backend consistency check - perhaps in the coming Ops UI.
 */
const CreateMyContractorProfileButton: React.FC<CreateMyContractorProfileButtonProps> = (props) => {
  const myTeam = useMyIndividualActiveTeam()

  const datasource = useGraphQLDataSource({ api: 'core' })
  const createContractorProfile = useCreateContractorProfileMutation(datasource)

  const [ isCreatingContractorProfile, setCreatingContractorProfile ] = useState(false)
  const doCreateContractorProfile = async () => {
    if (!myTeam) throw new Error('[CreateMyContractorProfileButton] No team!')
    if (!myTeam.companyNumber) throw new Error('[CreateMyContractorProfileButton] Team is not a company team!')

    setCreatingContractorProfile(true)
    await createContractorProfile.mutateAsync({
      input: {
        contractorTeamId: myTeam.id,
        companyRegisteredName: myTeam.name ?? '',
        companyTradingAs: myTeam.name,
        companyNumber: myTeam.companyNumber,
      },
    })
    setCreatingContractorProfile(false)
  }

  return <IonButton {...props} onClick={doCreateContractorProfile} disabled={isCreatingContractorProfile} />

}

export default CreateMyContractorProfileButton
