import React from "react"
import { IonBadge, IonIcon, IonItem, IonText } from "@ionic/react"
import { allIonIcons } from "../../../common/components/IonIconTyped/icons"
import { alwaysArray } from "../../../common/utils"
import { moneyToText } from "../../../common/utils/string"
import { ProgressionStatus } from "../../../graphql/generated"
import { GetContractorProfileWorkHistory, getProgressionStatusForWorkHistoryItem } from "../ContractorLeadPreferences/ContractorBudgetRanges/datasource"
import { useRouteToContractorWorkHistory } from "../../../routes"
import { projectTypeLabels } from "../../onboarding/team/onboarding.i18n"
import Styles from "./ContractorProfileWorkHistory.module.scss"

type ContractorProfileWorkHistoryProps = {
  contractorProfileId: string,
  workHistory?: GetContractorProfileWorkHistory[] | null,
}

const acceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

const ContractorProfileWorkHistory: React.FC<ContractorProfileWorkHistoryProps> = ({ contractorProfileId, workHistory }) => {
  const routeToContractorWorkHistory = useRouteToContractorWorkHistory()

  const acceptableWorkHistory = alwaysArray(workHistory)
    .filter(workHistoryItem => acceptableStatuses.includes(getProgressionStatusForWorkHistoryItem(workHistoryItem)))

  return acceptableWorkHistory.length > 0
    ? <div className={Styles.contractorWorkHistoryContainer}>
      <h3>Work History</h3>
      {acceptableWorkHistory.map(workHistoryItem =>  
        <IonItem
          key={workHistoryItem.id}
          button
          lines="none"
          detail={false}
          onClick={routeToContractorWorkHistory(contractorProfileId, workHistoryItem.id)}
        >
          <IonIcon icon={allIonIcons.homeOutline} />
          <div className={Styles.workHistoryTextContainer}>
            {workHistoryItem.projectAddress && <p className={Styles.projectAddress}>{workHistoryItem.projectAddress.formatted_address}</p>}
            <p key="constructionValue" className={Styles.costOfWorkText}>Cost of Work: {moneyToText(workHistoryItem.constructionValue, { withPennies: true })}</p>
            <p key="projectTypes">
              {alwaysArray(workHistoryItem.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
            </p>
          </div>
        </IonItem>,
      )}
    </div>
    :  <div className={Styles.workHistoryTextContainer}>
      <h3>Work History</h3>
      <p className={Styles.noWorkHistoryText}>No work history has been added</p>
    </div>
}

export default ContractorProfileWorkHistory
