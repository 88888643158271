import React from 'react'
import { alwaysArray } from '../../../common/utils'
import { IonContent, IonPage } from '@ionic/react'
import { MyChatsRow } from './MyChatsRow'
import { useMyChatRooms } from '../../../api/services/chat/useMyChatRooms'
import { Virtuoso } from 'react-virtuoso'
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import Styles from "./MyChats.module.scss"

const PACKAGE = 'techDemo.chats'

const MyChats: React.FC = () => {
  return (
    <IonPage>
      <GlobalHeader pageTitle='My Chats'/>
      <IonContent fullscreen>
        <MyChatRooms />
      </IonContent>
    </IonPage>
  )
}

const MyChatRooms: React.FC = () => {
  const LOCAL_PACKAGE = `${PACKAGE}.MyChatRooms`
  console.debug(`[${PACKAGE}] Render:`)

  const myChatRooms = useMyChatRooms()

  return myChatRooms === undefined
    ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useMyChatRooms`} />
    : myChatRooms === null
      ? <p>There is an error loading my chatrooms!</p>
      : myChatRooms.length === 0
        ? <p>You have not joined any chatrooms yet!</p>
        : <IonContent className={Styles.myChatContentContainer}>
          <Virtuoso
            style={{ height: "100%" }}
            data={myChatRooms}
            itemContent={index => {
              const chatRoom = myChatRooms[index]
              return (
                <MyChatsRow
                  key={chatRoom.id}
                  id={chatRoom.id}
                  name={chatRoom.name}
                  messages={alwaysArray(chatRoom.messages)}
                />
              )
            }}
          />
        </IonContent>
}

export default MyChats
