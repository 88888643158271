import React, { MouseEvent } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import OnboardingTeamIndex from './team'
import NotImplementedPage from '../../common/components/NotImplementedPage'
import TeamTypeSelector from './TeamTypeSelector'
import { TeamType } from '../../graphql/generated'

const baseURL = '/onboarding'
export const pathForTeamFn = (teamType?: TeamType) => teamType === undefined
  ? baseURL
  : `${baseURL}/team/${teamType}/`

export const useSwitchToOnboardingTeam = (teamType?: TeamType) => {
  const history = useHistory()

  return (event?: MouseEvent<unknown, globalThis.MouseEvent>) => {
    const url = pathForTeamFn(teamType)

    event && event.preventDefault()
    history.push(url)
  }
}

export const OnboardingIndex: React.FC = () => {
  const match = useRouteMatch()
  console.debug(`[OnboardingIndex] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="onboardingIndex">
        <Route path={`${baseURL}`} component={TeamTypeSelector} exact />
        <Route path={`${baseURL}/team/:teamType`} component={OnboardingTeamIndex} />
        <Route component={NotImplementedPage} />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default OnboardingIndex
