import { useState } from "react"

export type UseNewMessageFiles = {
  newMessageFiles: File[],
  addFileToNewMessage: (file: File) => void,
  addFilesToNewMessage: (files: File[]) => void,
  removeFileFromNewMessage: (fileToRemove: File) => void,
  clearFilesFromNewMessage: () => void,
}

const useNewMessageFiles = (): UseNewMessageFiles => {
  const [ newMessageFiles, setNewMessageFiles ] = useState<File[]>([])

  const addFileToNewMessage = (file: File) => {
    setNewMessageFiles([ ...newMessageFiles, file ])
  }

  const addFilesToNewMessage = (files: File[]) => {
    setNewMessageFiles([ ...newMessageFiles, ...files ])
  }

  const removeFileFromNewMessage = (fileToRemove: File) => {
    setNewMessageFiles([ ...newMessageFiles.filter(file => file.name !== fileToRemove.name) ])
  }

  const clearFilesFromNewMessage = () => setNewMessageFiles([])

  return {
    newMessageFiles,
    addFileToNewMessage,
    addFilesToNewMessage,
    removeFileFromNewMessage,
    clearFilesFromNewMessage,
  }
}

export default useNewMessageFiles
