import { DateTime } from "luxon/src/datetime"

export const INVITE_PARAM_KEY = "claimInviteId"
export const STORED_INVITE_KEY = "pendingClaimInviteId"

export type StoredInvite = {
  inviteId: string | undefined,
  addedAt: DateTime | undefined,
}

export const EMPTY_INVITE: StoredInvite = {
  inviteId: undefined,
  addedAt: undefined,
}
