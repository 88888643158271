import React, { useState } from "react"
import { downloadProjectDocumentFile, fileIconSelector, openFile } from "../../common/utils/files"
import { cloudDownloadOutline } from "ionicons/icons"
import { DateTime } from 'luxon'
import { formatBytes } from "../../common/utils"
import { IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonSpinner, IonThumbnail } from "@ionic/react"
import { ProjectDocument, Team } from "../../graphql/generated"
import Styles from "./ShowProject.module.scss"

type ProjectUploads = {
  projectDocument: Pick<ProjectDocument, 'id' | 'fileName' | 'fileSizeInBytes' |'signedUrlForDownload' | 'fileContentType' | 'updatedAt'> & { uploadedByTeam?: Pick<Team, 'name'> | null },
  isDownloadingAll: boolean,
}

const ProjectDocumentRow: React.FC<ProjectUploads> = ({ projectDocument, isDownloadingAll = false }) => {
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(isDownloadingAll)

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  return (
    <IonCard className={Styles.projectDocumentCard}>
      <IonItem>
        <IonThumbnail onClick={() => openFile(projectDocument.fileName, projectDocument.fileContentType)}>
          <IonImg src={fileIconSelector(projectDocument.fileName)} />
        </IonThumbnail>
        <IonLabel>
          { projectDocument.uploadedByTeam && <p>Author: {projectDocument.uploadedByTeam?.name}</p> }
          <h3>{projectDocument.fileName}</h3>
          { projectDocument.fileSizeInBytes && <p>{formatBytes(projectDocument.fileSizeInBytes)}</p>}
        </IonLabel>
        <div className={Styles.iconDateContainer}>
          <IonLabel>
            <p>{DateTime.fromISO(projectDocument.updatedAt).toLocaleString(DateTime.DATE_SHORT)}</p>
          </IonLabel>
          {
            isDownloadingInProgress
              ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
              : <IonButton
                className={Styles.downloadButton}
                fill='clear' size='small'
                onClick={() => downloadProjectDocumentFile(projectDocument, onProgressUpdate)}>
                <IonIcon icon={cloudDownloadOutline}/>
              </IonButton>
          }
        </div>
      </IonItem>
    </IonCard>
  )
}
export default ProjectDocumentRow
