import React from 'react'
import SuccessPage from '../../../common/components/SuccessPage/SuccessPage'

type UploadedProjectDocumentSuccessPageProps = {
  routerLink?: string,
}

const UploadedProjectDocumentSuccessPage: React.FC<UploadedProjectDocumentSuccessPageProps> = ({ routerLink }) => {

  return (
    <SuccessPage routerLink={routerLink} header= 'Success!' text= 'Documents successfully uploaded' buttonText= 'Continue!'/>
  )
}
export default UploadedProjectDocumentSuccessPage
