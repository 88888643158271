import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { useAuthContext, WeaverIdTokenClaims } from '../../api/providers/AuthProvider'
import RoutedSwiper from '../../common/components/RoutedSwiper'
import TitleWithBulletsPage from '../../domains/onboarding/team/pages/TitleWithBulletsPage'
import builderPng from '../../assets/images/builder.png'
import { phonePortrait } from 'ionicons/icons'
import MobileNumberCapture from '../../common/components/MobileNumberCapture'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useSetIndividualPhoneNumberMutation } from '../../graphql/generated'
import { useGraphQLDataSource } from '../../api/graphql'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'

// Check if aspect of 'minimum-individual-profile' been disabled
const useIsFlagEnabled = () => !useWeaverFlags()['minimum-individual-profile'].disableAspect?.['require-phone-number']

export const RequirePhoneNumber: React.FC = ({ children }) => {
  const isFlagEnabled = useIsFlagEnabled()
  const auth = useAuthContext()

  const phoneNumber = auth.userData?.[WeaverIdTokenClaims.WeaverPhoneNumber]
  console.debug('[MinimalIndividualProfile.RequirePhoneNumber] Phone number: ', { auth, phoneNumber })

  return isFlagEnabled && phoneNumber == null
    ? <CollectPhoneNumber />
    : <>{children}</>
}

const zFormSchema = z.object({
  phoneNumber: z.string(),
}).superRefine((validated, ctx) => {
  if (!isPossiblePhoneNumber(validated.phoneNumber)) {
    ctx.addIssue({
      path: [ "phoneNumber" ],
      code: z.ZodIssueCode.custom,
      message: "A valid Phone Number is required",
    })
  }
})

type FormSchema = z.infer<typeof zFormSchema>

const CollectPhoneNumber: React.FC = () => {
  // Form Setup
  const { control, trigger, handleSubmit } = useForm<FormSchema>({
    defaultValues: {},
    resolver: zodResolver(zFormSchema),
  })

  // Mutations
  const auth = useAuthContext()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setIndividualPhoneNumberMutation = useSetIndividualPhoneNumberMutation(gqlDataSource)

  const triggerEvent = useAnalyticsEvent('Onboarding_Contact_Number_Entered')

  // Handle Submission
  const onSubmit = async (form: FormSchema): Promise<void> => {
    // This sets the Phone Number in Auth0, making it available in the tokens the next time they're generated
    await setIndividualPhoneNumberMutation.mutateAsync(form)

    await triggerEvent({
      // country code can be between 2(USA) & 5 characters (British Virgin Islands)
      countryCode: form.phoneNumber.slice(0,5),
      // should inclusion of country code and next 4 digits (based off the UK)
      areaCode: form.phoneNumber.slice(0,7),
    })

    // Trigger auth token refresh
    await auth.forceRefresh()
  }

  // Render the Swiper
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RoutedSwiper
        pages={[
          {
            name: 'noticeNeedPhoneNumber',
            render: () => (
              <TitleWithBulletsPage
                title="Thanks for Registering!"
                imgProps={{
                  src: builderPng,
                }}
                bullets={[
                  { description: 'We need your phone number to verify your account.', icon: phonePortrait },
                ]}
              />
            ),
          },
          {
            name: 'captureMobileNumber',
            render: () => (
              <Controller
                control={control}
                name="phoneNumber"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <MobileNumberCapture
                    value={value}
                    onChange={onChange}
                    error={error}
                    errorMessage={'A valid Phone Number is required.'}
                  />
                )}
              />
            ),
            canGoForward: async () => await trigger([ 'phoneNumber' ]),
          },
        ]}
        onLastPageGoForward={handleSubmit(onSubmit)}
      />
    </form>
  )
}
