import React, { useContext } from 'react'
import { useAuthContext } from '../AuthProvider'
import { useEnvironmentContext } from '../EnvironmentProvider'
import { useMyIndividual } from '../MyIndividualProvider/MyIndividualProvider'

type Bootstrapped = {
  auth: ReturnType<typeof useAuthContext>,
  environment: ReturnType<typeof useEnvironmentContext>,
  myIndividual: ReturnType<typeof useMyIndividual>,
}

export const BootstrappedContext = React.createContext<Bootstrapped | undefined>(undefined)

export const BootstrappedProvider: React.FC = ({ children }) => {
  const auth = useAuthContext()
  const environment = useEnvironmentContext()
  const myIndividual = useMyIndividual()

  const bootstrapped: Bootstrapped = {
    auth,
    environment,
    myIndividual,
  }

  return (
    <BootstrappedContext.Provider value={bootstrapped}>
      {children}
    </BootstrappedContext.Provider>
  )
}

export const useBootstrappedContext = (): Bootstrapped => {
  const bootstrapped = useContext(BootstrappedContext)

  if (bootstrapped === undefined) {
    const errorMessage = '[BoostrappedProvider.useBootstrappedContext] Bootstrapped is not yet available. This should not happen!'
    console.error(errorMessage, { bootstrapped })
    throw new Error(errorMessage)
  }

  return bootstrapped
}

export default BootstrappedProvider
