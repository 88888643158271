import { IonAlert, IonButton, IonContent, IonInput, IonItem, IonPage, IonText, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery, useUpdateArchitectProfileMutation } from '../../../graphql/generated'
import EditProfileHeader from '../EditProfileHeader'
import Styles from '../EditProfile.module.scss'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import ErrorBlock from '../../../common/components/ErrorBlock'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type EditArchitectProfileInputs = {
  companyTradingAs: string,
}

export const EditArchitectProfileSchema = z.object({
  companyTradingAs: z.string().nonempty('Required'),
})

type EditArchitectProfileProps = {
  id : string,
}

export const EditArchitectProfile: React.FC<EditArchitectProfileProps> = ({ id }) => {
  const queryClient = useQueryClient()
  const [ showUnsuccessfulAlert, setShowUnsuccessfulAlert ] = useState(false)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetArchitectProfileQuery(gqlDataSource, { architectTeamId: id })
  const updateArchitectProfile = useUpdateArchitectProfileMutation(gqlDataSource)
  const router = useIonRouter()

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<EditArchitectProfileInputs>({ resolver: zodResolver(EditArchitectProfileSchema) })

  if (isFetching) {
    return <LoadingSpinner name="EditArchitectProfile"/>
  }

  if (error) {
    console.error(`[EditArchitectProfile] Failed to load Trading As: `, {  data, error })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  if (data?.getArchitectProfile?.companyTradingAs == null) {
    console.warn(`[EditArchitectProfile] Trading As is missing: `, { data, error })
    return <IonPage>
      <IonContent fullscreen>
        <CentralisedBlock>
          <p>Unable to find the Architect Profile details.</p>
        </CentralisedBlock>
      </IonContent>
    </IonPage>
  }

  const { companyTradingAs } = data.getArchitectProfile
  setValue('companyTradingAs', companyTradingAs)
  const goBack = () => router.goBack()

  const onSubmit = async (data: EditArchitectProfileInputs) => {
    await updateArchitectProfile.mutateAsync({ input: { id, companyTradingAs: data.companyTradingAs } }, {
      onSuccess: () => {
        queryClient.invalidateQueries('getArchitectProfile')
        goBack()
      },
      onError: () => {
        setShowUnsuccessfulAlert(true)
      },
    })
  }

  return <IonPage>
    <EditProfileHeader title='Trading Name' />
    <IonContent className={`${Styles.ionContent} ion-padding`} fullscreen>
      <ResponsiveContentWrapper>
        <IonText>
          <h5> Change your Trading Name</h5>
        </IonText>
        <IonText className={Styles.lightGrey}>
          <p> This is the name clients will see on your profile </p>
        </IonText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonItem className={Styles.ionItemInput} lines="none">
            <IonInput type='text' {...register("companyTradingAs", { required: true }) } />
          </IonItem>

          <IonItem lines='none' className={`${Styles.ionItem} ion-no-padding`}>
            {errors.companyTradingAs &&<IonText color='danger'>
              <p>This field is required</p>
            </IonText>
            }
          </IonItem>

          <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
            <IonButton type='submit' className={`${Styles.confirmButton} ion-no-padding`}>CONFIRM</IonButton>
          </IonItem>

          <IonAlert
            isOpen={showUnsuccessfulAlert}
            onDidDismiss={() => setShowUnsuccessfulAlert(false)}
            header={'Oops! Something has gone wrong.'}
            buttons={[ 'Dismiss' ]}
          />
        </form>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}
