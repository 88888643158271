import React, { useState } from 'react'
import { IonButton, IonList, IonItem, IonLabel, IonInput, InputChangeEventDetail, IonListHeader } from '@ionic/react'
import { useJwtManagerPersistItem } from '../../../api/services/jwtManager/useJwtManagerPersistItem'

const NewJwtForm: React.FC = () => {
  const persistJwt = useJwtManagerPersistItem()
  const [ passcode, setPasscode ] = useState('123456')

  return (
    <form onSubmit={event => {
      event.preventDefault()
      persistJwt.mutate({ passcode })
    }}>
      <IonList>
        <IonListHeader>
          <h2>Add your JWT</h2>
        </IonListHeader>
        <IonItem>
          <IonLabel>Encryption Passcode</IonLabel>
          <IonInput name='passcode' type='number' value={passcode} onIonChange={(event: CustomEvent<InputChangeEventDetail>) => setPasscode(event.detail.value ?? '')} />
        </IonItem>
      </IonList>
      <IonButton type="submit">Persist JWT</IonButton>
    </form>
  )
}

export default NewJwtForm
