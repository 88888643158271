import React from 'react'
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import Styles from './TitleWithDescription.module.scss'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type TitleWithDescriptionPageProps = {
  title: string,
  titleClassName?: string,
  description: string,
  topDivBackground?: React.PropsWithRef<JSX.IntrinsicElements['div']>,
  topDivContents?: JSX.Element,
}

const TitleWithDescriptionPage: React.FC<TitleWithDescriptionPageProps> = ({ title, titleClassName, description, topDivBackground, topDivContents }) => {
  return (
    <>
      <div className={`${Styles.topBackground} ion-text-center ${topDivBackground?.className}`}>
        {topDivContents}
      </div>
      <ResponsiveContentWrapper>
        <IonGrid>
          <IonRow className='ion-justify-content-center ion-align-items-center'>
            <IonCol className={`ion-text-sm-center ion-text-start ion-padding`}>
              <h3 className={`ion-margin-bottom ${titleClassName}`}><b>{title}</b></h3>
              <IonText color='medium' className={Styles.ionText}>{description}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ResponsiveContentWrapper>
    </>
  )
}

export default TitleWithDescriptionPage
