import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useIonAlert } from '@ionic/react'
import { useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { prettifyCompanyName } from '../../companyNameHelpers'
import { useSwitchToOnboardingTeam } from '../../..'
import { useOnboardArchitectTeam } from '../../hooks'
import { useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { zGoogleGeocodedAddressInput } from '../../../../../graphql/zod'
import RoutedSwiper from '../../../../../common/components/RoutedSwiper'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import CompanyDetailsFind from '../../pages/CompanyDetailsFind'
import CompanyDetailsEdit from '../../pages/CompanyDetailsEdit'
import TitleWithBulletsPage from "../../pages/TitleWithBulletsPage"
import CorporateStructureSelector from '../../../CorporateStructureSelector'
import { CorporateStructureType, TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { architectSteps } from '../../pages/OnboardingSignupSteps/config'
import PickAddress from '../../../../../common/components/PickAddress'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import ConfettiPage from '../../../../../common/components/ConfettiPage'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useContactSupportContext } from '../../../../../api/providers/ContactSupportProvider/ContactSupportProvider'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'

const zFormSchema = z.object({
  corporateStructure: z.enum([ CorporateStructureType.LimitedCompany, CorporateStructureType.SoleTrader, CorporateStructureType.Partnership, CorporateStructureType.Other ]),
  company: z.object({
    companyName: z.string(),
    companyNumber: z.string(),
  }),
  companyTradingAs: z.string().min(1, 'Required'),
  companyAddress: zGoogleGeocodedAddressInput,
})

type FormSchema = z.infer<typeof zFormSchema>

const OnboardingArchitectStageOne: React.FC = () => {
  const match = useRouteMatch()

  // A flag to prevent pages being swiped while we are doing network stuff
  const [ isSubmitting, setSubmitting ] = useState<boolean>(false)
  const { register, trigger, setValue, getValues, resetField, formState: { errors } } = useForm<FormSchema>({
    defaultValues: {},
    resolver: zodResolver(zFormSchema),
  })

  // Analytics
  const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('WorkHistory_References_Added_Past_Project_Address')
  const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Complete')

  // Error Handler
  const [ present ] = useIonAlert()

  // Contact Support overlay
  const contactSupportAPI = useContactSupportContext()

  // Finished Handler -- the ultimate goal of this form!
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
  const onboardArchitectTeamFn = useOnboardArchitectTeam()
  const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()
  const history = useHistory()

  const onSubmit = async (data: FormSchema) => {
    let success = false

    try {
      setSubmitting(true)

      const onboardResult = await onboardArchitectTeamFn({
        companyNumber: data.company.companyNumber,
        companyRegisteredName: data.company.companyName,
        companyTradingAs: data.companyTradingAs,
        companyAddress: data.companyAddress,
      })

      await setOnboardingCompleteMutation.mutateAsync({
        teamId: onboardResult.team.id,
        isOnboardingComplete: true,
      })

      success = true
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Failed to Onboard Architect Team",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }

    setSubmitting(false)
    return success
  }

  const handleSlideSubmit = async () => {
    const value = getValues('companyAddress')

    await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
      city: (value?.address_components?.find(address_component => address_component.types.includes("postal_town")))?.long_name,
      firstPartOfPostCode: (value?.address_components?.find(address_component => address_component.types.includes("postal_code")))?.long_name.slice(0,4),
    })

    const ready = await trigger()
    if (!ready) return { abort: true }
    const submitSuccess = await onSubmit(getValues())

    if (!submitSuccess) return { abort: true }
  }

  // Go back to the TeamSelector
  const switchToOnboardingTeam = useSwitchToOnboardingTeam()

  console.debug('[OnboardingArchitectStageOne] Render ', { match, isSubmitting, formValues: getValues() })
  return (
    <form>
      <RoutedSwiper
        pages={[
          {
            name: 'introduction',
            render: () => <SuccessNotice
              caption="Brilliant!"
              message="You're almost ready to find builders for your project."
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting,
          },
          {
            name: 'signupStepsFirst',
            render: (goToNextPage) => <OnboardingSignupSteps caption="Sign up to Weaver" message="Complete all tasks and start finding builders for your projects." steps={architectSteps} currentPageIndex={1} goToNextPage={goToNextPage} />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting,
          },
          {
            name: 'companyDetailsSignpost',
            render: () => <TitleWithBulletsPage
              title='Next enter your company details'
              imgProps={{
                src: builderPng,
              }}
              bullets = {[
                { description: 'Find your company name on Companies House Services', icon: shieldCheckmark } ,
                { description: 'Confirm a trading name that contractors on Weaver will see', icon: shieldCheckmark },
              ]}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting,
          },
          {
            name: 'corporateStructureSelect',
            render: () => <CorporateStructureSelector
              value={getValues("corporateStructure")}
              setValue={async corporateStructure => {
                if (corporateStructure) {
                  setValue("corporateStructure", corporateStructure)
                  await trigger([ 'corporateStructure' ])
                } else {
                  resetField('corporateStructure')
                }
              }}
              hasError={errors.corporateStructure !== undefined}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting && await trigger([ 'corporateStructure' ]),
            beforeGoForwardOnClick: async () => {
              const corporateStructure = getValues('corporateStructure')
              const contactSupport = corporateStructure !== CorporateStructureType.LimitedCompany

              if (contactSupport) {
                contactSupportAPI.showContactSupport()
                return { abort: true }
              }
            },
          },
          {
            name: 'companyDetailsFind',
            render: () => <CompanyDetailsFind
              value={getValues("company")}
              setValue={company => {
                if (company) {
                  setValue("company", company)
                  setValue("companyTradingAs", prettifyCompanyName(company.companyName))
                } else {
                  resetField('company')
                  resetField('companyTradingAs')
                }
              }}
              hasError={errors.companyTradingAs !== undefined || errors.company !== undefined}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting && await trigger([ 'company', 'companyTradingAs' ]),
          },
          {
            name: 'companyDetailsEdit',
            render: () => <CompanyDetailsEdit
              company={getValues("company")}
              register={{
                companyTradingAs: register('companyTradingAs'),
              }}
              errors={errors}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting && await trigger([ 'companyTradingAs' ]),
          },
          {
            name: 'companyAddressSelect',
            render: () => <PickAddress
              title="Enter your Company Address"
              value={getValues("companyAddress")}
              setValue={companyAddress => {
                if (companyAddress) {
                  setValue("companyAddress", companyAddress)
                } else {
                  resetField('companyAddress')
                }
              }}
              hasError={errors.companyAddress !== undefined}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting && await trigger([ 'companyAddress' ]),
            beforeGoForwardOnClick: handleSlideSubmit,
          },
          {
            name: 'stageOneComplete',
            render: () => <ConfettiPage
              caption="Amazing!"
              message="Thank you for adding your company information."
              onClick={async () => {
                await triggerEventOnboardingCompanyComplete({
                  teamType: TeamType.Architect,
                })
                await useMyIndividualInvalidateCacheFn()
                history.push('/')
              }}
            />,
            hideButtons: true,
          },
        ]}
        onFirstPageGoBack={switchToOnboardingTeam}
        onLastPageGoForward={() => {
          console.error('[OnboardingArchitectStageOne.onLastPageGoForward] This should never trigger!')
        }}
      />
    </form>
  )
}

export default OnboardingArchitectStageOne
