import { DateTime } from "luxon"
import { useEffect, useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useStoredValue } from "../../../api/providers/StorageProvider"
import { EMPTY_INVITE, INVITE_PARAM_KEY, StoredInvite, STORED_INVITE_KEY } from "./common"

/**
 * A component for capturing querystring invites and capturing them into storage for a bootstrapped app to pick up
 *
 * This two-part architecture required so that before-auth or before-signup users can still claim their invites even
 * after they've been sent on a redirection-auth-loop
 */
export const InviteCapture: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const queryParams = useMemo(() => new URLSearchParams(location.search), [ location.search ])
  const [ , setStoredInvite ] = useStoredValue<StoredInvite>({ key: STORED_INVITE_KEY, initialValue: EMPTY_INVITE })

  useEffect(() => {
    async function claim() {
      const inviteId = queryParams.get(INVITE_PARAM_KEY)
      if (!inviteId) return

      console.debug("[InviteCapture] storing invite:", inviteId)

      setStoredInvite({
        inviteId,
        addedAt: DateTime.now(),
      })

      const nextParams = new URLSearchParams(queryParams)
      nextParams.delete(INVITE_PARAM_KEY)

      console.debug("[InviteClaimer] removing invite search param from URL. nextParams: ", nextParams)
      history.replace({
        search: nextParams.toString(),
      })
    }
    claim()
  }, [ queryParams, history, setStoredInvite ])

  return null
}
