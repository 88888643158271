import React, { useEffect } from "react"

import { App, AppState } from "@capacitor/app"
import { useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react"
import { useChatRoom } from "./services/ChatRoomProvider"

const ChatRoomPresenceTracker : React.FC = ({ children }) => {
  const {
    enterChatRoom,
    leaveChatRoom,
  } = useChatRoom()

  useIonViewDidEnter(async () => {
    await enterChatRoom()
  })

  useIonViewDidLeave(async () => {
    await leaveChatRoom()
  })

  useEffect(() => {
    // This listener is called when the user changes tabs or minimises the app.
    const listener = App.addListener('appStateChange', (state: AppState) => {
      if (state.isActive == true) {
        enterChatRoom()
      } else {
        leaveChatRoom()
      }
    })

    return () => {
      listener.remove()
    }
  }, [])

  return <>{children}</>
}

export default ChatRoomPresenceTracker
