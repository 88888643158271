import { IonIcon } from "@ionic/react"
import { checkmarkCircle, closeCircle, radioButtonOff, radioButtonOn } from "ionicons/icons"
import React from "react"

import Styles from "./IndicatorBar.module.scss"

export type IndicatorBarProps = {
  currentPageIndex: number,
  totalPages: number,
  /** The current maximum available page index.
   *
   * indexes above this will be greyed out and will disallow navigation */
  maxAvailableIndex: number,
  /** called when available navigation items are clicked  */
  onNavigate: (pageIndex: number) => void,
}
const IndicatorBar: React.FC<IndicatorBarProps> = ({ currentPageIndex: page, totalPages: total, maxAvailableIndex: maxPage, onNavigate }) => {
  const pages = new Array(total).fill(null)
  return (
    <div className={Styles.indicator}>
      {pages.map((x, i) => {
        const isCurrent = page === i
        const isAvailable = i <= maxPage
        const isDone = i < page
        const modifiers = [
          ...isCurrent ? [ Styles.active ] : [],
          ...isAvailable ? [] : [ Styles.unavailable ],
          ...isDone ?  [ Styles.done ] : [],
        ]
        let icon = radioButtonOff
        if (isCurrent) {
          icon = radioButtonOn
        } else if (!isAvailable) {
          icon = closeCircle
        } else if (isDone) {
          icon = checkmarkCircle
        }

        const handleClick = () => {
          if (isAvailable) {
            onNavigate(i)
            return true
          }
          return false
        }

        const itemClassName = [ Styles.indicatorItem, ...modifiers ].join(" ")

        return <div key={i} className={itemClassName} >
          <div key={i} onClick={handleClick} >
            <IonIcon icon={icon}  />
          </div>
        </div>
      })}
    </div>
  )
}

export default IndicatorBar
