import { Dispatch, SetStateAction, useEffect, useRef } from "react"

/**
 * A trigger which resets the state to the `initialState` after moving to a `true` value for `isWaiting`.
 * @param isWaiting true | false | undefined.
 * @param setState The state mutation action to call with the `initialState` when resetting.
 * @param initialState Same as what is passed as `useState(initialState)`. If a function, will rerun when `isWaiting` transitions to `true`.
 */
export const useReinitStateAfterWaiting = <S,>(isWaiting: boolean | undefined, setState: Dispatch<SetStateAction<S>>, initialState: S | (() => S)) => {
  const wasWaiting = useRef(isWaiting)

  useEffect(() => {
    // Have I transitioned into false from any other state?
    if (isWaiting === false && wasWaiting.current !== false) {
      setState(initialState)
    }

    // Update the previous state every time it changes
    wasWaiting.current = isWaiting
  }, [ isWaiting ])
}
