import React from 'react'
import { IonImg } from '@ionic/react'
import WeaverIcon from "../../../assets/icons/weaver_icon_white.png"
import Styles from "./DisplaySplashScreen.module.scss"

export const DisplaySplashScreen: React.FC = () =>
  <div className={Styles.imageContainer} >
    <IonImg className={Styles.img} src={WeaverIcon} />
  </div>

export default DisplaySplashScreen
