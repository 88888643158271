import { Camera } from '@capacitor/camera'

export const useImageGallery = () => {
  const pickImages = async () => Camera.pickImages({
    quality: 100,
    limit: 10,
  }).then(({ photos }) => ({ images: photos }))

  return {
    pickImages,
  }
}
