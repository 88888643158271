import { IonAlert, IonButton, IonContent, IonInput, IonItem,IonPage, IonText, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGraphQLDataSource } from '../../../api/graphql'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import ErrorBlock from '../../../common/components/ErrorBlock'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useGetContractorProfileQuery, useUpdateContractorProfileMutation } from '../../../graphql/generated'
import Styles from '../EditProfile.module.scss'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import EditProfileHeader from '../EditProfileHeader'
import { useQueryClient } from 'react-query'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type EditContractorProfileInputs = {
  companyTradingAs: string,
}

export const EditContractorProfileSchema = z.object({
  companyTradingAs: z.string().nonempty('Required'),
})

type EditContractorProfileProps = {
  id: string,
}

const EditContractorProfile: React.FC<EditContractorProfileProps> = ({ id }) => {
  const queryClient = useQueryClient()
  const [ showUnsuccessfulAlert, setShowUnsuccessfulAlert ] = useState(false)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: id })
  const updateContractorProfile = useUpdateContractorProfileMutation(gqlDataSource)
  const router = useIonRouter()

  const { register, setValue, handleSubmit, formState: { errors  } } = useForm<EditContractorProfileInputs>( { resolver: zodResolver(EditContractorProfileSchema), reValidateMode: 'onChange' })

  if (isFetching) {
    return <LoadingSpinner name="EditContractorProfile"/>
  }

  if (error) {
    console.error(`[EditContractorProfile] Failed to load Trading As: `, {  data, error })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  if (data?.getContractorProfile?.companyTradingAs == null) {
    console.warn(`[EditContractorProfile] Trading As is missing: `, { data, error })
    return <IonPage>
      <IonContent fullscreen>
        <CentralisedBlock>
          <p>Unable to find the Contract Profile details.</p>
        </CentralisedBlock>
      </IonContent>
    </IonPage>
  }

  const { companyTradingAs } = data.getContractorProfile
  setValue('companyTradingAs', companyTradingAs)

  const goBack = () => router.goBack()

  const onSubmit = async (data: EditContractorProfileInputs) => {
    await updateContractorProfile.mutateAsync({ input: { id, companyTradingAs: data.companyTradingAs } }, {
      onSuccess: () => {
        queryClient.invalidateQueries('getContractorProfile')
        goBack()
      },
      onError: () => {
        setShowUnsuccessfulAlert(true)
      },
    })
  }

  return <IonPage>
    <EditProfileHeader title='Trading Name' />
    <IonContent className={`${Styles.ionContent} ion-padding`} fullscreen>
      <ResponsiveContentWrapper>
        <IonText>
          <h5> Change your Trading Name</h5>
        </IonText>
        <IonText className={Styles.lightGrey}>
          <p> This is the name clients will see on your profile </p>
        </IonText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonItem className={Styles.ionItemInput} lines="none">
            <IonInput type='text' {...register("companyTradingAs", { required: true }) } />
          </IonItem>

          <IonItem lines='none' className={`${Styles.ionItem} ion-no-padding`}>
            {errors.companyTradingAs &&<IonText color='danger'>
              <p>This field is required</p>
            </IonText>
            }
          </IonItem>

          <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
            <IonButton type='submit' className={`${Styles.confirmButton} ion-no-padding`}>CONFIRM</IonButton>
          </IonItem>

          <IonAlert
            isOpen={showUnsuccessfulAlert}
            onDidDismiss={() => setShowUnsuccessfulAlert(false)}
            header={'Oops! Something has gone wrong.'}
            buttons={[ 'Dismiss' ]}
          />
        </form>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}

export default EditContractorProfile
