import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from "react-query/devtools"

const ENABLE_DEVTOOLS = true

const ReactQueryProvider: React.FC = ({ children }) =>
  <QueryClientProvider client={new QueryClient()}>
    {children}
    {ENABLE_DEVTOOLS && <ReactQueryDevtools initialIsOpen />}
  </QueryClientProvider>

export default ReactQueryProvider
