import React, { useEffect, useRef, useState } from 'react'
import { IonIcon, IonSpinner, IonButton, isPlatform } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import Styles from "./Chat.module.scss"
import { formatBytes } from '../../../common/utils'
import { TChatRoomMessageDocument } from './ChatRoomTypes'
import { convertBlobToFile, openFile, saveFileToDeviceStorage, saveFileToDownloadsFolder, readFileFromDeviceStorage } from '../../../common/utils/files'
import { useChatRoom } from './services/ChatRoomProvider'
import useAbortableEffect from '../../../common/hooks/useAbortableEffect'

const ChatMessageImageItem: React.FC<TChatRoomMessageDocument> = ({ fileName, fileSizeInBytes, signedUrlForDownload, fileContentType }) => {
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)
  const { readFromCache, saveToCache } = useChatRoom()
  const [ imageBlob, setImageBlob ] = useState<Blob>()
  const imgRef = useRef<HTMLImageElement>(null)

  useAbortableEffect(status => {

    const setImageBlobIfComponentIsStillMounted = (blob: Blob) => {
      if (!status.aborted) setImageBlob(blob)
    }

    const readImageBlobFromFS = async () => {
      const blob = await readFileFromDeviceStorage(fileName)
      if (blob) {
        setImageBlobIfComponentIsStillMounted(blob)
        saveToCache(fileName, blob)
      }
    }

    const readImageBlobFromCache = () => {
      const blob = readFromCache(fileName)
      if (blob) {
        setImageBlobIfComponentIsStillMounted(blob)
      } else {
        readImageBlobFromFS()
      }
    }

    readImageBlobFromCache()
  }, [ fileName, readFileFromDeviceStorage, readFromCache, setImageBlob, saveToCache  ])

  useEffect(() => {
    const updateImageSrc = () => {
      if (imageBlob) {
        const url = URL.createObjectURL(imageBlob)
        if (imgRef && imgRef.current) {
          imgRef.current.src = url
        }
      }
    }
    updateImageSrc()
  }, [ imageBlob, setImageBlob ])

  const downloadFile = async (downloadUrl: string, fileName: string) => {
    try {
      setIsDownloadingInProgress(true)
      const result = await fetch(downloadUrl)
      const blob = await result.blob()

      if (isPlatform('desktop')) {
        saveFileToDownloadsFolder(fileName, blob)
      }
      const file = convertBlobToFile(blob, fileName)
      await saveFileToDeviceStorage(file)
      setIsDownloadingInProgress(false)
      setImageBlob(blob)
    } catch (err) {
      setIsDownloadingInProgress(false)
      throw new Error(`File ${fileName} could not be saved. ${err}`)
    }
  }

  return (
    <div className={Styles.chatMessageImageContainer}>
      <img ref={imgRef} className={Styles.chatMessageImage} onClick={() => {openFile(fileName, fileContentType)}}/>

      <div className={Styles.chatMessageImageBottomContainer}>
        <div className={Styles.chatMessageImageDetailsContainer}>
          <div className={Styles.chatMessageDocumentName}>{fileName}</div>
          <div className={Styles.chatMessageDocumentSize}>{fileSizeInBytes ? formatBytes(fileSizeInBytes) : ""}</div>
        </div>

        <div className={Styles.chatMessageImageDownloadContainer}>
          {
            isDownloadingInProgress
              ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
              : <IonButton className={Styles.downloadButton} fill='clear' size='small' onClick={() => downloadFile(signedUrlForDownload, fileName)}>
                <IonIcon className={Styles.downloadIcon} icon={cloudDownloadOutline} />
              </IonButton>
          }
        </div>
      </div>
    </div>
  )
}

export default ChatMessageImageItem
