import CryptoJS from "crypto-js"

export const encryptSymmetrical = (data: string, passcode: string): string => {
  return CryptoJS.AES.encrypt(data, passcode).toString()
}

export const decryptSymmetrical = (cipherText: string, passcode: string): string => {
  const bytes = CryptoJS.AES.decrypt(cipherText, passcode)
  return bytes.toString(CryptoJS.enc.Utf8)
}
