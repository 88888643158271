import { useQuery } from "react-query"
import { z } from "zod"
import { useEnvironmentContext } from "../../providers/EnvironmentProvider"

const zJwtListItems = z.array(
  z.object({
    id: z.string(),
    savedAt: z.string(),
  }),
)
export type JwtListItem = z.infer<typeof zJwtListItems>[number]

export const useJwtManagerListItems = () => {
  const environment = useEnvironmentContext()
  if (environment.apiGateway.devSupport.url === undefined) {
    throw new Error('[useJwtManager] Unable to find the devSupport API url')
  }
  const devSupportURL = environment.apiGateway.devSupport.url

  // List all the JWTs

  const listQueryResult = useQuery([ 'jwtManager', 'list' ], async () => {
    const response = await fetch(devSupportURL)
    const jsonBody = await response.json()
    try {
      return zJwtListItems.parse(jsonBody)
    } catch (error) {
      console.error('[useJwtManagerListItems] Unable to parse JWT list items: ', { response, jsonBody })
      return undefined
    }
  }, {
    staleTime: 10 * 1000, // 10 seconds
  })

  return listQueryResult
}
