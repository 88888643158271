import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { IonBreadcrumb, IonBreadcrumbs, IonContent, IonList, IonModal, IonPage, IonToolbar } from '@ionic/react'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useListProjectTasksQuery, ListProjectTasksQuery } from '../../../graphql/generated'
import Task, { TaskProps } from './Task'
import Styles from "./ProjectTaskList.module.scss"
interface ProjectTaskListProps {
  projectId: string,
}

export type ProjectTaskList_TopLevelTask = NonNullable<ListProjectTasksQuery['listProjectTasks']>[number]
export type ProjectTaskList_FirstLevelTask = NonNullable<ProjectTaskList_TopLevelTask['childTasks']>[number]
export type ProjectTaskList_FirstLevelTask_ChildTasks = NonNullable<ProjectTaskList_FirstLevelTask['childTasks']>[number]

const ProjectTaskList: React.FC<ProjectTaskListProps> = ({ projectId }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { isFetching, error, data, refetch, isRefetching } = useListProjectTasksQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false  })
  const [ itemsExpanded, setItemsExpanded ] = useState<string[]>([])
  const [ currentTopLevelTask, setCurrentTopLevelTask ] = useState<ProjectTaskList_TopLevelTask | null>(null)
  const [ currentFirstLevelSubtask, setCurrentFirstLevelSubtask ] = useState<ProjectTaskList_FirstLevelTask | null>(null)
  const [ subtaskOpen, setSubtaskOpen ] = useState(false)

  const handleRefetchData = async () => {
    await refetch()

  }

  const handleSelectTask: TaskProps["onClick"] = ({ id, parentId }) => {
    if (parentId) {
      const topLevelTask = data?.listProjectTasks.find(t => t.id === parentId)
      if (topLevelTask) {
        setCurrentTopLevelTask(topLevelTask)
        const firstLevelTask = topLevelTask.childTasks.find(ct => ct.id === id)

        if (firstLevelTask ) {
          setCurrentFirstLevelSubtask(firstLevelTask)

          // only open the modal when there are child tasks to show
          if (firstLevelTask.childTasks.length > 0) {
            setSubtaskOpen(true)
          }
        }
      }
      return
    } else {
      const index = itemsExpanded.findIndex((i: string) => i === id)
      if (index >= 0) {
        const items = itemsExpanded.filter((i: string) => i !== id)
        setItemsExpanded(items)
      } else {
        setItemsExpanded(items => [ ...items, id ])
      }
    }
  }

  const handleCloseSLTask = () => {
    setSubtaskOpen(false)
    setCurrentTopLevelTask(null)
    setCurrentFirstLevelSubtask(null)
  }

  if (isFetching && !data) {
    return <LoadingSpinner name="Projects Task List"/>
  }

  if (error || !data) {
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  const projectTasks = data.listProjectTasks
  return <div className={classnames([ Styles.container ])}>
    <IonList lines="none" >
      {
        projectTasks.length > 0 ? <>
          {projectTasks.map((task) => {
            const { id, childTasks } = task
            const isExpanded = itemsExpanded.some((i: string) => i === id)
            const parentId = id

            return (
              <div key={id}>
                <Task task={task} onClick={handleSelectTask}  />
                {
                  childTasks.map((child) => (
                    <div key={child.id}
                      className={classnames({
                        [Styles.subItemDrawer]: true,
                        [Styles.subItemExpanded]: isExpanded,
                        [Styles.subItemContracted]: !isExpanded,
                      })}>
                      <Task task={child} {...{ parentId, onClick: handleSelectTask }} refetchStatus={isRefetching} refetchTasks={handleRefetchData}/>
                    </div>
                  ))}
              </div>
            )},
          )}
        </>
          : null
      }
    </IonList>
    <IonModal
      className={classnames([ Styles.modal ])}
      isOpen={subtaskOpen}
      initialBreakpoint={0.5}
      breakpoints={[ 0, 0.5, 1 ]}
      onDidDismiss={() => handleCloseSLTask()}>
      <IonToolbar>
        <IonBreadcrumbs>
          <IonBreadcrumb>
            {currentTopLevelTask?.title}
          </IonBreadcrumb>
          <IonBreadcrumb>
            {currentFirstLevelSubtask?.title}
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent>
        <div className={classnames([ Styles.modalBody ])}>
          {currentFirstLevelSubtask?.childTasks.map((child) => <div key={child.id}>
            <Task task={child} parentId={currentFirstLevelSubtask.id}  />
          </div>,
          )}
        </div>
      </IonContent>
    </IonModal>
  </div>
}

export default ProjectTaskList
