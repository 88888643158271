import React, { useCallback, useEffect, useState } from "react"
import { IonButton, IonContent, IonFooter, IonIcon, IonImg, IonPage, IonText, IonToolbar } from "@ionic/react"
import { useRouteToContractorLeadPreferences } from "../../../../routes"
import { useScreens } from "../../../../common/hooks/useScreens"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import { LocationArea, useUpdateContractorProfileMutation } from "../../../../graphql/generated"
import { useInvalidateMyContractorProfile, useMyContractorProfile } from "../ContractorBudgetRanges/datasource"
import ConfettiPage from "../../../../common/components/ConfettiPage"
import TitleWithDescriptionPage from "../TitleWithDescriptionPage"
import locationSvg from '../../../../assets/icons/location.svg'
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { locationAreaLabels } from "../../../onboarding/team/onboarding.i18n"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { alwaysArray } from "../../../../common/utils"
import { useGraphQLDataSource } from "../../../../api/graphql"
import { useHistory } from "react-router"
import Styles from "./ContractorLeadLocations.module.scss"
import ResponsiveContentWrapper from "../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"

enum LeadLocationScreens {
  Splash = "Splash",
  PickLeadLocations = "PickLeadLocations",
  Finished = "Finished",
}

const ContractorLeadLocations: React.FC = () => {
  const history = useHistory()

  // Loading this up here, so the data is ready by the time PickLeadLocations is rendered (so it can init from the data)
  const myContractorProfile = useMyContractorProfile()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const updateContractorMutation = useUpdateContractorProfileMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

  const goToContractorLeadPreferences = useRouteToContractorLeadPreferences()

  const [ Screen, activeScreen ] = useScreens<LeadLocationScreens>({
    init: LeadLocationScreens.Splash,
    resetWhenValueChanges: history.location.search,
    screens: {

      Splash: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <IonContent fullscreen>
              <TitleWithDescriptionPage
                topDivBackground={{
                  className: Styles.locationTopBackground,
                }}
                topDivContents={<IonImg src={locationSvg} className={Styles.customIonImg} />}
                title='Pick the areas where you would like to receive leads in'
                titleClassName='ion-text-center'
                description={`Locations next step placeholder.`}
              />
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToContractorLeadPreferences()}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ goToContractorLeadPreferences ]),
      },

      PickLeadLocations: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const [ locationAreas, setLocationAreas ] = useState<string[]>(
            () => alwaysArray(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.locationAreas),
          )

          const onClickNext = async () => {
            const contractorId = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.id
            if (!contractorId) {
              // This shouldn't happen, but can in theory
              throw new Error('Missing Contractor Profile ID!')
            }

            await updateContractorMutation.mutateAsync({
              input: { id: contractorId, locationAreas },
            })
            await invalidateMyContractorProfile()
            await changeToNextScreen()
          }

          return <>
            <IonContent className="ion-padding" >
              <ResponsiveContentWrapper>
                <h3>What areas do you want to add to your lead preferences</h3>
                <IonText>Select up to 3 areas you want to receive leads in</IonText>
                <GenericSelectorList
                  options={getGenericSelectorOptionsForEnum(LocationArea, locationAreaLabels)}
                  multiSelect={true}
                  selected={locationAreas}
                  onSelect={setLocationAreas}
                  showItemDetail={false}
                  colSize={'6'}
                />
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ myContractorProfile ]),
      },

      Finished: {
        render: useCallback(() => {
          return <>
            <ConfettiPage caption='Amazing!!!' message='You have selected locations'/>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={goToContractorLeadPreferences()}>Continue</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ goToContractorLeadPreferences ]),
      },

    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default ContractorLeadLocations
