import React from 'react'
import { isMobile, isPushNotificationsAvailable } from '../../../common/utils/ionic'
import MobilePushNotificationsProvider from './MobilePushNotificationsProvider'

export const PushNotificationsProvider: React.FC = ({ children }) =>
  isMobile && isPushNotificationsAvailable
    ? <MobilePushNotificationsProvider>
      {children}
    </MobilePushNotificationsProvider>
    : <>
      {children}
    </>

export default PushNotificationsProvider