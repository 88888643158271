import { IonPage, IonRouterOutlet } from '@ionic/react'
import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import NotImplementedPage from '../../../../../common/components/NotImplementedPage'
import { TeamType } from '../../../../../graphql/generated'
import OnboardingArchitectStageOne from './OnboardingArchitectStageOne'
import OnboardingArchitectStageSelector from './OnboardingArchitectStageSelector'

const baseURL = `/onboarding/team/${TeamType.Architect}/`

const OnboardingArchitect: React.FC = () => {
  const match = useRouteMatch()
  console.debug(`[OnboardingArchitect] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="onboardingArchitect">
        <Route path={`${baseURL}`} component={OnboardingArchitectStageSelector} exact />
        <Route path={`${baseURL}stage1`} component={OnboardingArchitectStageOne} />
        <Route component={NotImplementedPage} />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default OnboardingArchitect
