import React, { ComponentProps } from "react"
import { IonButton } from "@ionic/react"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { TeamType, useResetContractorProfileWorkHistoryMutation } from "../../../graphql/generated"
import { useGraphQLDataSource } from "../../../api/graphql"

type IonButtonPropsWithoutOnClick = Omit<ComponentProps<typeof IonButton>, 'onClick'>
const ButtonResetContractorProfileWorkHistory: React.FC<IonButtonPropsWithoutOnClick> = (ionButtonProps) => {
  const team = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const resetMutation = useResetContractorProfileWorkHistoryMutation(gqlDataSource)

  // Disable the button if either:
  // 1. Button props say it should be disabled ... or ...
  // 2. I'm not logged in as a Contractor team
  const disabled = ionButtonProps.disabled === true || team?.type !== TeamType.Contractor

  return <IonButton {...ionButtonProps} disabled={disabled} onClick={() => resetMutation.mutateAsync({})}>
    {ionButtonProps.children ?? "RESET MY WORK HISTORY"}
  </IonButton>
}

export default ButtonResetContractorProfileWorkHistory
