import React from 'react'
import DisplaySplashScreen from './DisplaySplashScreen'
import { SplashScreenStateContext, useSplashScreenState } from './context'
import { useFireAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'

export const SplashScreen: React.FC = ({ children }) => {
  const splashScreenState = useSplashScreenState()

  useFireAnalyticsEvent({ eventName: 'App_SplashScreen_Loaded' })

  console.debug(`[SplashScreen] Rendering: `, splashScreenState)

  return <SplashScreenStateContext.Provider value={splashScreenState}>
    {!splashScreenState.hideSplashScreen && <DisplaySplashScreen />}
    {children}
  </SplashScreenStateContext.Provider>
}
