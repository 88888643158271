import { Money } from "../../graphql/generated"
import { currencySymbols } from "./currency.i18n"

export const createValidCssClassname = (value: string): string =>
  [
    formatStringFromPascalToCamelCase,
    removeSpaces,
  ].reduce((currentValue, stringProcessingFn: (input: string) => string) => stringProcessingFn(currentValue), value)

export const formatStringFromPascalToCamelCase = (value: string): string =>
  value.charAt(0).toLowerCase() + value.slice(1)

export const removeSpaces = (value: string): string => value.split(' ').join('')

export const numberToStringWithCommas = (number: number): string =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export type MoneyToTextConfig = {
  withPennies: boolean,
}
export const moneyToText = (money: Money | null | undefined, config?: MoneyToTextConfig): string | null | undefined => {
  if (!money) return money

  const symbol = currencySymbols[money.currency]

  const pounds = Math.floor(money.amountInPence / 100)
  const poundsWithCommas = numberToStringWithCommas(pounds)

  const pennies = money.amountInPence % 100
  const penniesSuffix = config?.withPennies ? `.${pennies.toString().padStart(2, '0')}` : ''

  return `${symbol}${poundsWithCommas}${penniesSuffix}`
}
