import { useContext } from "react"
import { Realtime } from "ably/promises"
import { AblyRealtimeContext } from "./AblyProvider"

export const useAblyRealtimeContext = (): Realtime => {
  const realtime = useContext(AblyRealtimeContext)

  if (realtime === undefined) {
    throw new Error('[useAblyRealtime] Ably Realtime not yet setup! Guard more appropriately.')
  }

  return realtime
}
