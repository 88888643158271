import { Camera, CameraPermissionType } from "@capacitor/camera"

export enum PhonePermission {
  Camera = 'camera',
  Photos = 'photos',
}

enum PhonePermissionStatus {
  Granted = 'granted',
}

const isPermissionGranted = (permission: CameraPermissionType) =>
  Camera.checkPermissions()
    .then((result) => result[permission] === PhonePermissionStatus.Granted)

/**
 * Request a camera or photos gallery permission
 * @param permission The Camera permission to be requested.
 * @returns A boolean indicating whether or not the permission was just granted.
 */
const requestPermission = (permission: CameraPermissionType) =>
  Camera.requestPermissions({ permissions: [ permission ] })
    .then((result) => (result[permission] === PhonePermissionStatus.Granted))

/**
 * Verifies that the specified permission has been granted by the user to the app. If that's not the case then it prompts the user asking them to grant access.
 * @param permission The permission to check / ask access to
 * @returns True if the permission had been already granted or if it was just accepted. Otherwise it returns false.
 */
export const verifyPermission = async (permission: CameraPermissionType) =>
  isPermissionGranted(permission)
    .then(permissionAlreadyGranted => {
      if (permissionAlreadyGranted) {
        return true
      } else {
        return requestPermission(permission)
      }
    })
