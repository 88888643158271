import React from 'react'
import { CompanySummary } from "../../../../graphql/generated"
import AutoSearchCompany from "./AutoSearchCompany"
import Styles from "./CompanyDetailsFind.module.scss"

type CompanyDetailsFindProps = {
  value?: CompanySummary,
  setValue: (value?: CompanySummary) => Promise<void> | void,
  hasError?: boolean,
}

const CompanyDetailsFind: React.FC<CompanyDetailsFindProps> = ({ value, setValue, hasError }) =>
  <div className={Styles.contentContainer}>
    <div>
      <h3>Find your Company Details</h3>
    </div>
    <div className={Styles.item}>
      <AutoSearchCompany selectedCompany={value} companyNameSelected={setValue} />
      <p className={Styles.errorMessage}>{hasError && 'You must select a company.'}</p>
    </div>
  </div>

export default CompanyDetailsFind
