import React, { useEffect, useState } from 'react'
import { IonContent, IonPage, IonInput, IonButton, IonNote, IonTextarea, useIonAlert, IonIcon, IonDatetime, IonCol, IonGrid, IonRow, useIonViewDidLeave } from '@ionic/react'
import Styles from "./CreateProject.module.scss"

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import { useSearchState } from '../../../common/hooks/pages'
import { Swiper as SwiperType } from 'swiper/types'

import { Controller, useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { CreateProjectMutation, Currency, ProjectType, useCreateProjectMutation, WorkStartEstimate } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import IndicatorBar from "./IndicatorBar"
import AddContractorsSlide, { ContractorFormSchema } from './AddContractorsSlide'
import { arrowBack } from 'ionicons/icons'
import SuccessSlide from './SuccessSlide'
import GenericSelectorList from '../../../common/components/GenericSelectorList'
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import PickAddress from '../../../common/components/PickAddress'
import { zGoogleGeocodedAddressInput } from '../../../graphql/zod'
import { projectTypeLabels } from "../../onboarding/team/onboarding.i18n"
import { getGenericSelectorOptionsForEnum } from "../../../common/components/GenericSelectorList/helpers"
import { ensureTrailingSlash, getEnumLabel } from '../../../common/utils'
import { useStoredValue } from '../../../api/providers/StorageProvider'
import { DateTime } from 'luxon'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useGetInviteUrl, workStartEstimateLabels } from '../common'

const MAX_INT = 2147483647
enum BudgetCategory {
  NoIdea = "NoIdea",
  RoughIdea = "RoughIdea",
  Exact = "Exact",
}

const FormSchema = z.object({
  projectTypes: z.nativeEnum(ProjectType).array().nonempty(),
  description: z.string(),
  address: zGoogleGeocodedAddressInput,
  budgetCategory: z.nativeEnum(BudgetCategory),
  budgetAmount: z.number().nonnegative().min(1, "Required").max(MAX_INT, "Too Big"),
  workStartEstimate: z.nativeEnum(WorkStartEstimate),
  tenderReturnDate: z.string().superRefine((val, ctx) => {
    const date = DateTime.fromISO(val)
    if (!date.isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid Date",
      })
    }
    if (date.diffNow().days < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "You cannot set a date in the past",
      })
    }
  }),
  totalContractors: z.number().nonnegative().min(1).max(5),
  contractors: z.array(ContractorFormSchema).default([]),
})
const FormPartial = FormSchema.partial()
type FormPartial = z.infer<typeof FormPartial>
type FormData = z.infer<typeof FormSchema>

const INITIAL_PAGE_INDEX = 0
type Draft = {
  type: "draft",
  createdAt: string,
  maxUnlockedIndex: number,
  formData: string,
}
const EMPTY_DRAFT_STATE = {
  type: "initial",
  maxUnlockedIndex: 0,
  createdAt: undefined,
  formData: "{}",
}

type DraftState = Draft | typeof EMPTY_DRAFT_STATE

const CreateProject: React.FC = () => {
  const now = DateTime.now()
  const getInviteUrl = useGetInviteUrl()
  const [ draftState, setDraftState ] = useStoredValue<DraftState>({ key: "createProjectDraft", initialValue: EMPTY_DRAFT_STATE } )
  const [ draftInitComplete, setDraftInitComplete ] = useState(false)
  const { register, handleSubmit, trigger, formState: { errors }, watch, control, getValues, reset } = useForm<FormData>({ resolver: zodResolver(FormSchema) })

  const [ search, mergeSearch ] = useSearchState({ page: INITIAL_PAGE_INDEX })
  const setPage = (page: number) => mergeSearch({ page })

  const [ present ] = useIonAlert()

  const [ swiper, setSwiper ] = useState<SwiperType | null>(null)
  const [ maxUnlockedIndex, setMaxUnlockedIndex ] = useState<number>(0)

  const [ isSubmitting, setSubmitting ] = useState(false)
  const [ createdProject, setCreatedProject ] = useState<CreateProjectMutation["createProject"] | null>(null)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createProject = useCreateProjectMutation(gqlDataSource)
  const triggerCreatedInvite = useAnalyticsEvent("Operations_Invite_Created")

  const page: number | undefined = Number(search.page) || INITIAL_PAGE_INDEX
  const [ totalContractors ] = watch([ "totalContractors" ])

  const formData = getValues()
  const formJson = JSON.stringify(formData)

  useIonViewDidLeave(() => {
    reset({})
    setMaxUnlockedIndex(0)
    setDraftState(EMPTY_DRAFT_STATE)

    if (createdProject) {
      setCreatedProject(null)
    }

  })

  // Draft Initialization
  useEffect(() => {
    if (!draftState || draftInitComplete) return

    try {
      const rawDraftData = JSON.parse(draftState.formData)
      const draftData = FormPartial.parse(rawDraftData)

      reset(draftData)
      setMaxUnlockedIndex(draftState.maxUnlockedIndex)
      setCreatedProject(null)
    } catch (e) {
      console.error("[CreateProject] Failed to restore draft, likely corrupt or parsing failure. Clearing draft...", draftState,  e)
      reset({})
      setMaxUnlockedIndex(0)
      setDraftState(EMPTY_DRAFT_STATE)
    }
    setDraftInitComplete(true)

  }, [ draftState?.formData ])

  // Draft persistence
  useEffect(() => {
    if (!draftInitComplete) {
      console.debug("[CreateProject] ignoring persist: draft init has not completed yet")
      return
    }
    if (createdProject) {
      console.debug("[CreateProject] ignoring persist: success state is active. draft should be getting cleared!")
      return
    }
    const nextState: Draft = {
      type: "draft",
      createdAt: DateTime.now().toISO(),
      maxUnlockedIndex,
      formData: formJson,
    }
    setDraftState(nextState)
  }, [ formJson, maxUnlockedIndex ])

  const onSubmit = async (data: FormData) => {
    const { address, description, budgetCategory, budgetAmount, projectTypes, totalContractors, contractors, tenderReturnDate, workStartEstimate } = data
    try {
      setSubmitting(true)

      // transform the contractors in the form to contractor invites (remove id field)
      const contractorInvites = contractors.map(({ companyName, email, familyName, givenName, phone }) => ({
        companyName,
        email,
        familyName,
        givenName,
        phone,
      }))

      const result = await createProject.mutateAsync({
        project: {
          projectTypes,
          description,
          address,
          budgetCategory,
          budgetValue: {
            currency: Currency.Gbp,
            amountInPence: budgetAmount * 100,
          },
          totalContractors,
          contractorInvites,
          workStartEstimate,
          tenderReturnDate,
        },
      })

      // sending analytics events doesn't affect project creation, so update state/screen immediately
      setCreatedProject(result.createProject)
      reset()

      await Promise.all(contractors.map(async(contractor, ix) => {
        // invites on a project are not dependent on who's being invited, they can be joined to contractors in the frontend
        const invite = result.createProject.memberInvites[ix]

        if (!invite) {
          console.error("[CreateProject] Missing invite for corresponding contractor index", ix)
          return
        }

        try {
          await triggerCreatedInvite({
            source: "createProject",
            inviteId: invite.id,
            inviteUrl: getInviteUrl(invite.id),
            inviteEmail: contractor.email,
            inviteCompanyName: contractor.companyName,
            inviteFamilyName: contractor.familyName,
            inviteGivenName: contractor.givenName,
            invitePhone: contractor.phone,
          })
        } catch (e) {
          console.error("[CreateProject] failed to send invite analytics event ", invite.id, e)
        }
      }))

      reset({})
      setMaxUnlockedIndex(0)
      setCreatedProject(result.createProject)
      setDraftState(EMPTY_DRAFT_STATE)
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Failed to Create Project",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }
    setSubmitting(false)
  }

  const tryUnlockPage = async (fields: (keyof FormData)[], requestedPage: number) => {
    const validationSuccess = await trigger(fields)

    if (validationSuccess) {
      // unlock the requested page if required
      if (requestedPage > maxUnlockedIndex) {
        setMaxUnlockedIndex(requestedPage)
      }
      setPage(requestedPage)
    }
  }

  // sync swiper with query state
  useEffect(() => {
    if (!swiper || swiper.destroyed) return
    if (page > maxUnlockedIndex) {
      setPage(maxUnlockedIndex)
      swiper.slideTo(maxUnlockedIndex)
      return
    }
    swiper.slideTo(page)
  }, [ swiper, page, maxUnlockedIndex ])

  return <IonPage>
    <GlobalHeader/>
    <IonContent>
      <div className={Styles.container}>
        {isSubmitting && <div className={Styles.submitOverlay}></div>}
        {!draftInitComplete ? <LoadingSpinner name="CreateProject" /> : <>
          {createdProject ? <SuccessSlide createdProject={createdProject} /> : <>

            <IndicatorBar currentPageIndex={page} totalPages={swiper?.slides?.length ?? 0} maxAvailableIndex={maxUnlockedIndex} onNavigate={(page) => swiper?.slideTo(page)} />
            <div className={Styles.swiperContainer}>
              <Swiper
                className={Styles.swiper}
                onActiveIndexChange={(x) => setPage(x.activeIndex)}
                onSwiper={(swiper) => setSwiper(swiper)}
                allowSlideNext={page <= maxUnlockedIndex}
                simulateTouch={false}
              >

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>What type of project is it?</h2>
                    <div>
                      <Controller
                        control={control}
                        name="projectTypes"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (<div className={Styles.projectList}>
                          <GenericSelectorList
                            options={getGenericSelectorOptionsForEnum(ProjectType, projectTypeLabels)}
                            selected={value || []}
                            multiSelect={true}
                            onSelect={(value) => onChange(value)}
                            showItemDetail={false}
                            colSize={'6'}
                            render={({ value }) => <div key={value} id={value}>{getEnumLabel(value)}</div>}
                          />
                          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                        </div>
                        )}
                      />
                    </div>

                    <h2>Anything else to add?</h2>
                    <IonTextarea placeholder='(Optional) Describe your project...' {...register("description")}></IonTextarea>
                    {errors.description ? <IonNote color='danger'>{errors.description.message}</IonNote> : null}

                    <div className={Styles.buttonContainer}>
                      <div></div>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "projectTypes", "description" ], 1)}>Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>What&apos;s your budget?</h2>
                    <Controller
                      control={control}
                      name="budgetCategory"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<>
                        <GenericSelectorList
                          options={Object.values(BudgetCategory).map(each => ({
                            key: each,
                            label: each,
                            value: each,
                          }))}
                          selected={value}
                          onSelect={value => onChange(value)}
                          render={({ value }) => <div key={value} id={value}>{getEnumLabel(value)}</div>}
                        />
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </>
                      )}
                    />

                    <h2>Budget Value (GBP, ex. VAT)</h2>
                    <IonInput type='number' placeholder="100000" {...register("budgetAmount", { setValueAs: v => v ? Number(v) : undefined })} ></IonInput>
                    {errors.budgetAmount ? <IonNote color='danger'>{errors.budgetAmount.message}</IonNote> : null}

                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(0)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "budgetCategory", "budgetAmount" ], 2)}>Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>Where is your project?</h2>
                    <Controller
                      control={control}
                      name="address"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<>
                        <PickAddress title='' value={value} valueKey={draftState?.createdAt} setValue={onChange}/>
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </>
                      )}
                    />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(1)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "address" ], 3)}>Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>When do you want to begin construction?</h2>
                    <Controller
                      control={control}
                      name="workStartEstimate"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<div className={Styles.projectList}>
                        <GenericSelectorList
                          options={getGenericSelectorOptionsForEnum(WorkStartEstimate, workStartEstimateLabels)}
                          selected={value}
                          onSelect={(value) => onChange(value)}
                          showItemDetail={false}
                          colSize="12"
                          render={({ value }) => <div key={value} id={value}>{workStartEstimateLabels[value]}</div>}
                        />
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </div>
                      )} />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(2)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "workStartEstimate" ], 4)}>Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>When will the Tender Return?</h2>
                    <Controller
                      control={control}
                      name="tenderReturnDate"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<IonGrid>
                        <IonRow>
                          <IonCol className={`${Styles.flex} ion-align-items-center ion-justify-content-center`}>
                            <IonDatetime
                              presentation='date'
                              value={value}
                              onIonChange={(e) => onChange(e.detail.value)}
                              min={now.toISO()}
                            />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}

                          </IonCol>
                        </IonRow>

                      </IonGrid>)} />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(3)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "tenderReturnDate" ], 5)}>Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>Contractors</h2>
                    <h3>How many contractors would you like to tender for your project?</h3>
                    <p>Weaver recommends a total of at least 3 contractors tender for your project. Find out more <a href="https://help.weaver.build/en/articles/4784659-sourcing-weaver-professionals">here</a></p>
                    <Controller
                      control={control}
                      name="totalContractors"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<>
                        <GenericSelectorList
                          options={new Array(5).fill(null).map((id, index) => ({
                            key: `${index + 1}`,
                            label: `${index + 1}`,
                            value: index + 1,
                          }))}
                          selected={value}
                          onSelect={value => onChange(value)}
                          render={({ label, value }) => <div key={value} id={label}>{value}</div>}
                        />
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </>
                      )}
                    />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(4)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "totalContractors" ], 6)}>Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <h2>Contractors</h2>
                    <h3>Would you like to invite your own contractors?</h3>
                    <p>Adding your own contractors lets Weaver know how many additional contractors to find.</p>
                    <div className={Styles.contractorChoices}>
                      <IonButton color='light' onClick={() => tryUnlockPage([], 7)} >
                        <div>
                          <h3>Yes</h3>
                          <p>I would like to add my own contractors</p>
                        </div>
                      </IonButton>
                      <IonButton color='light' onClick={handleSubmit(onSubmit)}>
                        <div>
                          <h3>No</h3>
                          <p>I don&apos;t have any contractors to add</p>
                        </div>
                      </IonButton>
                    </div>

                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(5)}><IonIcon icon={arrowBack} /> Back</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <Controller
                    control={control}
                    name="contractors"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (<div className={Styles.slideContainer}>
                      <AddContractorsSlide value={value} onChange={onChange} max={totalContractors} />
                      {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      <div className={Styles.buttonContainer}>
                        <IonButton color='secondary' onClick={() => setPage(6)}><IonIcon icon={arrowBack} /> Back</IonButton>
                        <IonButton color='primary' onClick={handleSubmit(onSubmit)}>Create Project</IonButton>
                      </div>
                    </div>
                    )}
                  />
                </SwiperSlide>

              </Swiper>
            </div>
          </>}
        </>}
      </div>
    </IonContent>
  </IonPage >
}

export default CreateProject
