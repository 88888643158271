import React, { useCallback, useState } from 'react'
import  BrowseFilesButton from  '../../../../common/components/BrowseFilesButton'
import { DateTime } from 'luxon'
import { DeviceStorageFileList } from './DeviceStorageFileList'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { readFileFromDeviceStorage, saveFileToDeviceStorage } from '../../../../common/utils/files'
import { useChatRoom } from '../services/ChatRoomProvider'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import Styles from "./FilePicker.module.scss"
import useDeviceStorageFiles, { StoredFile } from '../../../../common/hooks/useDeviceStorageFiles'
import { chevronBack } from 'ionicons/icons'

const PACKAGE = 'chat.filepicker'

export const NoRecentFilesPlaceholder: React.FC = () => {
  return (
    <div className={Styles.emptyRecentFilesPlaceholder}>
      Your recent documents will appear here. Add your first document below 👇
    </div>
  )
}

export type EventHandlerType = {
  name: string,
  isChecked: boolean,
}

export type FilesToUpload = {
  [fileName: string]: boolean,
}

export const FilePicker: React.FC = () => {
  const { files } = useDeviceStorageFiles()
  const { addFileToNewMessage, addFilesToNewMessage } = useChatRoom()
  const LOCAL_PACKAGE = `${PACKAGE}.FilePicker`
  const [ multiAddState, setMultiAddState ] = useState(false)
  const [ filesToUpload, setFilesToUpload ] = useState<FilesToUpload>({})
  const goBackToPreviousScreen = () => history.back()

  const selectSingleFile = useCallback(async (storedFile: StoredFile) => {
    if (multiAddState) return
    const blob = await readFileFromDeviceStorage(storedFile.name)
    if (blob === undefined) return

    const file = new File([ blob ], storedFile.name, {
      lastModified: DateTime.now().toMillis(),
      type: blob.type,
    })
    addFileToNewMessage(file)
    goBackToPreviousScreen()

  }, [ readFileFromDeviceStorage, addFileToNewMessage, goBackToPreviousScreen, multiAddState ])

  const filterFiles = () => {
    const fileNamesToUpload: string[] = []
    const filesToFilter = Object.entries(filesToUpload)
    const filteredToShowIsChecked = filesToFilter.filter(([ , value ]) => value === true)
    filteredToShowIsChecked.forEach((key) => fileNamesToUpload.push(key[0]))
    return fileNamesToUpload
  }

  const onFileSelectedByUser = useCallback(async (userSelectedFile: File) => {
    await saveFileToDeviceStorage(userSelectedFile)
      .catch(error => {
        console.log(`Error saving file ${userSelectedFile}`)
        throw error
      })
    addFileToNewMessage(userSelectedFile)
    goBackToPreviousScreen()
  }, [ saveFileToDeviceStorage, addFileToNewMessage, goBackToPreviousScreen ])

  const selectMultipleFiles = useCallback(async () => {
    const filteredSelectedFiles = filterFiles()
    if (filteredSelectedFiles.length === 0) return
    const filesSelected: File[] = []
    const readSelectedFiles = async () => {
      const promises = filteredSelectedFiles.map(async (value) => {
        const blob = await readFileFromDeviceStorage(value)
        if (blob){
          const file = new File([ blob ], value, {
            lastModified: DateTime.now().toMillis(),
            type: blob.type,
          })
          filesSelected.push(file)
        }
      })
      await Promise.all(promises)
      return filesSelected
    }
    const files = await readSelectedFiles()
    addFilesToNewMessage(files)
    goBackToPreviousScreen()
  }, [ filesToUpload, setFilesToUpload, readFileFromDeviceStorage, addFilesToNewMessage, goBackToPreviousScreen ])

  const setSelected = (fileName: string, isChecked: boolean) =>
    setFilesToUpload({
      ...filesToUpload,
      [fileName]: isChecked,
    })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode='ios'>
          <IonButtons slot="start">
            {
              multiAddState
                ? <IonButton color="danger" onClick={() => { setMultiAddState(!multiAddState) }}>Cancel</IonButton>
                : <IonButton className={Styles.backButton} color="primary" onClick={() => history.back()}>
                  <IonIcon icon={chevronBack}/>Back
                </IonButton>
            }
          </IonButtons>
          <IonButtons slot="end" >
            {
              multiAddState
                ? <IonButton color="primary" onClick={() => selectMultipleFiles()}>Done</IonButton>
                : <IonButton color="primary" onClick={() => setMultiAddState(!multiAddState)}>Select</IonButton>
            }
          </IonButtons>
          {multiAddState
            ? (filterFiles().length === 0)
              ? <IonTitle>Select Items</IonTitle>
              : <IonTitle>{filterFiles().length} Selected</IonTitle>
            : <IonTitle>Recent Documents</IonTitle>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <h4 className={Styles.header}>Recent Documents</h4>
        {
          files === undefined
            ? <LoadingSpinner name={`${LOCAL_PACKAGE}`} />
            : files.length === 0
              ? <>
                <NoRecentFilesPlaceholder />
                <BrowseFilesButton onFileSelectedByUserCallback={onFileSelectedByUser} />
              </>
              :
              <>
                <BrowseFilesButton onFileSelectedByUserCallback={onFileSelectedByUser} />
                <DeviceStorageFileList files={files} multiAddState={multiAddState}
                  selectFile={selectSingleFile} filesToUpload={filesToUpload} setSelected={setSelected}
                />
              </>
        }
      </IonContent>
    </IonPage>
  )
}
