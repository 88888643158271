import React from 'react'
import { IonIcon, IonBadge } from '@ionic/react'
import { pastProjectLabel } from './screenWorkHistoryMenu.i18n'
import { WorkHistoryMenuItem, progressionStatusIcons } from './config'
import NestedMenu from '../../../../../../common/components/NestedMenu/NestedMenu'
import { BudgetRange, ProgressionStatus } from '../../../../../../graphql/generated'
import { GetContractorProfileWorkHistory } from '../../datasource'
import { objectEntries } from '../../../../../../common/utils/objects'
import { alwaysArray } from '../../../../../../common/utils'
import { useRouteToUnlockContractorBudgetRangeNewWorkHistory, useRouteToUnlockContractorBudgetRangeWorkHistoryPhotos, useRouteToUnlockContractorBudgetRangeWorkHistoryReferences } from '../../../../../../routes'
import { createValidCssClassname } from '../../../../../../common/utils/string'
import { progressionStatusBadgeLabel } from '../../progressionStatus.i18n'
import Styles from './ScreenWorkHistoryMenu.module.scss'
import { useAnalyticsEvent } from '../../../../../../api/providers/SegmentProvider/hooks'

type ScreenWorkHistoryMenuProps = {
  budgetRange: BudgetRange,
  workHistory?: GetContractorProfileWorkHistory,
}

type WorkHistoryMenuItemConfig = {
  status: ProgressionStatus,
  disabled?: boolean,
  onSelect?: () => Promise<void> | void,
}

const ScreenWorkHistoryMenu = ({ budgetRange, workHistory }: ScreenWorkHistoryMenuProps) => {
  const uniqueSearch = `${new Date().getTime()}`

  const goToNewWorkHistory = useRouteToUnlockContractorBudgetRangeNewWorkHistory()
  const goToWorkHistoryReferences = useRouteToUnlockContractorBudgetRangeWorkHistoryReferences()
  const goToWorkHistoryPhotos = useRouteToUnlockContractorBudgetRangeWorkHistoryPhotos()

  const hasNoWorkHistory = workHistory === undefined

  const doesWorkHistoryHaveCompletedReference = alwaysArray(workHistory?.references).some(reference => reference.status === ProgressionStatus.Completed)
  const doesWorkHistoryHaveUnverifiedReference = alwaysArray(workHistory?.references).some(reference => reference.status === ProgressionStatus.WaitingOnWeaver)

  console.log(workHistory)
  const workHistoryReferenceProgressStatus =
    doesWorkHistoryHaveCompletedReference
      ? ProgressionStatus.Completed
      : doesWorkHistoryHaveUnverifiedReference
        ? ProgressionStatus.WaitingOnWeaver
        : ProgressionStatus.WaitingOnUser

  const doesWorkHistoryHaveAnyPhotos = alwaysArray(workHistory?.photos).length > 0

  const workHistoryPhotoProgressStatus =
    doesWorkHistoryHaveAnyPhotos
      ? ProgressionStatus.Completed
      : ProgressionStatus.WaitingOnUser

  const menuItemConfig: Record<WorkHistoryMenuItem, WorkHistoryMenuItemConfig> = {
    [WorkHistoryMenuItem.AddPastProject]:
      hasNoWorkHistory
        ? {
          status: ProgressionStatus.WaitingOnUser,
          onSelect: () => goToNewWorkHistory(budgetRange, uniqueSearch)(),
        }
        : {
          status: ProgressionStatus.Completed,
          disabled: false, // Not clickable but not disabled
        },
    [WorkHistoryMenuItem.AddReference]:
      hasNoWorkHistory
        ? {
          status: ProgressionStatus.NotStarted,
          disabled: true,
        }
        : {
          status: workHistoryReferenceProgressStatus,
          onSelect: () => workHistory && goToWorkHistoryReferences(budgetRange, workHistory?.id, uniqueSearch)(),
        },
    [WorkHistoryMenuItem.AddPhotos]:
      hasNoWorkHistory
        ? {
          status: ProgressionStatus.NotStarted,
          disabled: true,
        }
        : {
          status: workHistoryPhotoProgressStatus,
          onSelect: () => workHistory && goToWorkHistoryPhotos(budgetRange, workHistory?.id, uniqueSearch)(),
        },
  }

  const options = objectEntries(menuItemConfig).map(([ key, value ]) => ({
    key,
    title: pastProjectLabel[key],
    prefix: <IonIcon icon={progressionStatusIcons[key]} />,
    suffix: (
      <IonBadge className={Styles[createValidCssClassname(progressionStatusBadgeLabel[value.status])]} slot="end">
        {progressionStatusBadgeLabel[value.status]}
      </IonBadge>
    ),
    value: key,
    itemClassname: Styles[createValidCssClassname(progressionStatusBadgeLabel[value.status])],
    isDisabled: value.disabled,
  }))

  const fireEventWorkHistoryReferencesRangeSelected = useAnalyticsEvent('WorkHistory_References_Range_Selected')

  const wrapperFunction = (menuItem: WorkHistoryMenuItem) => {
    fireEventWorkHistoryReferencesRangeSelected({
      budgetRangeSelected: budgetRange,
      rangeStatus: workHistoryReferenceProgressStatus,
    })
    const maybeFn = menuItemConfig[menuItem].onSelect
    return maybeFn && maybeFn()
  }

  return <NestedMenu onSelect={(menuItem) => wrapperFunction(menuItem)} options={options} />
}

export default ScreenWorkHistoryMenu
