import React from 'react'
import { IonButton, IonContent, IonPage, IonRouterOutlet } from '@ionic/react'
import { TMyChatRoom, useMyChatRooms } from '../../../api/services/chat/useMyChatRooms'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { getRandomIconName } from '../../../common/utils/ionic'
import { TAvailableChatRoom, useAvailableChatRooms } from '../../../api/services/chat/useAvailableChatRooms'
import { useChatRoomFunctions } from '../../../api/services/chat/useChatRoomFunctions'
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { ensureTrailingSlash } from '../../../common/utils'
import ChatRoom from './ChatRoom'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import GlobalHeader from '../../../common/components/GlobalHeader'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

const PACKAGE = 'techDemo.chat'

export const ChatterTab: React.FC<RouteComponentProps> = ({ match }) => {
  console.debug(`[${PACKAGE}.ChatterTab] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="chatter">
        <Route exact path={match.url} component={ChatterHome} />
        <Route path={`${ensureTrailingSlash(match.url)}:chatRoomId`} component={ChatRoom} />
      </IonRouterOutlet>
    </IonPage>
  )
}

const ChatterHome: React.FC = () => {
  const { createChatRoom } = useChatRoomFunctions()
  const myTeam = useMyIndividualActiveTeam()

  return (<IonPage>
    <GlobalHeader/>
    <IonContent fullscreen>
      <ResponsiveContentWrapper>
        <h1>Tech Demo: Chatter</h1>
        <div style={{ marginLeft: '1rem' }}>
          <h2>My Chat Rooms</h2>
          <MyChatRooms />
          <h2>Available Chat Rooms</h2>
          <AvailableChatRooms />
          <h2>Chat Room Tools</h2>
          {
            myTeam && (
              <IonButton onClick={() => createChatRoom({
                name: `Techdemo Chatroom ${getRandomIconName()}`,
                icon: getRandomIconName(),
                teamIds: [ myTeam.id ],
              })}>New Chat Room</IonButton>
            )
          }
        </div>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
  )
}

export const MyChatRooms: React.FC = () => {
  const LOCAL_PACKAGE = `${PACKAGE}.MyChatRooms`
  const chatRooms = useMyChatRooms()
  console.debug(`[${PACKAGE}] Render: `, { chatRooms })

  return chatRooms === undefined
    ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useMyChatRooms`} />
    : chatRooms === null
      ? <p>There is an error loading my chatrooms!</p>
      : <>{chatRooms.map(chatRoom =>
        <MyChatRoom
          key={chatRoom.id}
          chatRoom={chatRoom}
        />)}</>
}

type TMyChatRoomProps = {
  chatRoom: TMyChatRoom,
}
export const MyChatRoom: React.FC<TMyChatRoomProps> = ({ chatRoom }) => {
  const match = useRouteMatch()
  const history = useHistory()

  type TFieldProps = {
    title: string,
    value?: string | null,
  }
  const Field: React.FC<TFieldProps> = ({ title, value }) => <div><strong>{title}: </strong><span>{value ?? <em>null / undefined</em>}</span></div>
  return <div style={{ marginBottom: '2rem' }}>
    <h3>Chat Room</h3>
    <Field title="ID" value={chatRoom.id} />
    <Field title="Name" value={chatRoom.name} />
    <Field title="Icon" value={chatRoom.icon} />
    <IonButton color='success' onClick={event => {
      event.preventDefault()
      const newLocation = `${ensureTrailingSlash(match.url)}${chatRoom.id}`
      history.push(newLocation)
    }}>Open</IonButton>
  </div>
}

export const AvailableChatRooms: React.FC = () => {
  const LOCAL_PACKAGE = `${PACKAGE}.AvailableChatRooms`
  const chatRooms = useAvailableChatRooms()
  const { updateChatRoom, joinChatRoom } = useChatRoomFunctions()
  console.debug(`[${PACKAGE}] Render: `, { chatRooms })

  return chatRooms === undefined
    ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useAvailableChatRooms`} />
    : chatRooms === null
      ? <p>There is an error loading the available chatrooms!</p>
      : <>{chatRooms.map(chatRoom =>
        <AvailableChatRoom
          key={chatRoom.id}
          chatRoom={chatRoom}
          updateChatRoomButtonClickFn={() => updateChatRoom({
            ...chatRoom,
            name: `Techdemo chatroom ${getRandomIconName()}`,
            icon: getRandomIconName(),
          })}
          joinChatRoomButtonClickFn={() => { joinChatRoom(chatRoom) }}
        />)}</>
}

type TAvailableChatRoomProps = {
  chatRoom: TAvailableChatRoom,
  updateChatRoomButtonClickFn: () => void,
  joinChatRoomButtonClickFn: () => void,
}
export const AvailableChatRoom: React.FC<TAvailableChatRoomProps> = ({ chatRoom, updateChatRoomButtonClickFn, joinChatRoomButtonClickFn }) => {
  type TFieldProps = {
    title: string,
    value?: string | null,
  }
  const Field: React.FC<TFieldProps> = ({ title, value }) => <div><strong>{title}: </strong><span>{value ?? <em>null / undefined</em>}</span></div>
  return <div style={{ marginBottom: '2rem' }}>
    <h3>{chatRoom.name}</h3>
    <Field title="ID" value={chatRoom.id} />
    <Field title="Name" value={chatRoom.name} />
    <Field title="Icon" value={chatRoom.icon} />
    <IonButton color='warning' onClick={updateChatRoomButtonClickFn}>Update</IonButton>
    <IonButton color='success' onClick={joinChatRoomButtonClickFn}>Join</IonButton>
  </div>
}

export default ChatterTab
