import React, { useContext } from 'react'
import { SplashScreenStateContext } from './context'

const SplashScreenHidden: React.FC = ({ children }) => {
  const splashScreenState = useContext(SplashScreenStateContext)

  return splashScreenState.hideSplashScreen
    ? <>{children}</>
    : null
}

export default SplashScreenHidden
