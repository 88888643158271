import React from 'react'
import { IonImg } from '@ionic/react'
import WeaverHardhatWithComments from '../../../assets/images/weaver-hardhat-with-comments.png'
import Styles from "./SuccessNotice.module.scss"

export type SuccessNoticeProps = {
  caption: string,
  message: string,
}

export const SuccessNotice: React.FC<SuccessNoticeProps> = ({ caption, message }) => {

  return (
    <div className={Styles.pageContainer}>
      <div className={Styles.background}>
        <div className={Styles.promoImage}>
          <IonImg  src={WeaverHardhatWithComments}/>
        </div>
      </div>

      <div className={Styles.message}>
        <h1 className={Styles.caption}>{caption}</h1>
        <p className={Styles.notification} >
          {message}
        </p>
      </div>
    </div>
  )
}

export default SuccessNotice
