import React from 'react'
import { IonContent, IonButton } from '@ionic/react'
import './index.css'
import { useAuthContext } from '../../../api/providers/AuthProvider'

const LoginCard: React.FC = () => {
  const auth = useAuthContext()

  return (
    <IonContent>
      <div className='loginCard'>
        <IonButton size='small' color='danger' fill='outline' onClick={auth.logout}>Sign Out</IonButton>
      </div>
    </IonContent>
  )
}

export default LoginCard
