import React, { useRef } from 'react'

import { IonButton, IonCard, IonIcon } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'

import Styles from "./UploadPhotoButton.module.scss"

type UploadPhotoButtonProps = {
  onFilesSelectedByUserCallback: (selectedFiles: File[]) => void,
  multipleSelection?: boolean,
}

export const UploadPhotoButton: React.FC<UploadPhotoButtonProps> = ({ onFilesSelectedByUserCallback, multipleSelection }) => {
  const inputThatOpensNativeFilePicker = useRef<HTMLInputElement>(null)

  const openNativeFilePicker = () => {
    inputThatOpensNativeFilePicker.current?.click()
  }

  const onFileSelectedByUser = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    if (target.files && target.files.length > 0) {
      onFilesSelectedByUserCallback([ ... target.files ])
    }
  }

  return (
    <div className={Styles.uploadPhotosButtonContainer}>
      <input hidden ref={inputThatOpensNativeFilePicker} type="file" id="nativeFilePicker" onChange={onFileSelectedByUser} multiple={multipleSelection}/>
      <IonCard>
        <IonButton onClick={openNativeFilePicker} fill="clear" size="small" className={Styles.uploadPhotosButton}>
          <IonIcon icon={addCircleOutline} className={Styles.uploadPhotosButtonIcon}/>
          Upload Photo
        </IonButton>
      </IonCard>
    </div>
  )
}
