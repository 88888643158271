import { IonButton, IonFooter, IonIcon, IonPage, IonToolbar } from '@ionic/react'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import React, { useContext, useState } from 'react'
import ContactSupportScreen from './ContactSupportScreen'
import Styles from './ContactSupportScreen.module.scss'

type ContactSupportAPI = {
  showContactSupport: () => void,
  isContactSupportShowing: boolean,
}

export const ContactSupportContext = React.createContext<ContactSupportAPI | undefined>(undefined)

/**
 * Show a Contact Support screen when told to.
 *
 * Mount this component outside everything you want to hide when showing the contact support screen.
 * Call `useContactSupportContext()` to get the Contact Support API, and then call `showContactSupport()` to switch to the contact support screen.
 *
 * NOTE: This component was only built becase we have Swiper exit routing issues. See: MW-1125
 *
 * @param props The { children } to display when contact support is not showing
 * @returns The passthru component for rendering
 */
export const ContactSupportProvider: React.FC = ({ children }) => {
  const [ isContactSupportShowing, setIsContactSupportShowing ] = useState(false)

  const contactSupportAPI: ContactSupportAPI = {
    showContactSupport: () => setIsContactSupportShowing(true),
    isContactSupportShowing,
  }

  return (
    <ContactSupportContext.Provider value={contactSupportAPI}>
      {isContactSupportShowing
        ? (
          <IonPage>
            <ContactSupportScreen />
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={() => setIsContactSupportShowing(false)} ><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" href="mailto:support@weaver.build">Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </IonPage>
        )
        : children}
    </ContactSupportContext.Provider>
  )
}

export const useContactSupportContext = (): ContactSupportAPI => {
  const contactSupportAPI = useContext(ContactSupportContext)

  if (contactSupportAPI === undefined) {
    const errorMessage = '[ContactSupportContext.useContactSupportContext] ContactSupportAPI is not yet available. This should not happen!'
    console.error(errorMessage, { contactSupportAPI })
    throw new Error(errorMessage)
  }

  return contactSupportAPI
}

export default ContactSupportProvider
