import React from 'react'
import { AuthContext, TAuth_LoggedIn, TAuth_LoggedOut } from '..'
import useStatefulEffect from '../../../../common/hooks/useStatefulEffect'
import { useEnvironmentContext } from '../../EnvironmentProvider'
import useAuth0Client from './useAuth0Client'
import { buildLoggedInAuthState, buildLoggedOutAuthState } from '../helpers'
import useRegisterCustomURLHandler from './useRegisterCustomURLHandler'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import SplashScreenSetReady from '../../../../common/components/SplashScreen/SplashScreenSetReady'
import { IonButton, IonContent, IonPage, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react'
import { arrowForwardOutline } from "ionicons/icons"
import Styles from "./Auth0Provider.module.scss"

import WeaverLogo from "../../../../assets/images/weaver_logo.svg"
import WeaverHardhatWithComments from "../../../../assets/images/weaver-hardhat-with-comments.png"

export const Auth0Provider: React.FC = ({ children }) => {
  const environment = useEnvironmentContext()
  const { auth0Client, auth0ClientOptions } = useAuth0Client(environment)

  const {
    state: authState,
    setState: setAuthState,
  } = useStatefulEffect<TAuth_LoggedOut | TAuth_LoggedIn>('auth', ({ state, setState }) => {
    if (auth0Client === undefined) return
    if (auth0ClientOptions === undefined) return

    auth0Client.isAuthenticated()
      .then(isLoggedIn => {
        // TODO: This was here to stop infinite loops - test to see if this is still needed
        // Check I've changed state
        if (state?.isLoggedIn === isLoggedIn) return undefined

        return isLoggedIn
          ? buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState: setState })
            .then(setState)
          : buildLoggedOutAuthState({ auth0Client, auth0ClientOptions, setAuthState: setState })
            .then(setState)
      })
  }, [ auth0Client ])

  useRegisterCustomURLHandler({ auth0Client, auth0ClientOptions, authState, setAuthState })

  console.debug('[Auth0Provider] Current state: ', authState)

  return authState === undefined
    ? <LoadingSpinner name='authStateUndefined' />
    : authState.isLoggedIn === false
      ? ( // Extract the following into a Login Card
        <SplashScreenSetReady>
          <IonPage>
            <IonContent>
              <div className={Styles.login}>
                <IonGrid className={Styles.container}>
                  <IonRow class="ion-justify-content-center">
                    <IonCol size="8">
                      <IonImg className={Styles.weaverLogo} src={WeaverLogo} />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol >
                    </IonCol>
                    <IonCol size='auto'>
                      <IonImg className={Styles.promoImage} src={WeaverHardhatWithComments}/>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-justify-content-center">
                    <IonCol size="8" className={Styles.buttonContainer}>
                      <h1 className={Styles.caption}>Get started with Weaver</h1>
                      <IonButton expand="block" color="light" className={Styles.loginButton} onClick={authState.login}>
                        <IonIcon slot="end" icon={arrowForwardOutline} />
                        <p>Continue</p>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonContent>
          </IonPage>
        </SplashScreenSetReady>
      )
      : <AuthContext.Provider value={authState}>
        {children}
      </AuthContext.Provider>
}

export default Auth0Provider
