import { useIonRouter } from "@ionic/react"
import { useEffect, useState } from "react"
import useAbortableEffect from "./useAbortableEffect"

type TUsePageStateInternal<T> = {
  data: T,
  initialized: boolean,
}

type TUsePageStateReturns<T> = [
  T,
  (mergeState: Partial<T>) => void,
]

function isEntryValid(entry: [k: string, v: unknown]): boolean {
  const [ , value ] = entry
  return value !== undefined
}

function buildSantizedSearchParams(state: object): object {
  const validEntries = Object.entries(state).filter(x => isEntryValid(x))
  return new URLSearchParams(validEntries)
}

function buildStateFromSearch(search: string | undefined) {
  const params = new URLSearchParams(search || "")
  return Object.fromEntries(params.entries())
}
const delay = (ms: number) => new Promise( (resolve) => setTimeout(resolve, ms))

/**
 * 2-way bind a state object to IonRouter, so that modifications to state result in changes to the URL, and vice-versa
 * Supports simple immutable merging of state with minimal validation
 * Keys set to undefined are not propagated to the URL. (see buildSantizedSearchParams)
 */
export function useSearchState<T extends object>(initialData: T): TUsePageStateReturns<T> {
  const router = useIonRouter()
  const [ internalState, setInternalState ] = useState<TUsePageStateInternal<T>>({ data: initialData, initialized: false })

  const mergeNextData = (nextData: Partial<T>) => {
    const nextInternalState = {
      ...internalState,
      // If the change is coming from a component, we have to assume it's been initialized, otherwise we'll be waiting forever
      initialized: true,
      data: {
        ...internalState.data,
        ...nextData,
      },
    }

    setInternalState(nextInternalState)
  }

  // bind URL => state
  useAbortableEffect((status) => {
    async function setStateFromUrl() {
      const incomingData = buildStateFromSearch(router.routeInfo?.search)

      // build a valid next state (missing fields should be defaulted)
      const normalizedState = {
        ...initialData,
        ...incomingData,
      }

      // some ionic components(IonAccordion) don't render correctly when value is set the first time,
      // booting the state update into an async call seems to fix this.
      await delay(1)
      if (status.aborted) {
        return
      }
      setInternalState({ data: normalizedState, initialized: true })
    }

    setStateFromUrl()
  },[ router.routeInfo?.search ])

  // bind state => URL
  useEffect(() => {
    const nextSearch = buildSantizedSearchParams(internalState.data).toString()
    if (!internalState.initialized) {
      return
    }
    const nextUrl =`${router.routeInfo.pathname}?${nextSearch}`
    router.push(nextUrl)
  }, [ internalState ])

  return [ internalState.data, mergeNextData ]
}
