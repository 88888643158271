import React, { MouseEvent } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { IonButton, IonContent, IonPage, IonRouterOutlet } from '@ionic/react'
import { ForgetMeNoteTab } from './notes'
import { ChatterTab } from './chat'
import { ensureTrailingSlash } from '../../common/utils'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import ResponsiveContentWrapper from '../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

const TechDemoPage: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch()

  const switchToPage = (page: string) => (event: MouseEvent<unknown, globalThis.MouseEvent>) => {
    event.preventDefault()
    history.push(`${ensureTrailingSlash(match.url)}${page}`)
  }

  return (
    <IonPage>
      <GlobalHeader/>
      <IonContent>
        <ResponsiveContentWrapper>
          <h1>Tech Demos</h1>
          <h2>Demos</h2>
          <div>
            <IonButton onClick={switchToPage('forget-me-note')}>Forget Me Note</IonButton>
            <IonButton onClick={switchToPage('chatter')}>Chatter</IonButton>
          </div>
          <h2>Dev Tools</h2>
          <div>
            Nothing here yet.
          </div>
        </ResponsiveContentWrapper>
      </IonContent>
    </IonPage>
  )
}

const TechDemoTab: React.FC = () => {
  const match = useRouteMatch()

  console.debug(`[techDemo.TechDemoTab] Render: `, { match })

  return (
    <IonPage>
      <IonRouterOutlet key="techDemo">
        <Route exact path={match.url} component={TechDemoPage} />
        <Route path={`${ensureTrailingSlash(match.url)}forget-me-note`} component={ForgetMeNoteTab} />
        <Route path={`${ensureTrailingSlash(match.url)}chatter`} component={ChatterTab} />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default TechDemoTab
