import { alwaysArray } from "../../../common/utils"
import { useGraphQLDataSource } from "../../graphql"
import { ListAvailableChatRoomsQuery, useListAvailableChatRoomsQuery } from "../../../graphql/generated"

const PACKAGE = 'api.services.chat'

export type TAvailableChatRoom = NonNullable<NonNullable<ListAvailableChatRoomsQuery['listAvailableChatRooms']>[number]>

export const useAvailableChatRoomsQueryKey = [ 'availableChatRooms' ]

export type TUseAvailableChatRoomsReturns = TAvailableChatRoom[] | null | undefined

export const useAvailableChatRooms = (): TUseAvailableChatRoomsReturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useMyChatRooms`

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const queryResult = useListAvailableChatRoomsQuery(gqlDataSource, {}, { queryKey: [ ...useAvailableChatRoomsQueryKey, 'list' ] })

  console.debug(`[${LOCAL_PACKAGE}] React Graph`, { queryResult })

  const chatRooms = queryResult.isSuccess
    ? alwaysArray(queryResult.data.listAvailableChatRooms)
    : queryResult.isLoading
      ? undefined
      : null

  return chatRooms
}
