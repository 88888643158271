import React, { useLayoutEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ensureTrailingSlash } from '../../../../../common/utils'
import { useGetOnboardingArchitectProfileQuery } from '../../../../../graphql/generated'

const OnboardingArchitectStageSelector: React.FC = () => {
  const match = useRouteMatch()
  const history = useHistory()

  const myTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const myTeamProfileQuery = useGetOnboardingArchitectProfileQuery(gqlDataSource, { architectTeamId: myTeam?.id ?? '' }, { enabled: myTeam?.id != null })
  const myTeamProfile = myTeamProfileQuery.data?.getArchitectProfile

  console.debug('[OnboardingArchitectStageSelector] Render: ', { myTeam, myTeamProfileQuery })

  useLayoutEffect(() => {
    console.debug('[OnboardingArchitectStageSelector] What do you have? ', { myTeam, myTeamProfile })

    // Have we loaded all our date?
    if (myTeamProfileQuery.isLoading) {
      console.debug('[OnboardingArchitectStageSelector] We are still loading. Stop here for now.', { myTeam, myTeamProfile })
      return
    }

    // We haven't yet got a team yet :: get them to choose their company
    if (myTeam === undefined) {
      console.debug('[OnboardingArchitectStageSelector] Off to stage 1 with you! ', { myTeam, myTeamProfile })
      history.push(`${ensureTrailingSlash(match.url)}stage1`)
      return
    }

    // We have a team and have completed onboarding :: error, as we shouldn't be here
    if (myTeam.isOnboardingComplete) {
      throw new Error('[OnboardingArchitectStageSelector] Routing Error! Onboarding is already complete!!')
    }

    // We have a team, but no profile :: error, as we shouldn't be here
    if (myTeamProfile == null) {
      throw new Error('[OnboardingArchitectStageSelector] Team but no Profile? That should not happen!')
    }

    // STAGE 2: We have a team, but haven't paid yet
    // we don't have a STAGE 2 right now - removed as part of MW-1119

    // Everything else in onboarding is STAGE 3
    // we don't have a STAGE 3 right now - removed as part of MW-1119
  }, [ myTeam, myTeamProfileQuery.isLoading, myTeamProfile, history, match ])

  return null
}

export default OnboardingArchitectStageSelector
