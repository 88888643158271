import React, { useEffect, useState } from 'react'
import Autocomplete from "react-google-autocomplete"
import { IonGrid, IonRow, IonItem, IonInput, IonItemDivider } from '@ionic/react'
// import WeaverGoogleMap, { defaultMapConfig } from '../../../../common/components/WeaverGoogleMap'
import { environment } from '../../api/providers/EnvironmentProvider'
import Styles from "./PickAddress.module.scss"
import { GoogleGeocodedAddressInput } from '../../graphql/generated'
import { convertGoogleMapsPlaceResultToGoogleGeocodedAddressInput } from '../../common/utils/googleMaps'

const apiKey = environment.integrations.google.apiKey

export type PickAddressProps = {
  title: string,
  /** controls the value of the form, but only resets the form when `valueKey` is changed */
  value?: GoogleGeocodedAddressInput,
  /** setting this key replaces the current input state with the contents of the `value` prop  */
  valueKey?: string,
  setValue: (value?: GoogleGeocodedAddressInput) => Promise<void> | void,
  hasError?: boolean,
}

/**
 * A semi-controlled addresspicker component that uses GoogleGeocodedAddressInput, which we support natively in the api types.
 */
export const PickAddress: React.FC<PickAddressProps> = ({ title, setValue, value, valueKey }) => {
  // const [ lat, setLat ] = useState(value?.geometry.location.lat ?? defaultMapConfig.center.lat)
  // const [ lng, setLng ] = useState(value?.geometry.location.lng ?? defaultMapConfig.center.lng)
  const [ streetAddress, setStreetAddress ] = useState<string | undefined>()
  const [ town, setTown ] = useState<string | undefined>()
  const [ city, setCountry ] = useState<string | undefined>()
  const [ postcode, setPostcode ] = useState<string | undefined>()

  const [ lastValueKey ] = useState<string| undefined>()

  useEffect(() => {
    if (valueKey === lastValueKey || !value) return
    googleAddressToAddress(value)
  }, [ lastValueKey ])

  const selectPlace = (place: google.maps.places.PlaceResult) => {
    console.log('[PickAddress.selectPlace] Place', place)

    // Ignore if I dont have a geocoded location
    if (!place.geometry?.location) {
      console.error('[PickAddress.selectPlace] Selected place is missing geometry location and cannot be used!', place)
      return
    }
    googleAddressToAddress(place)

    const googleGeocodedAddressInput = convertGoogleMapsPlaceResultToGoogleGeocodedAddressInput(place)
    console.log('[PickAddress.selectPlace] Converted place to GoogleGeocodedAddressInput', { place, googleGeocodedAddressInput })

    // Tell my controller about the new selected place
    setValue(googleGeocodedAddressInput)

    // Tell the map about the coordinates to drop a pin at
    // setLat(googleGeocodedAddressInput.geometry.location.lat)
    // setLng(googleGeocodedAddressInput.geometry.location.lng)
  }

  const googleAddressToAddress = (place: google.maps.places.PlaceResult | GoogleGeocodedAddressInput) => {
    if (place.address_components) {
      const street_number = place.address_components.find(address_component => address_component.types.includes("street_number"))
      const street_name = place.address_components.find(address_component => address_component.types.includes("route"))
      const town = place.address_components.find(address_component => address_component.types.includes("postal_town"))
      const country = place.address_components.find(address_component => address_component.types.includes("country"))
      const postcode = place.address_components.find(address_component => address_component.types.includes("postal_code"))

      setStreetAddress(`${street_number?.long_name ?? ""} ${street_name?.long_name ?? ""}`.trim())
      setTown(town?.long_name)
      setCountry(country?.long_name)
      setPostcode(postcode?.long_name)
    }
  }

  return (
    <IonGrid className={Styles.outerGrid}>
      <IonItem>
        <IonGrid className={Styles.contentContainer}>
          <h3>{title}</h3>
          <h6>Quick Search by:</h6>
          <IonRow className={Styles.displayRows}>
            <IonItemDivider>
              <Autocomplete
                apiKey={apiKey}
                className={Styles.addressAutoComplete}
                placeholder='Address'
                onPlaceSelected={place => {
                  console.log('[CompanyDetailsNew.Autocomplete.onPlaceSelected] Place by Address', place)
                  return selectPlace(place)
                }}
                options={{
                  types: [ "address" ],
                  componentRestrictions: { country: "gb" },
                }}
              />
            </IonItemDivider>
          </IonRow>
          <IonRow className={Styles.displayRows}>
            Street*
            <IonItemDivider>
              <IonInput className={Styles.addressBoxReadOut} value={streetAddress} readonly/>
            </IonItemDivider>
          </IonRow>
          <IonRow className={Styles.displayRows}>
            City
            <IonItemDivider>
              <IonInput value={town} readonly/>
            </IonItemDivider>
          </IonRow>
          <IonRow className={Styles.displayRows}>
            Postcode*
            <IonItemDivider>
              <IonInput value={postcode} readonly/>
            </IonItemDivider>
          </IonRow>
          <IonRow className={Styles.displayRows}>
            Country
            <IonItemDivider>
              <IonInput value={city} readonly/>
            </IonItemDivider>
          </IonRow>
          <IonRow>
            {/* <WeaverGoogleMap apiKey={apiKey} lat={lat} lng={lng} /> */}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonGrid>
  )
}

export default PickAddress
