import React from 'react'
import {  IonContent, IonPage  } from '@ionic/react'
import { useParams } from 'react-router'
import { useGetContractorProfileQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { alwaysArray } from '../../../common/utils'
import ContractorWorkHistory from './ContractorWorkHistory'

const DisplayContractorWorkHistory: React.FC = () => {
  const { contractorProfileId, workHistoryId } = useParams<{ contractorProfileId: string, workHistoryId: string }>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: contractorProfileId })

  const workHistoryItem = alwaysArray(data?.getContractorProfile?.workHistory).find(workHistoryItem => workHistoryItem.id === workHistoryId)

  console.debug(`[DisplayContractorWorkHistory] Render: `, { contractorProfileId, workHistoryId, data, workHistoryItem })

  if (isFetching) {
    return <LoadingSpinner name="DisplayContractorWorkHistory" />
  }

  if (error || workHistoryItem == null) {
    console.error(`[DisplayContractorWorkHistory] Failed to load Contractor WorkHistory item: `, { contractorProfileId, workHistoryItem, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  return <ContractorWorkHistory {...{ contractorProfileId, workHistoryItem }} />
}

export default DisplayContractorWorkHistory
