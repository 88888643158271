import { alwaysArray, isSomething } from "../../../common/utils"
import { useGraphQLDataSource } from "../../graphql"
import { ListMyChatRoomsQuery, useListMyChatRoomsQuery } from "../../../graphql/generated"

const PACKAGE = 'api.services.chat'

export type TMyChatRoom = NonNullable<NonNullable<NonNullable<ListMyChatRoomsQuery['listMyChatRooms']>[number]>['chatRoom']>

export const useMyChatRoomsQueryKey = [ 'listMyChatRooms' ]

export type TUseMyChatRoomsReturns = TMyChatRoom[] | null | undefined

export const useMyChatRooms = (): TUseMyChatRoomsReturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useMyChatRooms`

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const queryResult = useListMyChatRoomsQuery(gqlDataSource, {}, { queryKey: [ ...useMyChatRoomsQueryKey, 'list' ] })

  console.debug(`[${LOCAL_PACKAGE}] React Graph`, { queryResult })

  const chatRooms = queryResult.isSuccess
    ? alwaysArray(queryResult.data.listMyChatRooms)
      .map(each => each.chatRoom)
      .filter(isSomething)
    : queryResult.isLoading
      ? undefined
      : null

  return chatRooms
}
