// https://lookout.dev/rules/better-types-when-extracting-the-keys-and-entries-from-an-object-or-an-enum

/**
 * Extracts typed keys instead of string.
 * For example if we have a specific set of keys (e.g. from string enum) we
 * want them to be properly typed
 * ```ts
 * enum AB {
 *   A = 'a',
 *   B = 'b',
 * }
 * type AbConfig = {
 *   // 👇 key is one of the enum values
 *   [aOrB in AB]?: boolean;
 * };
 *
 * const abConfig: AbConfig = {
 *   [AB.A]: true,
 * }
 *
 * Object.keys(abConfig); // type string[]
 * objectKeys(abConfig); // type AB[]
 * ```
 */
export function objectKeys<T extends object>(object: T): Array<keyof T> {
  return Object.keys(object) as Array<keyof T>
}

/**
 * Extracts typed [key, value] array instead of [string, value]
 * ```ts
 * enum AB {
 *   A = 'a',
 *   B = 'b',
 * }
 * type AbConfig = {
 *   // 👇 key is one of the enum values
 *   [aOrB in AB]?: boolean;
 * };
 *
 * const abConfig: AbConfig = {
 *   [AB.A]: true,
 * }
 *
 * Object.entries(abConfig); // type Array<[string, boolean]>
 * objectEntries(abConfig); // type Array<[AB, boolean]>
 * ```
 */
export function objectEntries<
  O extends object,
  K = keyof O,
  V = O extends { [key: string]: infer L } ? L : never
>(object: O): Array<[K, V]> {
  return Object.entries(object) as unknown as Array<[K, V]>
}
