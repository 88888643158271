import { IonAvatar } from "@ionic/react"
import React from "react"
import { getInitials, getSeededColor } from "../../common/utils"

import Styles from "./ProjectAvatar.module.scss"

const ProjectAvatar: React.FC<{title: string | undefined | null, slot: string}> = ({ title, slot }) => {
  const inputTitle = title || ""
  return (
    <IonAvatar slot={slot} style={{ backgroundColor: getSeededColor(inputTitle) }}>
      <div className={Styles.label}><div>{getInitials(inputTitle).slice(0, 2)}</div></div>
    </IonAvatar>
  )
}

export default ProjectAvatar
