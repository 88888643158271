import { useCallback } from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useCreateStripeSessionForLeadPaymentMutation } from '../../../graphql/generated'
import { StripeProduct } from '../../../graphql/generated'
import { useIonAlert } from '@ionic/react'
import { Browser } from '@capacitor/browser'
import { useRedirectBaseURL } from '../../../common/hooks/useRedirectBaseURL'
import { useMyIndividualActiveTeam } from '../../providers/MyIndividualProvider/MyIndividualProvider'

type MakeStripePaymentProps = {
  product: StripeProduct,
  leadId: string,
}

type UseStripePaymentHandlerReturns = (props: MakeStripePaymentProps) => Promise<void>

const useStripePaymentForLeadPaymentHandler = (): UseStripePaymentHandlerReturns => {
  const [ present ] = useIonAlert()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createStripeSessionForLeadPayment = useCreateStripeSessionForLeadPaymentMutation(gqlDataSource )

  const activeTeam = useMyIndividualActiveTeam()
  const redirectBaseURL = useRedirectBaseURL('/stripe/')

  const makeStripePayment = useCallback(async (makeStripePaymentProps: MakeStripePaymentProps) => {
    if (redirectBaseURL === undefined) {
      return
    }

    const teamId = activeTeam?.id
    if (teamId === undefined) {
      console.debug(`[useStripePaymentForLeadPaymentHandler.makeStripePayment] No active team!`, activeTeam)
      throw new Error('[useStripePaymentForLeadPaymentHandler] Need to be a member of a team to make payments!')
    }

    try {
      const data = await createStripeSessionForLeadPayment.mutateAsync({ ...makeStripePaymentProps, redirectBaseURL })
      if (data.createStripeSessionForLeadPayment.url == null) {
        // log this
        console.debug(`[useStripePaymentForLeadPaymentHandler.makeStripePayment] createStripeSession failed to return url`, data.createStripeSessionForLeadPayment)
        throw new Error('[useStripePaymentForLeadPaymentHandleraymentHandler] Unable to create stripe session!')
      }

      const stripeSessionUrl = data.createStripeSessionForLeadPayment.url

      await Browser.open({ url: stripeSessionUrl })
    }
    catch (err) {
      if (err instanceof Error) {
        present({
          header: "Unable to take Payment at this time. Please try later.",
          message: err.message,
          buttons: [
            {
              text: "Dismiss",
              role: "cancel",
            },
          ],
        })
      }
    }
  }, [ redirectBaseURL, createStripeSessionForLeadPayment, present ])

  return makeStripePayment
}

export default useStripePaymentForLeadPaymentHandler
