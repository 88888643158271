import { useEnvironmentContext } from "../../api/providers/EnvironmentProvider"
import { ensureTrailingSlash } from "../../common/utils"
import { WorkStartEstimate } from "../../graphql/generated"

export const workStartEstimateLabels: Record<WorkStartEstimate, string> = {
  [WorkStartEstimate.LessThanMonth]: "Within a Month",
  [WorkStartEstimate.OneThreeMonths]: "1-3 Months",
  [WorkStartEstimate.ThreeSixMonths]: "3-6 Months",
  [WorkStartEstimate.OverSixMonths]: "Later than 6 Months",
  [WorkStartEstimate.Unknown]: "No Construction Date in Mind",
}

/** returns a function for generating the right invite url, dependent on the environment context */
export const useGetInviteUrl = () => {
  const env = useEnvironmentContext()
  return (inviteId: string) => `https://${ensureTrailingSlash(env.app.domain)}?claimInviteId=${encodeURIComponent(inviteId)}`
}
