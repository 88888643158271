import { objectEntries } from "../../../../common/utils/objects"
import { BudgetRange, Currency, Money, StripeProduct } from "../../../../graphql/generated"

/**
 * Product talk about budgets in "kilo-pounds" (a non-SI unit) and the product processes currency in pence.
 * This function converts from marketing speak into data for storage / comparision.
 *
 * e.g. £150k > 150_000_00
 *
 * @param {number} kPounds The amount to convert in thousands of pounds (e.g. `£150k` or `£150,000` would be `150`)
 * @returns kPounds in pence
 */
const kPoundsToPence = (kPounds: number) => kPounds * 1_000_00

type BudgetRangeConfig = {
  fromInclusive: number,
  toExclusive: number,
}

export const budgetRangesConfigs: Record<BudgetRange, BudgetRangeConfig> = {
  F30T100: {
    fromInclusive: kPoundsToPence(30),
    toExclusive: kPoundsToPence(100),
  },
  F100T250: {
    fromInclusive: kPoundsToPence(100),
    toExclusive: kPoundsToPence(250),
  },
  F250T500: {
    fromInclusive: kPoundsToPence(250),
    toExclusive: kPoundsToPence(500),
  },
  F500T1000: {
    fromInclusive: kPoundsToPence(500),
    toExclusive: kPoundsToPence(1000),
  },
  F1000T3000: {
    fromInclusive: kPoundsToPence(1000),
    toExclusive: kPoundsToPence(3000),
  },
}

export const budgetRangeMonthlySubscriptionFee: Record<BudgetRange, Money> = {
  [BudgetRange.F30T100]: {
    currency: Currency.Gbp,
    amountInPence: 12000,
  },
  [BudgetRange.F100T250]: {
    currency: Currency.Gbp,
    amountInPence: 27500,
  },
  [BudgetRange.F250T500]: {
    currency: Currency.Gbp,
    amountInPence: 57500,
  },
  [BudgetRange.F500T1000]: {
    currency: Currency.Gbp,
    amountInPence: 120000,
  },
  [BudgetRange.F1000T3000]: {
    currency: Currency.Gbp,
    amountInPence: 300000,
  },
}

export const budgetRangeLeadFee: Record<BudgetRange, Money> = {
  [BudgetRange.F30T100]: {
    currency: Currency.Gbp,
    amountInPence: 60000,
  },
  [BudgetRange.F100T250]: {
    currency: Currency.Gbp,
    amountInPence: 137500,
  },
  [BudgetRange.F250T500]: {
    currency: Currency.Gbp,
    amountInPence: 287500,
  },
  [BudgetRange.F500T1000]: {
    currency: Currency.Gbp,
    amountInPence: 600000,
  },
  [BudgetRange.F1000T3000]: {
    currency: Currency.Gbp,
    amountInPence: 1500000,
  },
}

export const budgetRangeMonthlySubscriptionStripeProduct: Record<BudgetRange, StripeProduct> = {
  F30T100: StripeProduct.UnlimitedLeadSubscriptionF30T100,
  F100T250: StripeProduct.UnlimitedLeadSubscriptionF100T250,
  F250T500: StripeProduct.UnlimitedLeadSubscriptionF250T500,
  F500T1000: StripeProduct.UnlimitedLeadSubscriptionF500T1000,
  F1000T3000: StripeProduct.UnlimitedLeadSubscriptionF1000T3000,
}

export const budgetRangeLeadFeeStripeProduct: Record<BudgetRange, StripeProduct> = {
  F30T100: StripeProduct.SingleLeadPaymentF30T100,
  F100T250: StripeProduct.SingleLeadPaymentF100T250,
  F250T500: StripeProduct.SingleLeadPaymentF250T500,
  F500T1000: StripeProduct.SingleLeadPaymentF500T1000,
  F1000T3000: StripeProduct.SingleLeadPaymentF1000T3000,
}

export const budgetRangeMatchesAmountInPennies = (amountInPennies: number, config: BudgetRangeConfig) =>
  amountInPennies >= config.fromInclusive && amountInPennies < config.toExclusive

export const getBudgetRangeByAmountInPennies = (amountInPennies?: number): BudgetRange | undefined => {
  if (amountInPennies == null) return amountInPennies

  const budgetRangeEntry = objectEntries(budgetRangesConfigs)
    .find(([ , budgetRangeConfig ]) => budgetRangeMatchesAmountInPennies(amountInPennies, budgetRangeConfig))

  return budgetRangeEntry === undefined
    ? undefined
    : budgetRangeEntry[0]
}
