import React, { useEffect, useState } from 'react'
import { useGraphQLDataSource } from '../../../../../../api/graphql'
import { UploadedFileStatus, useGetUploadedFileQuery, useUpdateUploadedFileMutation } from '../../../../../../graphql/generated'
import ThumbnailPhotoErrored from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoErrored'
import ThumbnailPhotoInProgress from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoInProgress'
import ThumbnailPhotoSucceded from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoSucceded'
import ThumbnailPhotoUnstarted from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoUnstarted'

type DisplayUploadedFileAsPhotoProps = {
  uploadedFileId: string,
  shouldDisplayDeleteButton?: boolean,
  onFileArchived?: () => void,
}

export enum InternalStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
}

export const DisplayUploadedFileAsPhoto: React.FC<DisplayUploadedFileAsPhotoProps> = ({ uploadedFileId, shouldDisplayDeleteButton, onFileArchived }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const uploadedFileQuery = useGetUploadedFileQuery(gqlDataSource, { id: uploadedFileId }, { staleTime: 15 * 60 * 1000 })
  const updateUploadFileMutation = useUpdateUploadedFileMutation(gqlDataSource)

  const [ internalStatus, setInternalStatus ] = useState<InternalStatus>(InternalStatus.NotStarted)
  const [ fileUrl, setFileUrl ] = useState<string>("")

  const uploadedFile = uploadedFileQuery.data?.getUploadedFile

  const downloadPicture = async () => {
    if (!uploadedFile) return
    setInternalStatus(InternalStatus.InProgress)
    try {
      const downloadUrl = uploadedFile.signedUrlForDownload
      if (!downloadUrl.url)
        return

      setInternalStatus(InternalStatus.Completed)
      setFileUrl(downloadUrl.url)
    } catch (error){
      setInternalStatus(InternalStatus.Failed)
      console.log(error)
    }
  }

  const archiveUploadedFile = async () => {
    if (!uploadedFile) return
    await updateUploadFileMutation.mutateAsync({
      input: {
        id: uploadedFile?.id,
        status: UploadedFileStatus.Archived,
      },
    },
    )
    if (onFileArchived) onFileArchived()
  }

  useEffect(() => {
    downloadPicture()
  }, [ downloadPicture ])

  const retryDownloadFile = () => downloadPicture()

  if (uploadedFileQuery.isLoading){
    return <ThumbnailPhotoInProgress />
  }

  if (internalStatus === InternalStatus.InProgress){
    return <ThumbnailPhotoInProgress />
  }

  if (internalStatus === InternalStatus.Failed){
    return <ThumbnailPhotoErrored onRetryClicked={ () => retryDownloadFile()}/>
  }

  if (internalStatus === InternalStatus.Completed){
    return  <ThumbnailPhotoSucceded photoUrl={fileUrl} onCloseClicked={shouldDisplayDeleteButton ? () =>  archiveUploadedFile() : undefined}/>
  }

  return <ThumbnailPhotoUnstarted />
}
