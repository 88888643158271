import React from "react"
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBack, golfOutline } from "ionicons/icons"
import { useRouteToContractorBudgetRanges, useRouteToContractorLeadLocations, useRouteToProfile } from "../../../routes"
import ResponsiveContentWrapper from "../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import Styles from './ContractorLeadPreferences.module.scss'

const ContractorLeadPreferences: React.FC = () => {
  const uniqueSearch = `${new Date().getTime()}`

  const goToProfile = useRouteToProfile()
  const goToContractorBudgetRanges = useRouteToContractorBudgetRanges()
  const goToContractorLeadLocations = useRouteToContractorLeadLocations()

  return <IonPage className={Styles.setLeadPreferencesPage}>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Lead preferences</IonTitle>
        <IonButtons slot="start" className='ion-padding'>
          <IonIcon className={Styles.backIcon} icon={arrowBack} onClick={goToProfile()}></IonIcon>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className={`ion-padding`} fullscreen>
      <ResponsiveContentWrapper>
        <h3>Add your lead preferences</h3>
        <p className={Styles.subtitle}>We want to make sure that we only send you the leads that suit your business and the direction of growth you want it to take. Tweak here your preferences to get the best projects for you</p>
        <IonButton expand="block" onClick={goToContractorBudgetRanges()}><span><IonIcon slot="start" icon={golfOutline} />Set budget preferences</span></IonButton>
        <IonButton expand="block" onClick={goToContractorLeadLocations(uniqueSearch)}><span><IonIcon slot="start" icon={golfOutline} />Set location preferences</span></IonButton>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}

export default ContractorLeadPreferences
