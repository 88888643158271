import React from 'react'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route, useRouteMatch } from "react-router"
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { TeamType } from "../../graphql/generated"
import { ensureTrailingSlash } from '../../common/utils'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import DisplayContractorProfile from './DisplayContractorProfile'
import EditContractorProfile from './EditContractorProfile'
import DisplayArchitectProfile from './DisplayArchitectProfile'
import DisplayHomeownerProfile from './DisplayHomeownerProfile/DisplayHomeownerProfile'
import { EditArchitectProfile } from './EditArchitectProfile/EditArchitectProfile'
import EditHomeownerProfile from './EditHomeownerProfile/EditHomeownerProfile'
import NotImplementedPage from '../../common/components/NotImplementedPage'

type TeamProfileComponents = Record<TeamType, {
  DisplayProfileComponent: React.FC<{ id: string, canEdit: boolean }>,
  EditProfileComponent: React.FC<{ id: string }>,
}>

const teamProfileComponents: TeamProfileComponents = {
  ARCHITECT: {
    DisplayProfileComponent: DisplayArchitectProfile,
    EditProfileComponent: EditArchitectProfile,
  },
  CONTRACTOR: {
    DisplayProfileComponent: DisplayContractorProfile,
    EditProfileComponent: EditContractorProfile,
  },
  HOMEOWNER: {
    DisplayProfileComponent: DisplayHomeownerProfile,
    EditProfileComponent: EditHomeownerProfile,
  },
  WEAVER: {
    DisplayProfileComponent: NotImplementedPage,
    EditProfileComponent: NotImplementedPage,
  },
}

const DisplayMyProfile: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  console.debug(`[DisplayMyProfile] Render: `, { myTeam })

  if (!myTeam) return <LoadingSpinner name='displayMyProfile' />

  // Create the appropriate Profile, e.g. <ContractorProfile id={myTeam.id} />
  const { DisplayProfileComponent } = teamProfileComponents[myTeam.type]
  return <DisplayProfileComponent id={myTeam.id} canEdit={true} />
}

const EditMyProfile: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  console.debug(`[EditMyProfile] Render: `, { myTeam })

  if (!myTeam) return <LoadingSpinner name='editMyProfile' />

  // Create the appropriate Profile, e.g. <EditContractorProfile id={myTeam.id} />
  const { EditProfileComponent } = teamProfileComponents[myTeam.type]
  return <EditProfileComponent id={myTeam.id} />
}

const ProfileIndex: React.FC = () => {
  const match = useRouteMatch()
  console.debug(`[ProfileIndex] Render: `, { match })

  const teamTypeProfileRoutes = Object.entries(teamProfileComponents)
    .map(([ teamType, { DisplayProfileComponent } ]) => <Route key={teamType} path={`${ensureTrailingSlash(match.url)}${teamType}/:teamId`} render={renderProps => {
      const { teamId } = renderProps.match.params
      console.debug(`[ProfileIndex.teamTypeProfileRoutes] Render TeamType ${teamType} with ID: ${teamId}`, { renderProps })
      return <DisplayProfileComponent id={teamId} canEdit={false} />
    }} />)

  return (
    <IonPage>
      <IonRouterOutlet key="profile">
        <Route path={`${ensureTrailingSlash(match.url)}`} component={DisplayMyProfile} exact />
        <Route path={`${ensureTrailingSlash(match.url)}edit`} component={EditMyProfile} exact />
        {teamTypeProfileRoutes}
      </IonRouterOutlet>
    </IonPage>
  )
}

export default ProfileIndex
