import { Directory, Filesystem } from "@capacitor/filesystem"
import write_blob from "capacitor-blob-writer"
import { useEffect, useState } from "react"
import { decode } from "base64-arraybuffer"
import { isPlatform } from "@ionic/react"
import { FileOpener } from '@awesome-cordova-plugins/file-opener'
import { resolveAppDirectoryFullPath } from "../utils/files"

// Folder within the app's internal directory, for now we use the root folder
const DEFAULT_FOLDER = ''
// The app's documents directory
const APP_DOCUMENT_DIRECTORY = Directory.Documents

export type FileName = {
  name: string,
}

export type FileMetaInformation = {
  type: string,
  size: number,
  modifiedTime: number,
  creationTime?: number,
}

export type StoredFile = FileName & FileMetaInformation

type UseDeviceStorageReturns = {
  files: StoredFile[],
  saveFileToDeviceStorage: (fileBlob: File) => Promise<string>,
  resolveAppDirectoryFullPath: () => Promise<string>,
  readFileFromDeviceStorage: (fileName: string) => Promise<Blob>,
  saveFileToDownloadsFolder: (fileName: string, blob: Blob) => void,
  openFile: (fileName: string, fileContentType: string) => void,
}

/**
 * Custom hook to access the files in the app's internal folder.
 */
const useDeviceStorage = (): UseDeviceStorageReturns => {
  const [ files, setFiles ] = useState<StoredFile[]>([])

  useEffect(() => {
    const loadFilesFromDeviceInternalDirectory = async () => {
      const folderContents = await Filesystem.readdir({
        directory: APP_DOCUMENT_DIRECTORY,
        path: DEFAULT_FOLDER,
      })

      const storedFilePromises = folderContents.files.map(async name => {
        const fileMetaInformation: FileMetaInformation = await Filesystem.stat({
          directory: APP_DOCUMENT_DIRECTORY,
          path: name,
        }).then(statResult => ({
          size: statResult.size,
          type: statResult.type,
          modifiedTime: statResult.mtime,
          creationTime: statResult.ctime,
        }))

        return {
          name,
          ...fileMetaInformation,
        }
      })

      setFiles(await Promise.all(storedFilePromises))
    }

    loadFilesFromDeviceInternalDirectory()
  }, [])

  const saveFileToDownloadsFolder = (fileName: string, blob: Blob) => {
    if (!isPlatform('desktop')) {
      throw Error('This function is only supported for Desktop devices.')
    }
    // Create a HTML A object with the URL and click on it in order to trigger to download.
    const url = window.URL.createObjectURL(
      new Blob([ blob ]),
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    // Append to html link element page
    document.body.appendChild(link)
    // Start download
    link.click()
    // Remove the link
    link.parentNode?.removeChild(link)
  }

  const saveFileToDeviceStorage = async (file: File): Promise<string> => {
    // We use write_blob for writing since it's more effective than Capacitor's write function on iOS and Android
    const result = await write_blob({
      directory: APP_DOCUMENT_DIRECTORY,
      path: file.name,
      blob: file,
      on_fallback: (error: Error) => { throw error },
    })
    return result
  }

  const readFileFromDeviceStorage = async (fileName: string): Promise<Blob> => {
    const result = await Filesystem.readFile({
      directory: APP_DOCUMENT_DIRECTORY,
      path: fileName,
    })
    const blob = new Blob([ new Uint8Array(decode(result.data)) ])
    return blob
  }

  const openFile = async (fileName: string, fileContentType: string) => {
    await resolveAppDirectoryFullPath().
      then(appDirectoryFullPath => {
        const fullFilePath = appDirectoryFullPath + '/' + fileName
        FileOpener.open(fullFilePath, fileContentType)
      }, (error) => {
        console.log(error)
        throw error
      })
  }

  return {
    files,
    resolveAppDirectoryFullPath,
    saveFileToDeviceStorage,
    readFileFromDeviceStorage,
    saveFileToDownloadsFolder,
    openFile,
  }
}

export default useDeviceStorage
