import React from 'react'
import { IonBadge, IonButton, IonCard, IonCardContent, IonContent, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonText } from '@ionic/react'
import ErrorBlock from '../../common/components/ErrorBlock'
import { DateTime } from 'luxon'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import { useGraphQLDataSource } from '../../api/graphql'
import { TeamType, useListMyProjectsAndLeadsQuery } from '../../graphql/generated'
import ProjectAvatar from './ProjectAvatar'
import Styles from './ProjectsIndex.module.scss'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ReactComponent as ConstructionWorkerIcon } from "../../assets/icons/twemoji-construction-worker.svg"
import { getKnownRoutePathForPage, Page } from '../../routes'
import ResponsiveContentWrapper from '../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

const NO_LEADS_DESCRIPTION = 'New Leads will show up here.'
const NO_PROJECTS_DESCRIPTION_CONTRACTOR = 'You don\'t have any active projects yet. Accept leads to be put forward to a project shortlist.'
const NO_PROJECTS_DESCRIPTION_OWNER = 'You don\'t have any active projects yet. Your projects will appear here.'

function timeAgo(date: string): string {
  const now = DateTime.local()
  const past = DateTime.fromISO(date)
  const diff = now.diff(past, 'hours')
  return `${Math.floor(diff.hours)} hours ago`
}

const ProjectsIndex: React.FC = () => {
  const currentTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { isFetching, error, data, refetch } = useListMyProjectsAndLeadsQuery(gqlDataSource, {}, { refetchOnWindowFocus: false })

  if (isFetching) {
    return <LoadingSpinner name="ProjectsIndex"/>
  }

  if (error || !data) {
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  const projects = data.listMyProjects
  const leads = data.listMyLeads
  const newLeads = leads.filter(x => !x.lastAccessed)

  const renderLeadsSection = () => {
    if (currentTeam?.type !== TeamType.Contractor) return null

    if (leads.length < 1) return (
      <div className={Styles.noResultsContainer}>
        <IonLabel> Leads </IonLabel>
        <IonCard className={Styles.card} >
          <IonCardContent>
            <IonText>
              <p>{NO_LEADS_DESCRIPTION}</p>
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )

    return <>
      <IonItemDivider>
        <IonLabel>Leads {newLeads.length > 0 ? <IonBadge>{newLeads.length}</IonBadge> : null}</IonLabel>
      </IonItemDivider>
      {leads.map(({ id, title, lastAccessed }) => <IonItem key={id} routerLink={`/leads/${id}`}>
        <ProjectAvatar slot="start" title={title} />
        <IonLabel>
          {title} {!lastAccessed ? <IonBadge slot="end" color='success'>New</IonBadge> : null }
          {lastAccessed ? <IonLabel color='medium'>Last Viewed: {timeAgo(lastAccessed)}</IonLabel> : null }
        </IonLabel>
      </IonItem>,
      )}
    </>

  }

  const renderProjectsSection = () => {
    const isContractor = currentTeam?.type === TeamType.Contractor
    if (projects.length < 1) return (
      <div className={Styles.noResultsContainer}>
        <IonLabel> Projects </IonLabel>
        <IonCard className={Styles.card} >
          <IonCardContent>
            <IonText>
              <ConstructionWorkerIcon className={Styles.projectsIcon} />
              <p>{isContractor ? NO_PROJECTS_DESCRIPTION_CONTRACTOR : NO_PROJECTS_DESCRIPTION_OWNER}</p>
              {!isContractor ? <IonButton routerLink={getKnownRoutePathForPage(Page.CreateProject)}>Create Project</IonButton> : null}
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )
    return <>
      <IonItemDivider>
        <IonLabel>Projects</IonLabel>
      </IonItemDivider>
      {projects.map(({ id, title, lastAccessed }) => <IonItem key={id} routerLink={`/projects/${id}`}>
        <ProjectAvatar slot="start" title={title} />
        <IonLabel>
          {title}
          {lastAccessed ? <IonLabel color='medium'>Last Viewed: {timeAgo(lastAccessed)}</IonLabel> : null }
        </IonLabel>
      </IonItem>,
      )}
    </>
  }

  return <IonPage>
    <GlobalHeader pageTitle='Projects'/>
    <IonContent fullscreen>
      <ResponsiveContentWrapper>
        <IonList>
          {renderLeadsSection()}
          {renderProjectsSection()}
        </IonList>
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}

export default ProjectsIndex
