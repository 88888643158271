import { IonAvatar } from "@ionic/react"
import React from "react"
import { getSeededColor, getInitials } from "../../../common/utils"
import Styles from '../TeamMembers/TeamMemberAvatar.module.scss'

const TeamMemberAvatar: React.FC<{title: string | undefined | null}> = ({ title }) => {
  const inputTitle = title ?? ""
  return (
    <IonAvatar style={{ backgroundColor: getSeededColor(inputTitle) }}>
      <div className={Styles.label}><div>{getInitials(inputTitle).slice(0, 2)}</div></div>
    </IonAvatar>
  )
}

export default TeamMemberAvatar
