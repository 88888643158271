import { IonPage, IonContent, IonIcon, IonItem, IonText } from '@ionic/react'
import { informationCircleOutline, createOutline } from 'ionicons/icons'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import ErrorBlock from '../../../common/components/ErrorBlock'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { ensureTrailingSlash } from '../../../common/utils'
import { useGetHomeownerProfileQuery } from '../../../graphql/generated'
import ProfileHeader from '../ProfileHeader'
import TeamMembers from '../TeamMembers'
import Styles from '../Profile.module.scss'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

type HomeownerProfileProps = {
  id: string,
  canEdit: boolean,
}

const HomeownerProfile: React.FC<HomeownerProfileProps> = ({ id, canEdit }) => {
  const myTeam = useMyIndividualActiveTeam()
  const isMyTeam = myTeam?.id === id
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetHomeownerProfileQuery(gqlDataSource, { homeownerTeamId: id })
  const history = useHistory()
  const match = useRouteMatch()
  console.debug(`[HomeownerProfile] Render: `, { id, data })

  if (isFetching) {
    return <LoadingSpinner name="HomeownerProfile" />
  }

  if (error) {
    console.error(`[HomeownerProfile] Failed to load Homeowner Profile: `, { id, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <ErrorBlock onRefresh={refetch} />
      </IonContent>
    </IonPage>
  }

  if (data?.getHomeownerProfile == null) {
    console.warn(`[HomeownerProfile] Homeowner Profile is missing: `, { id, data, error })
    return <IonPage>
      <IonContent fullscreen>
        <CentralisedBlock>
          <IonIcon icon={informationCircleOutline} size="large" color="primary" />
          <p>Unable to find the Homeowner Profile.</p>
        </CentralisedBlock>
      </IonContent>
    </IonPage>
  }

  const profile = data.getHomeownerProfile

  return <IonPage>
    <ProfileHeader isMyTeam={isMyTeam} />
    <IonContent className={`${Styles.ionContent} ion-padding`} fullscreen>
      <ResponsiveContentWrapper>
        {isMyTeam && canEdit
          ? (
            <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={event => {
              event.preventDefault()
              const newLocation = `${ensureTrailingSlash(match.url)}edit`
              history.push(newLocation)
            }}>
              <h3>{profile.teamName}</h3>
              <IonIcon className={Styles.editIcon} color='primary' src={createOutline} ></IonIcon>
            </IonItem>
          )
          : (
            <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
              <h3>{profile.teamName}</h3>
            </IonItem>
          )}

        <IonText className={Styles.lightGrey}>
          <p>Homeowner</p>
        </IonText>
        <IonText className={Styles.lightGrey}>
          <p>{profile.propertyAddress?.formatted_address ?? <em>No Address</em>}</p>
        </IonText>
        <TeamMembers teamId={id} isMyTeam={isMyTeam} />
      </ResponsiveContentWrapper>
    </IonContent>
  </IonPage>
}

export default HomeownerProfile
