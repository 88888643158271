import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useIonAlert } from '@ionic/react'
import { useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useSwitchToOnboardingTeam } from '../../..'
import { useOnboardHomeownerTeam } from '../../hooks'
import { useMyIndividual, useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { zGoogleGeocodedAddressInput } from '../../../../../graphql/zod'
import RoutedSwiper from '../../../../../common/components/RoutedSwiper'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import ReactHookFormSingleInputPage from '../../pages/ReactHookFormSingleInputPage'
import TitleWithBulletsPage from "../../pages/TitleWithBulletsPage"
import { TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { homeownerSteps } from '../../pages/OnboardingSignupSteps/config'
import PickAddress from '../../../../../common/components/PickAddress'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import ConfettiPage from '../../../../../common/components/ConfettiPage'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useContactSupportContext } from '../../../../../api/providers/ContactSupportProvider/ContactSupportProvider'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'

const zFormSchema = z.object({
  teamName: z.string().min(1, 'Required'),
  propertyAddress: zGoogleGeocodedAddressInput,
})

type FormSchema = z.infer<typeof zFormSchema>

const OnboardingHomeownerStageOne: React.FC = () => {
  const match = useRouteMatch()
  const myIndividual = useMyIndividual()

  // A flag to prevent pages being swiped while we are doing network stuff
  const [ isSubmitting, setSubmitting ] = useState<boolean>(false)
  const { register, trigger, setValue, getValues, resetField, formState: { errors } } = useForm<FormSchema>({
    defaultValues: {
      teamName: `The ${myIndividual.familyName}'s`,
    },
    resolver: zodResolver(zFormSchema),
  })

  // Analytics
  const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('WorkHistory_References_Added_Past_Project_Address')
  const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Complete')

  // Error Handler
  const [ present ] = useIonAlert()

  // Contact Support overlay
  const contactSupportAPI = useContactSupportContext()

  // Finished Handler -- the ultimate goal of this form!
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
  const onboardHomeownerTeamFn = useOnboardHomeownerTeam()
  const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()
  const history = useHistory()

  const onSubmit = async (data: FormSchema) => {
    let success = false
    try {
      setSubmitting(true)

      const onboardResult = await onboardHomeownerTeamFn({
        teamName: data.teamName,
        propertyAddress: data.propertyAddress,
      })

      await setOnboardingCompleteMutation.mutateAsync({
        teamId: onboardResult.team.id,
        isOnboardingComplete: true,
      })

      success = true
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Failed to Onboard Homeowner Team",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }
    setSubmitting(false)
    return success
  }

  const handleSlideSubmit = async () => {

    const value = getValues('propertyAddress')
    await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
      city: (value?.address_components?.find(address_component => address_component.types.includes("postal_town")))?.long_name,
      firstPartOfPostCode: (value?.address_components?.find(address_component => address_component.types.includes("postal_code")))?.long_name.slice(0,4),
    })

    const ready = await trigger()
    if (!ready) return { abort: true }
    const submitSuccess = await onSubmit(getValues())

    if (!submitSuccess) return { abort: true }
  }

  // Go back to the TeamSelector
  const switchToOnboardingTeam = useSwitchToOnboardingTeam()

  console.debug('[OnboardingHomeownerStageOne] Render ', { match, isSubmitting, formValues: getValues() })
  return (
    <form>
      <RoutedSwiper
        pages={[
          {
            name: 'introduction',
            render: () => <SuccessNotice
              caption="Brilliant!"
              message="You're almost ready to find builders for your project."
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting,
          },
          {
            name: 'signupStepsFirst',
            render: (goToNextPage) => <OnboardingSignupSteps caption="Sign up to Weaver" message="Completing these steps will allow you to find builders" steps={homeownerSteps} currentPageIndex={1} goToNextPage={goToNextPage} />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting,
          },
          {
            name: 'companyDetailsSignpost',
            render: () => <TitleWithBulletsPage
              title='Next enter your details'
              imgProps={{
                src: builderPng,
              }}
              bullets={[
                { description: 'Pick a name that builders on Weaver will see', icon: shieldCheckmark },
                { description: 'Choose your address', icon: shieldCheckmark },
              ]}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting,
          },
          {
            name: 'teamName',
            render: () => <ReactHookFormSingleInputPage
              title='Give your team a name'
              inputLabel="Team name:"
              inputSubtext='This is the name clients will see on Weaver (You can change this later)'
              inputFieldProps={register('teamName')}
              inputFieldError={errors.teamName}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting && await trigger([ 'teamName' ]),
          },
          {
            name: 'addressSelect',
            render: () => <PickAddress
              title="Enter your Property Address"
              value={getValues("propertyAddress")}
              setValue={propertyAddress => {
                if (propertyAddress) {
                  setValue("propertyAddress", propertyAddress)
                } else {
                  resetField('propertyAddress')
                }
              }}
              hasError={errors.propertyAddress !== undefined}
            />,
            canGoBack: async () => !isSubmitting,
            canGoForward: async () => !isSubmitting && await trigger([ 'propertyAddress' ]),
            beforeGoForwardOnClick: handleSlideSubmit,
          },
          {
            name: 'stageOneComplete',
            render: () => <ConfettiPage
              caption="Time to find some builders!"
              message="Now you're ready to add project details"
              onClick={async () => {
                await triggerEventOnboardingCompanyComplete({
                  teamType: TeamType.Homeowner,
                })
                await useMyIndividualInvalidateCacheFn()
                history.push('/')
              }}
            />,
            hideButtons: true,
          },
        ]}
        onFirstPageGoBack={switchToOnboardingTeam}
        onLastPageGoForward={() => {
          console.error('[OnboardingHomeownerStageOne.onLastPageGoForward] This should never trigger!')
        }}
      />
    </form>
  )
}

export default OnboardingHomeownerStageOne
