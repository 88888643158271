import { useQueryClient } from "react-query"
import { useGraphQLDataSource } from "../../../api/graphql"
import {
  ArchiveProjectDocumentMutation,
  CreateProjectDocument,
  CreateProjectDocumentMutation,
  ProjectDocument,
  IncrementProjectDocumentCount,
  IncrementProjectDocumentCountMutation,
  useArchiveProjectDocumentMutation,
  useCreateProjectDocumentMutation,
  useIncrementProjectDocumentCountMutation,
} from "../../../graphql/generated"

export type CreateProjectDocumentReturns = Pick<ProjectDocument, 'id' | 'fileContentType' | 'fileName' | 'fileSizeInBytes' | 'signedUrlForUpload' | 'signedUrlForDownload'>

export type MutationCreateProjectDocumentFn = (projectDocument: CreateProjectDocument) => Promise<CreateProjectDocumentReturns | undefined>
export type MutationArchiveProjectDocument = (projectDocumentId: string) => Promise<ArchiveProjectDocumentMutation>
export type MutationIncrementProjectDocumentCount = (documentCount: IncrementProjectDocumentCount) => Promise<IncrementProjectDocumentCountMutation>

export type UseProjectDocumentsReturns = {
  createProjectDocument: MutationCreateProjectDocumentFn,
  archiveProjectDocument: MutationArchiveProjectDocument,
  incrementProjectDocumentCount: MutationIncrementProjectDocumentCount,
}

type UseProjectDocumentsProps = {
  projectId: string,
}

export const useProjectDocuments = (props: UseProjectDocumentsProps) : UseProjectDocumentsReturns => {
  const { projectId } = props
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [ 'showProject', { id: projectId } ],
    })
  }

  const mutationCreateProjectDocument = useCreateProjectDocumentMutation(gqlDataSource, { onSuccess })
  const mutationArchiveProjectDocument = useArchiveProjectDocumentMutation(gqlDataSource, { onSuccess })
  const mutationIncrementProjectDocumentCount = useIncrementProjectDocumentCountMutation(gqlDataSource, { onSuccess })

  const createProjectDocument = async (createProjectDocument: CreateProjectDocument) => {
    const result: CreateProjectDocumentMutation = await mutationCreateProjectDocument.mutateAsync({ projectDocument: createProjectDocument })
    if (!result.createProjectDocument) throw new Error(`Error ocurred while creating the document ${createProjectDocument.fileName} for project ${createProjectDocument.projectId}.` )
    return {
      id: result.createProjectDocument.id,
      fileContentType: result.createProjectDocument.fileContentType,
      fileName: result.createProjectDocument.fileName,
      fileSizeInBytes: result.createProjectDocument.fileSizeInBytes,
      signedUrlForUpload: result.createProjectDocument.signedUrlForUpload,
      signedUrlForDownload: result.createProjectDocument.signedUrlForDownload,
    }
  }

  const archiveProjectDocument = (projectDocumentId: string) => mutationArchiveProjectDocument.mutateAsync({ id: projectDocumentId })

  const incrementProjectDocumentCount = (incrementProjectDocumentCount: IncrementProjectDocumentCount) => mutationIncrementProjectDocumentCount.mutateAsync({  documentCount: incrementProjectDocumentCount })

  return {
    createProjectDocument,
    archiveProjectDocument,
    incrementProjectDocumentCount,
  }

}
