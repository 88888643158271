// This file is no longer generated by backend-import
// Instead it uses environment variables from .env
//
// Generate the .env file with `frontend/scripts/build-dot-env.sh`

import * as z from 'zod'

export const zStaticEnvironmentConfig = z.object({
  version: z.literal('2'),
  account: z.object({
    name: z.string(),
    type: z.union([ z.literal('dev'), z.literal('published') ]),
  }),
  app: z.object({
    // The location of the hosting for the SPA (this will likely change / be removed as we gain multiple frontends)
    domain: z.string(),
  }),
  auth0: z.object({
    domain: z.string(),
    client_id: z.string(),
    audience: z.string().optional(),
  }),
  apiGateway: z.object({
    bootstrap: z.object({
      url: z.string(),
    }),
    devSupport: z.object({
      url: z.string(),
    }),
    ably: z.object({
      url: z.string(),
    }),
  }),
  graphQL: z.object({
    notes: z.object({
      url: z.string(),
      region: z.string(),
      key: z.string().optional(),
    }),
    core: z.object({
      url: z.string(),
      region: z.string(),
      key: z.string().optional(),
    }),
  }),
  integrations: z.object({
    google: z.object({
      apiKey: z.string(),
    }),
    segment: z.object({
      frontend: z.object({
        writeKey: z.string(),
      }),
    }),
    launchDarkly: z.object({
      sdkKey: z.string(),
      mobileKey: z.string(),
      clientSideId: z.string(),
    }),
    sentry: z.object({
      app: z.object({
        DSN: z.string(),
      }),
      frontend: z.object({
        DSN: z.string(),
      }),
    }),
  }),
})

export type StaticEnvironmentConfig = z.infer<typeof zStaticEnvironmentConfig>

const getAccountTypeEnvVar = (varName: string): 'dev' | 'published' => {
  const value = getStringEnvVar(varName)
  if (value === 'dev' || value === 'published') return value
  throw new Error(`Invalid string value from: ${varName} (was '${value}' and should be one of: 'dev' | 'published')`)
}

const getRequiredStringEnvVar = (varName: string): string => {
  const value = getStringEnvVar(varName)
  if (!value) throw new Error(`Missing string value from: ${varName}`)
  return value
}

const getStringEnvVar = (varName: string): string | undefined => {
  const value = process.env[varName]
  // If the string has length, return the string else return undefined
  return value == undefined
    ? undefined
    : value.length === 0
      ? undefined
      : value
}

export const staticEnvironmentConfig: StaticEnvironmentConfig = {
  version: "2",
  account: {
    name: getRequiredStringEnvVar('REACT_APP_WEAVER_ACCOUNT_NAME'),
    type: getAccountTypeEnvVar('REACT_APP_WEAVER_ACCOUNT_TYPE'),
  },
  app: {
    domain: getRequiredStringEnvVar('REACT_APP_WEAVER_SPA_DOMAIN'),
  },
  auth0: {
    domain: getRequiredStringEnvVar('REACT_APP_WEAVER_AUTH0_DOMAIN'),
    client_id: getRequiredStringEnvVar('REACT_APP_WEAVER_AUTH0_APP_CLIENTID'),
    audience: getRequiredStringEnvVar('REACT_APP_WEAVER_AUTH0_APP_AUDIENCE'),
  },
  apiGateway: {
    bootstrap: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_APIGATEWAY_BOOTSTRAP_URL'),
    },
    devSupport: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_APIGATEWAY_DEVSUPPORT_URL'),
    },
    ably: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_APIGATEWAY_ABLY_URL'),
    },
  },
  graphQL: {
    notes: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_NOTES_URL'),
      region: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_NOTES_REGION'),
      key: getStringEnvVar('REACT_APP_WEAVER_GRAPHQL_NOTES_KEY'),
    },
    core: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_CORE_URL'),
      region: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_CORE_REGION'),
      key: getStringEnvVar('REACT_APP_WEAVER_GRAPHQL_CORE_KEY'),
    },
  },
  integrations: {
    google: {
      apiKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_GOOGLE_APIKEY'),
    },
    segment: {
      frontend: {
        writeKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_SEGMENT_FRONTEND_WRITEKEY'),
      },
    },
    launchDarkly: {
      sdkKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_LAUNCHDARKLY_SDKKEY'),
      mobileKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_LAUNCHDARKLY_MOBILEKEY'),
      clientSideId: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_LAUNCHDARKLY_CLIENTSIDEID'),
    },
    sentry: {
      app: {
        DSN: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_SENTRY_APP_DSN'),
      },
      frontend: {
        DSN: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_SENTRY_FRONTEND_DSN'),
      },
    },
  },
}
