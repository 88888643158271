import React, { useCallback } from "react"
import { IonButton, IonContent, IonFooter, IonIcon, IonNote, IonPage, IonToolbar } from "@ionic/react"
import { useRouteToUnlockContractorBudgetRange } from "../../../../../../routes"
import { useHistory, useParams } from "react-router"
import { budgetRangeLabels } from "../../budgetRanges.i18n"
import { useScreens } from "../../../../../../common/hooks/useScreens"
import { Controller, useForm } from "react-hook-form"
import { WorkHistory, zWorkHistory } from "../workHistoryTypes"
import { BudgetRange, Currency, useAugmentGoogleGeocodedAddressMutation, useCreateContractorProfileWorkHistoryMutation } from "../../../../../../graphql/generated"
import { zodResolver } from "@hookform/resolvers/zod"
import PickAddress from "../../../../../../common/components/PickAddress"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { useInvalidateMyContractorProfile } from "../../datasource"
import ProjectTypeSelector from "./ProjectTypeSelector/ProjectTypeSelector"
import SumOfWork from "./SumOfWorkPage/SumOfWork"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import TitleWithDescriptionPage from "../../../TitleWithDescriptionPage"
import RangeBadge from "../../../ContractorBudgetReferences/RangeBadge"
import { budgetRangesConfigs } from "../../budgetRanges"
import { z } from "zod"
import Styles from './NewWorkHistory.module.scss'
import ConfettiPage from "../../../../../../common/components/ConfettiPage"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"

enum NewWorkHistoryScreens {
  Splash = "Splash",
  ProjectAddress = "ProjectAddress",
  ProjectSumOfWork = "ProjectSumOfWork",
  ProjectType = "ProjectType",
  Finished = "Finished",
}

const NewWorkHistory: React.FC = () => {
  const history = useHistory()
  const { budgetRange } = useParams<{ budgetRange: BudgetRange }>()

  const minBudgetRange = Number(budgetRangesConfigs[ budgetRange ].fromInclusive / 100)
  const maxBudgetRange = Number(budgetRangesConfigs[ budgetRange ].toExclusive / 100)

  const goToUnlockContractorBudgetRange = useRouteToUnlockContractorBudgetRange()

  const zRefinedWorkHistory = zWorkHistory.superRefine((validated, ctx) => {
    if (validated.constructionValue.amountInPence < budgetRangesConfigs[ budgetRange ].fromInclusive) {
      ctx.addIssue({
        path: [ "constructionValue.amountInPence" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be greater than the minimum of £${minBudgetRange}`,
      })
    }
    if (validated.constructionValue.amountInPence >= budgetRangesConfigs[ budgetRange ].toExclusive) {
      ctx.addIssue({
        path: [ "constructionValue.amountInPence" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be less than the maximum of £${maxBudgetRange}`,
      })
    }
  })

  const form = useForm<WorkHistory>({
    defaultValues: {
      projectAddress: undefined,
      constructionValue: {
        currency: Currency.Gbp,
      },
      projectTypes: [],
      references: [],
      photos: [],
    },
    mode: "onChange",
    resolver: zodResolver(zRefinedWorkHistory),
  })

  const [ Screen, activeScreen ] = useScreens<NewWorkHistoryScreens>({
    init: () => {
      form.reset()
      return NewWorkHistoryScreens.Splash
    },
    resetWhenValueChanges: history.location.search,
    screens: {

      Splash: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <IonContent fullscreen>
              <ResponsiveContentWrapper>
                <TitleWithDescriptionPage
                  topDivContents={<RangeBadge budgetRange={budgetRange} />}
                  title={`Add a past project in ${budgetRangeLabels[ budgetRange ]} budget range to unlock leads.`}
                  description={`It doesn't have to be a Weaver project, but does need to be in the selected budget range.`}
                />
              </ResponsiveContentWrapper>
            </IonContent>
            <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRange(budgetRange)}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ budgetRange ]),
      },

      ProjectAddress: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          const triggerEvent = useAnalyticsEvent('WorkHistory_References_Added_Past_Project_Address')

          const onClickNextIfFieldIsValid = async () => {
            const isValid = await form.trigger('projectAddress')

            const value = form.getValues('projectAddress')
            if (isValid) {
              await triggerEvent({
                city: (value?.address_components?.find(address_component => address_component.types.includes("postal_town")))?.long_name,
                firstPartOfPostCode: (value?.address_components?.find(address_component => address_component.types.includes("postal_town")))?.short_name,
              })
              onClickNext()
            }
          }

          return <Controller
            control={form.control}
            name="projectAddress"
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <>
                <IonContent className="ion-padding" fullscreen>
                  <ResponsiveContentWrapper>
                    <h3><b>Enter address of a past project</b></h3>
                    <PickAddress title='' value={value} setValue={onChange} />
                    {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                  </ResponsiveContentWrapper>
                </IonContent>
                <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
                  <IonToolbar className={Styles.ionToolbar}>
                    <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                    <IonButton slot="end" onClick={onClickNextIfFieldIsValid} disabled={!!error}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
                  </IonToolbar>
                </IonFooter>
              </>
            )}
          />
        }, [ form ]),
      },

      ProjectSumOfWork: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          const onClickNextIfFieldIsValid = async () => {
            const isValid = await form.trigger('constructionValue.amountInPence')
            if (isValid) onClickNext()
          }

          return <Controller
            control={form.control}
            name="constructionValue.amountInPence"
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (<>
              {/* Button to set the sum field in the work history */}
              <IonContent className="ion-padding">
                <SumOfWork
                  indicatorBarProps={{
                    currentPageIndex: 1,
                    totalPages: 3,
                    maxAvailableIndex: 3,
                    onNavigate: () => null,
                  }}
                  value={value}
                  setValue={onChange}
                  error={error}
                  budgetRange={budgetRange}
                />
              </IonContent>
              <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
                <IonToolbar className={Styles.ionToolbar}>
                  <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                  <IonButton slot="end" onClick={onClickNextIfFieldIsValid} disabled={!!error}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
                </IonToolbar>
              </IonFooter>
            </>
            )}
          />

        }, [ form ]),
      },

      ProjectType: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const createMutation = useCreateContractorProfileWorkHistoryMutation(gqlDataSource)
          const augmentGoogleGeocodedAddress = useAugmentGoogleGeocodedAddressMutation(gqlDataSource)
          const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

          const triggerEventReferencesAddedPastProjectTypes = useAnalyticsEvent("WorkHistory_References_Added_Past_Project_Types")

          const onSubmitThenClickNextIfFieldIsValid = async () => {
            const isProjectTypeValid = await form.trigger('projectTypes')
            if (isProjectTypeValid) {
              if (!form.trigger()) {
                console.error(`[NewWorkHistory] Form not valid: `, form)
                return
              }

              await augmentGoogleGeocodedAddress.mutateAsync({
                location: form.getValues('projectAddress').geometry.location,
                googleGeocodedAddress: form.getValues('projectAddress'),
              })

              await createMutation.mutateAsync({
                input: {
                  projectLocation: form.getValues('projectAddress').geometry.location,
                  constructionValue: form.getValues('constructionValue'),
                  projectTypes: form.getValues('projectTypes'),
                },
              })

              await triggerEventReferencesAddedPastProjectTypes({
                types: form.getValues('projectTypes') ,
              })

              await invalidateMyContractorProfile()

              onClickNext()
            }
          }

          return <Controller
            control={form.control}
            name="projectTypes"
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (<>
              <IonContent className="ion-padding" fullscreen>
                <ProjectTypeSelector
                  indicatorBarProps={{
                    currentPageIndex: 2,
                    totalPages: 3,
                    maxAvailableIndex: 3,
                    onNavigate: () => null,
                  }} value={value} setValue={onChange} hasError={!!error}
                />
              </IonContent>
              <IonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
                <IonToolbar className={Styles.ionToolbar}>
                  <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                  <IonButton slot="end" onClick={onSubmitThenClickNextIfFieldIsValid} disabled={!!error}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
                </IonToolbar>
              </IonFooter>
            </>
            )}
          />
        }, [ form ]),
      },

      Finished: {
        render: useCallback(() => {
          return <>
            <ConfettiPage caption='Amazing!!!' message='You have added a reference project'/>
            <IonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={goToUnlockContractorBudgetRange(budgetRange)}>Continue</IonButton>
              </IonToolbar>
            </IonFooter>
          </>
        }, [ budgetRange ]),
      },

    },
  })

  return (
    <IonPage key={activeScreen}>
      {Screen}
    </IonPage>
  )
}

export default NewWorkHistory
