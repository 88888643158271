import { decryptSymmetrical, encryptSymmetrical } from "../../../common/utils/crypto"
import { TAuth_LoggedIn } from "../../providers/AuthProvider"

const delimiter = '§§§'

export const encryptAuth = (auth: TAuth_LoggedIn, passcode: string) => {
  if (!auth.accessToken) throw new Error('Missing Access token')
  if (!auth.idToken?.__raw) throw new Error('Missing ID token')

  const encryptedAccessToken = encryptSymmetrical(auth.accessToken, passcode)
  const encryptedIdToken = encryptSymmetrical(auth.idToken?.__raw, passcode)

  return `${encryptedAccessToken}${delimiter}${encryptedIdToken}`
}

export const decryptEncryptedJWT = (encryptedJWT: string, passcode: string) => {
  const parts = encryptedJWT.split(delimiter)
  if (parts.length !== 2) throw new Error(`I expected 2 parts to the descrypted EncryptedJWT, but got ${parts.length}`)
  const [ encryptedAccessToken, encryptedIdToken ] = parts

  const accessToken = decryptSymmetrical(encryptedAccessToken, passcode)
  const idTokenRaw = decryptSymmetrical(encryptedIdToken, passcode)

  return { accessToken, idTokenRaw }
}
