import React from "react"
import { staticEnvironmentConfig, StaticEnvironmentConfig } from "../../../weaver-backend.generated"
import EnvironmentProvider from "./EnvironmentProvider"

export * from "./context"

export type Environment = StaticEnvironmentConfig
export const environment: Environment = staticEnvironmentConfig
export const EnvironmentContext = React.createContext<Environment>(environment)

export default EnvironmentProvider
