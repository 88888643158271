import { useLDClient } from "launchdarkly-react-client-sdk"
import { useEffect } from "react"
import { alwaysArray } from "../../../common/utils"
import { useAuthContext } from "../../providers/AuthProvider"
import { MyIndividual } from "../../providers/MyIndividualProvider/MyIndividualProvider"

export const useLDIdentifyUser = (myIndividual?: MyIndividual) => {
  const auth = useAuthContext()
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldClient) {
      console.debug('[api.thirdParty.launchDarkly.useLDIdentifyUser] ldClient not ready yet!', { myIndividual, ldClient })
      return
    }

    if (!myIndividual) {
      console.debug('[api.thirdParty.launchDarkly.useLDIdentifyUser] No user to identify yet!', { myIndividual, ldClient })
      return
    }

    const ldUser = {
      key: myIndividual.id,
      firstName: myIndividual.givenName,
      lastName: myIndividual.familyName,
      email: auth.userData?.email,
      custom: {
        teamTypes: alwaysArray(myIndividual.teamConnections).map(connection => connection.team.type),
      },
    }
    console.debug('[api.thirdParty.launchDarkly.useLDIdentifyUser] Identifying user: ', { ldUser, myIndividual })

    ldClient.identify(ldUser, undefined, (error, flags) => {
      console.debug('[api.thirdParty.launchDarkly.useLDIdentifyUser] User identified: ', { ldUser, myIndividual, error, flags })
    })
  }, [ ldClient, myIndividual ])
}
