import React from 'react'
import NestedMenuItem from './NestedMenuItem'
import { NestedMenuItemOption } from './NestedMenuItem'
import Style from './NestedMenu.module.scss'

interface NestedMenuProps<T> {
  options: NestedMenuItemOption<T>[],
  onSelect: (value: T) => void,
}

const NestedMenu = <T,> ({ onSelect, options }: NestedMenuProps<T>) => {
  return (
    <div className={Style.listContainer}>
      {options.map((option) => {
        return <NestedMenuItem key={option.key} option={option} onSelect={onSelect} />
      })}
    </div>
  )
}

export default NestedMenu
