import React from 'react'
import { IonButton } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"

const ThumbnailPhotoUnstarted: React.FC = () =>
  <IonButton className={Styles.thumbnailPhotoContainer} fill="clear" size="small"/>

export default ThumbnailPhotoUnstarted

