import { useQueryClient } from "react-query"
import { useGraphQLDataSource } from "../../graphql"
import { ChatRoomMessageDocument, CreateChatRoomMessageDocument, CreateChatRoomMessageDocumentMutation, GetChatRoomMessagesQuery, MessageSentInfo, SendChatRoomMessage, useArchiveChatRoomMessageDocumentMutation, useCreateChatRoomMessageDocumentMutation, useGetChatRoomWithMyMessagesQuery, useSendChatRoomMessageMutation } from "../../../graphql/generated"
import { sortBy, alwaysArray, isSomething } from "../../../common/utils"
import { StorableValue, useStoredValue } from "../../providers/StorageProvider"
import { useAblyPartyLine, useAblyPartyLinePublish } from "../../providers/AblyProvider/useAblyPartyLine"
import { z } from "zod"
import { useMyIndividual } from "../../providers/MyIndividualProvider/MyIndividualProvider"
import { useCallback } from "react"

const PACKAGE = 'api.services.chat'

/**
 * Represents the different sources the messages can come from.
 */
enum MessageSource {
  Server = "server", // From the GraphQL Server
  Partyline = "partyline", // From Ably's partyline or channel
}

/**
 * Zod's objects
 */
// This is the base chat room message model which represents the critical chat room fields for display

const zChatRoomMessageDocument = z.object({
  id: z.string(),
  archived: z.boolean().nullable().optional(),
  chatRoomMessageIdFromClient: z.string(),
  fileName: z.string(),
  fileSizeInBytes: z.number().nullable().optional(),
  fileContentType: z.string(),
  signedUrlForDownload: z.string(),
  signedUrlForUpload: z.string(),
})

const zBaseChatRoomMessage = z.object({
  idFromClient: z.string(),
  chatRoomMessageDocumentCount: z.number().nullable().optional(),
  chatRoomMessageDocuments: z.array(zChatRoomMessageDocument).nullable().optional(),
  content: z.string(),
  sentAt: z.string(),
  sentByIndividual: z.object({
    id: z.string(),
    familyName: z.string(),
    givenName: z.string(),
    pictureURL: z.string(),
  }),
})

// These are the extra fields which come when we publish party line message (currently nothing)
const zPartyLineChatRoomMessage = zBaseChatRoomMessage.extend({
  _source: z.literal(MessageSource.Partyline),
})

// These are the extra fields which come when we query the server
const zServerChatRoomMessage = zBaseChatRoomMessage.extend({
  _source: z.literal(MessageSource.Server),
  id: z.string(),
})

/**
 * Types infer from Zod's objects
 */
type BaseChatRoomMessage = z.infer<typeof zBaseChatRoomMessage>
type ServerChatRoomMessage = z.infer<typeof zServerChatRoomMessage>
type PartyLineChatRoomMessage = z.infer<typeof zPartyLineChatRoomMessage>
// The union of the above is stored in the cache
type CachedChatRoomMessage = ServerChatRoomMessage | PartyLineChatRoomMessage
export type ChatRoomMessage = BaseChatRoomMessage

/**
 * Other types
 */
export type UseRealtimeChatMessagesturns = {
  chatRoomMessages: ChatRoomMessage[] | undefined,
  sendChatRoomMessage: TMutationSendChatRoomMessageFn,
  createChatRoomMessageDocument: TMutationCreateChatRoomMessageDocumentFn,
  archiveChatRoomMessageDocument: TMutationArchiveChatRoomMessageDocumentFn,
}
type QueryChatRoomMessages = NonNullable<GetChatRoomMessagesQuery['getChatRoomMessages']>
type QueryChatRoomMessage = QueryChatRoomMessages[number]

export type TMutationSendChatRoomMessageFn = (message: SendChatRoomMessage, attachments: CreateChatRoomMessageDocumentReturns[]) => Promise<MessageSentInfo | undefined | null>

export type CreateChatRoomMessageDocumentReturns = Pick<ChatRoomMessageDocument, 'id' | 'chatRoomMessageIdFromClient' | 'fileContentType' | 'fileName' | 'fileSizeInBytes' | 'signedUrlForUpload' | 'signedUrlForDownload'>

export type TMutationCreateChatRoomMessageDocumentFn = (messageDocument: CreateChatRoomMessageDocument) => Promise<CreateChatRoomMessageDocumentReturns | undefined>

export type TMutationArchiveChatRoomMessageDocumentFn = (messageId: string) => void

const markMessageAsFromServer = (message: QueryChatRoomMessage): ServerChatRoomMessage => ({ ...message, _source: MessageSource.Server })

const isMessageFromServer = (message: CachedChatRoomMessage): message is ServerChatRoomMessage => message._source === MessageSource.Server

/**
 * Reconcilies the cached messages with the incoming messages from the server. First, it replaces all the cached messages where id or idFromClient has authoritively come from the server.
 * Then it caches those server messages that don't exist in the cache currently.
 * @param messagesFromServer The messages coming from the GraphQL server.
 * @param chatRoomMessagesCache The current cached messages.
 * @returns
 */
const reconcileCachedMessagesWithServerMessages = (messagesFromServer: ServerChatRoomMessage[], chatRoomMessagesCache: StorableValue<CachedChatRoomMessage[]>): CachedChatRoomMessage[] => {
  const messagesFromServerIds = messagesFromServer.map(each => each.id)
  const messagesFromServerIdFromClient = messagesFromServer.map(each => each.idFromClient)

  // Replace all the cached messages where id / idFromClient has authoritively come from the server
  const cacheWithReplacedMessages: CachedChatRoomMessage[] = alwaysArray(chatRoomMessagesCache)
    .map(
      (cachedMessage) => {
        if (isMessageFromServer(cachedMessage) && messagesFromServerIds.includes(cachedMessage.id)) {
          return messagesFromServer.find(newMessage => cachedMessage.id === newMessage.id)
        } else {
          if (messagesFromServerIdFromClient.includes(cachedMessage.idFromClient)) {
            return messagesFromServer.find(newMessage => cachedMessage.idFromClient === newMessage.idFromClient)
          } else {
            return cachedMessage
          }
        }
      },
    )
    .filter(isSomething)

  // Add any messages where the id does not exist in the cache
  const cacheWithReplacedMessagesId = cacheWithReplacedMessages.filter(isMessageFromServer).map(each => each.id)
  const uncachedNewMessages = messagesFromServer.filter(each => !cacheWithReplacedMessagesId.includes(each.id))
  const cacheWithReplacedAndNewMessages: CachedChatRoomMessage[] = [ ...cacheWithReplacedMessages, ...uncachedNewMessages ]

  return cacheWithReplacedAndNewMessages
}

export const useRealtimeChatMessages = ({ chatRoomId }: { chatRoomId: string }): UseRealtimeChatMessagesturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useRealtimeChatMessages`

  const myIndividual = useMyIndividual()

  // Load and Init the cache
  const [ chatRoomMessagesCache, setChatRoomMessagesCache ] = useStoredValue<CachedChatRoomMessage[]>({
    key: `chatRoomMessageCache:${chatRoomId}`,
    initialValue: [],
  })

  // Get data from the server
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const includeMessagesWithUnarchivedDocuments = (chatRoomMessage: ChatRoomMessage) => {
    if (!chatRoomMessage.chatRoomMessageDocuments || chatRoomMessage.chatRoomMessageDocuments.length === 0){
      return chatRoomMessage
    }

    return { ...chatRoomMessage, chatRoomMessageDocuments: [ chatRoomMessage.chatRoomMessageDocuments.filter(document => !document.archived) ] }
  }

  useGetChatRoomWithMyMessagesQuery(gqlDataSource, { chatRoomId }, {
    enabled: chatRoomMessagesCache !== undefined, // Wait for the cache to load / init
    staleTime: 5 * 60 * 1000, // 5 minutes
    onSuccess: dataFromServer => {
      const messagesFromServer: ServerChatRoomMessage[] = alwaysArray(dataFromServer.getChatRoom?.messages).filter(includeMessagesWithUnarchivedDocuments).map(markMessageAsFromServer)
      const cacheWithReplacedAndNewMessages = reconcileCachedMessagesWithServerMessages(messagesFromServer, chatRoomMessagesCache)
      // Sort and cache
      cacheWithReplacedAndNewMessages?.sort(sortBy(each => each.sentAt))
      setChatRoomMessagesCache(cacheWithReplacedAndNewMessages)

      return messagesFromServer
    },
  })

  // Add an invalidator so that we refetch from the server when we know updates have happened
  const queryClient = useQueryClient()
  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [ 'getChatRoomMessages', { chatRoomId } ],
    })
  }

  // Connect to the PartyLine
  useAblyPartyLine({
    partyLineId: chatRoomId,
    onMessageFn: useCallback(message => {
      try {
        const messageData = zPartyLineChatRoomMessage.parse(message.data)

        // Check I'm ready to run
        if (myIndividual == null || chatRoomMessagesCache === undefined) {
          console.debug(`[${LOCAL_PACKAGE}] Ignoring party line message as I am not ready: `, { chatRoomId, message, myIndividual, chatRoomMessagesCache: chatRoomMessagesCache })
          return
        }

        // Check to see if this is a duplicate by the idFromClient
        const chatRoomMessagesIdFromClient = chatRoomMessagesCache.map(each => each.idFromClient)
        if (chatRoomMessagesIdFromClient.includes(messageData.idFromClient)) {
          // Replace the duplicate
          const replacedChatRoomMessagesCache = chatRoomMessagesCache.map(cachedMessage =>
            cachedMessage.idFromClient === messageData.idFromClient
              ? messageData
              : cachedMessage,
          )
          setChatRoomMessagesCache(replacedChatRoomMessagesCache)
        } else {
          // Add to the end
          setChatRoomMessagesCache([ ...chatRoomMessagesCache, messageData ])
        }
      } catch (error) {
        console.error("[useCachedAuth] Unable to decode message from Ably: ")
        throw error
      }
    }, [ chatRoomMessagesCache, setChatRoomMessagesCache ]),
  })

  const ablyPartyLinePublish = useAblyPartyLinePublish({ partyLineId: chatRoomId })

  const mutationSendChatRoomMessage = useSendChatRoomMessageMutation(gqlDataSource, { onSuccess })
  const mutationCreateChatRoomMessageDocument = useCreateChatRoomMessageDocumentMutation(gqlDataSource, { onSuccess })
  const mutationArchiveChatRoomMessageDocument = useArchiveChatRoomMessageDocumentMutation(gqlDataSource, { onSuccess })

  const sendChatRoomMessage = async (sendMessage: SendChatRoomMessage, chatRoomMessageDocuments: CreateChatRoomMessageDocumentReturns[]) => {
    // Check I'm ready to run
    if (myIndividual == null || chatRoomMessagesCache === undefined) {
      console.debug(`[${LOCAL_PACKAGE}] Ignoring new message as I am not ready: `, { chatRoomId, sendMessage, myIndividual, chatRoomMessagesCache })
      return
    }

    // Augment SendChatRoomMessage for the Party Line
    const chatMessage: PartyLineChatRoomMessage = {
      ...sendMessage,
      sentByIndividual: myIndividual,
      _source: MessageSource.Partyline,
      chatRoomMessageDocuments,
    }
    console.log(`[${LOCAL_PACKAGE}.sendChatRoomMessage] Sending to the partyline `, { chatMessage })
    ablyPartyLinePublish({ message: chatMessage })

    // Send the SendChatRoomMessage to the server
    console.log(`[${LOCAL_PACKAGE}.sendChatRoomMessage] Sending to the server `, { sendMessage })
    const result = await mutationSendChatRoomMessage.mutateAsync({ message: sendMessage })
    return result.sendChatRoomMessage
  }

  const createChatRoomMessageDocument = async (createChatRoomMessageDocument: CreateChatRoomMessageDocument): Promise<CreateChatRoomMessageDocumentReturns | undefined> => {
    const result: CreateChatRoomMessageDocumentMutation = await mutationCreateChatRoomMessageDocument.mutateAsync({ messageDocument: createChatRoomMessageDocument })
    if (!result.createChatRoomMessageDocument) return undefined
    return {
      id: result.createChatRoomMessageDocument.id,
      chatRoomMessageIdFromClient: result.createChatRoomMessageDocument.chatRoomMessageIdFromClient,
      fileContentType: result.createChatRoomMessageDocument.fileContentType,
      fileName: result.createChatRoomMessageDocument.fileName,
      fileSizeInBytes: result.createChatRoomMessageDocument.fileSizeInBytes,
      signedUrlForUpload: result.createChatRoomMessageDocument.signedUrlForUpload,
      signedUrlForDownload: result.createChatRoomMessageDocument.signedUrlForDownload,
    }
  }

  const archiveChatRoomMessageDocument = async (chatRoomMessageDocumentId: string) => {
    await mutationArchiveChatRoomMessageDocument.mutateAsync({ id: chatRoomMessageDocumentId })
  }

  return {
    chatRoomMessages: chatRoomMessagesCache,
    sendChatRoomMessage,
    createChatRoomMessageDocument,
    archiveChatRoomMessageDocument,
  }
}

