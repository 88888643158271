import { SetStateAction, useEffect, useState } from "react"
import useAbortableEffect from "./useAbortableEffect"

// The useEffect 2 arguments switched into { state, setState } props for nicer readability
type UseStateProps<T> = {
  state: T | undefined,
  setState: React.Dispatch<React.SetStateAction<T | undefined>>,
}

// The useEffect 2 arguments
type UseEffectArgs<T> = [
  (props: UseStateProps<T>) => void, // Normally useEffect would have this as: () => void
  Parameters<typeof useEffect>[1],
]

/**
 * Sometimes you want the result of an asynchronous stored in a `useState`, so you have a `useEffect` to manage that.
 * The component may be unmounted, so we has `useAbortableEffect` manage that case for us with an intercepted `setState`.
 *
 * ### TRADITIONAL USAGE:
 *
 * ```typescript
 * type StarWarsCharacter = { name: 'jedi' }
 * const useMyFavouriteStarwarCharacter = () => {
 *   const [swChar, setSwChar] = useState<StarWarsCharacter>()
 *   useAbortableEffect(status => {
 *     fetch('http://something.com/')
 *       .then(result => result.json())
 *       .then(object => object as StarWarsCharacter)
 *       .then(value => {
 *          if (!status.aborted) setSwChar(value)
 *        })
 *   }, [])
 *   return swChar
 * }
 * ```
 *
 * ### NEW USAGE:
 * _**Note:** there's no need to worry about the unmount case_
 *
 * ```typescript
 * type StarWarsCharacter = { name: 'jedi' }
 * const useMyFavouriteStarwarCharacter2 = useStatefulEffect<StarWarsCharacter>(({ setState }) => {
 *   fetch('http://something.com/')
 *     .then(result => result.json())
 *     .then(object => object as StarWarsCharacter)
 *     .then(setState)
 * }, [])
 * ```
 *
 * @param name A name for this state variable. Used in warning log messages to help identify this usage.
 * @param create A function like `({ state, setState }) => { doSomething().then(setState) }`
 * @param deps The usual `useEffect` react hook dependency array
 * @returns `{ state, setState }` which is just `useState`'s return type switched into named params form
 */
const useStatefulEffect = <T extends object>(name: string, create: UseEffectArgs<T>[0], deps?: UseEffectArgs<T>[1]) => {
  const [ state, setState ] = useState<T | undefined>()

  useAbortableEffect(status => {
    // Only update the state if the component has not been unmounted
    const setStateAbortable: React.Dispatch<React.SetStateAction<T | undefined>> = (newState: SetStateAction<T | undefined>) => {
      if (status.aborted) {
        console.warn(`[useStatefulEffect#${name}] Unable to setState on an unmounted component!`, { state, newState })
        return
      }

      setState(newState)
    }

    // Call the useEffect function, passing in the useState properties (abortable)
    create({ state, setState: setStateAbortable })
  }, deps)

  return { state, setState }
}

export default useStatefulEffect
