import { useEffect } from "react"

// Heavily inspired by https://www.debuggr.io/react-update-unmounted-component/
// See also: node_modules/@types/react/index.d.ts

export type TAbortableEffectStatus = { aborted?: boolean }
type EffectCallback = (status: TAbortableEffectStatus) => (void | (() => void | undefined))
type DependencyList = ReadonlyArray<unknown>

const useAbortableEffect = (effect: EffectCallback, deps?: DependencyList): TAbortableEffectStatus => {
  // mutable status object
  const status: TAbortableEffectStatus = {}

  // The effect that detects if we've been unmounted
  useEffect(() => {
    status.aborted = false
    // pass the mutable object to the effect callback
    // store the returned value for cleanup
    const cleanUpFn = effect(status)
    return () => {
      // mutate the object to signal the consumer
      // this effect is cleaning up
      status.aborted = true
      if (typeof cleanUpFn === "function") {
        // run the cleanup function
        cleanUpFn()
      }
    }
  }, deps)

  // Leaking the mutatble object, as it might be useful elsewhere
  return status
}

export default useAbortableEffect
