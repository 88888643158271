import React from 'react'
import {
  IonApp,
  IonContent,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabs,
  setupIonicReact,
} from '@ionic/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { IonReactRouter } from '@ionic/react-router'
import { getRouterRoutes, getRouterTabButtons } from "./routes"

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import Bootstrapper from './init/Bootstrapper'
import Menu from './common/components/Menu'
import GlobalActionButton from './common/components/GlobalActionButton'
import AppUrlListener from './common/AppUrlListener'
import { useMyIndividualActiveTeam } from './api/providers/MyIndividualProvider/MyIndividualProvider'
import SegmentProvider from './api/providers/SegmentProvider'
import { environment } from './api/providers/EnvironmentProvider'
import { InviteClaimer } from './common/components/InviteClaimer'

setupIonicReact()

const BootstrappedApp: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()

  return <>
    <IonApp>
      <InviteClaimer />
      <AppUrlListener />
      <Menu />
      <IonContent>
        <IonPage id="main">
          <IonTabs>
            <IonRouterOutlet key="app">
              {getRouterRoutes(myTeam)}
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              {getRouterTabButtons(myTeam)}
            </IonTabBar>
          </IonTabs>
        </IonPage>
      </IonContent>
      <GlobalActionButton />
    </IonApp>
    <div id='googleMapsAttribution'></div>
  </>
}

const App: React.FC = () =>
  <SegmentProvider>
    <IonReactRouter>
      <Bootstrapper>
        <BootstrappedApp />
      </Bootstrapper>
    </IonReactRouter>
  </SegmentProvider>

const AppWithLaunchDarkly = withLDProvider({
  clientSideID: environment.integrations.launchDarkly.clientSideId,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App)

export default AppWithLaunchDarkly
