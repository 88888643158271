import React, { useCallback } from 'react'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButton } from '@ionic/react'
import { useDevRemoveIndividualFromTeamMutation } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { getActiveTeam, useMyIndividual, useMyIndividualInvalidateCache } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useHistory } from 'react-router'
import { getKnownRoutePathForPage, Page } from '../../../routes'

const ManageTeams: React.FC = () => {
  const history = useHistory()

  const myIndividual = useMyIndividual()
  const individualId = myIndividual.id

  const activeTeam = getActiveTeam(myIndividual)
  const teamId = activeTeam?.id

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const removeIndividualFromTeam = useDevRemoveIndividualFromTeamMutation(gqlDataSource)
  const individualCacheBuster = useMyIndividualInvalidateCache()

  const onClickRemoveIndividualFromTeam = useCallback(async () => {
    if (!teamId) {
      console.error('[devSettings.ManageTeams] Cannot remove individual from no teams.', { myIndividual, activeTeam })
      return
    }

    await removeIndividualFromTeam.mutateAsync({
      individualId,
      teamId,
    })

    await individualCacheBuster()

    history.push(getKnownRoutePathForPage(Page.Onboarding))
  }, [ removeIndividualFromTeam, individualId, teamId ])

  return (
    <IonPage id="dev-settings">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Developer Settings: Manage Teams</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton onClick={onClickRemoveIndividualFromTeam}>Dettach My Team</IonButton>
      </IonContent>
    </IonPage>
  )
}

export default ManageTeams
