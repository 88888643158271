
import React, { MouseEvent, ReactNode, useEffect, useState } from 'react'
import { IonPage, IonContent, IonButton, IonIcon } from '@ionic/react'
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import { useSearchState } from '../hooks/pages'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import Styles from "./RoutedSwiper.module.scss"

const INITIAL_PAGE_INDEX = 0

type RoutedSwiperProps = {
  pages: {
    name: string,
    render: (goToNextPage?:() => void) => ReactNode,
    hideButtons?: boolean, // Hide all the buttons
    canGoBack?: () => Promise<boolean>,
    backButtonText?: string, // Defaults to Back on all pages
    beforeGoBackOnClick?: () => Promise<{ abort: boolean } | void>,
    canGoForward?: () => Promise<boolean>,
    forwardButtonText?: string, // Defaults to Next on all pages except last, which is Finished
    beforeGoForwardOnClick?: () => Promise<{ abort: boolean } | void>,
  }[],
  // If set, the first page shows the Back button and calls this method (otherwise there is no Back button on the first page)
  onFirstPageGoBack?: (event: MouseEvent<unknown, globalThis.MouseEvent>) => Promise<void> | void,
  // Called by the Next button on the last page
  onLastPageGoForward: () => Promise<void> | void,
}

const RoutedSwiper: React.FC<RoutedSwiperProps> = (props: RoutedSwiperProps) => {
  const [ search, mergeSearch ] = useSearchState({ page: INITIAL_PAGE_INDEX })
  const [ swiper, setSwiper ] = useState<SwiperType | undefined>(undefined)
  const [ maxUnlockedPageIndex, setMaxUnlockedPageIndex ] = useState<number>(0)

  const page = Number(search.page) ?? INITIAL_PAGE_INDEX
  const isFirstPage = page === INITIAL_PAGE_INDEX
  const isLastPage = page === props.pages.length - 1
  const nextPageUnlocked = page <= maxUnlockedPageIndex
  const signupStepsPage = 'signupStepsFirst'

  const goToNextPage = async () => {
    if (isLastPage) {
      console.error(`[RoutedSwiper] Tried to advance past the last page!`, { isLastPage, page, props })
      return
    }
    setMaxUnlockedPageIndex(page + 1)
    mergeSearch({ page: page + 1 })
  }

  const goBackAPage = () => {
    if (isFirstPage) {
      console.error(`[RoutedSwiper] Tried to retreat before the first page!`, { isFirstPage, page, props })
      return
    }
    mergeSearch({ page: page - 1 })
  }

  useEffect(() => {
    if (!swiper || swiper.destroyed) return

    // Ensure we don't skip ahead with URL hacking
    if (page > maxUnlockedPageIndex) {
      mergeSearch({ page: maxUnlockedPageIndex })
      swiper.slideTo(maxUnlockedPageIndex)
      return
    }

    // Ensure we're showing the page that our URL refers to
    swiper.slideTo(page)
  }, [ swiper, page, maxUnlockedPageIndex ])

  const getButtonContainerClassName = (className:string) =>
    [ Styles.buttonContainer, Styles[className] ].join(' ')

  return (
    <IonPage>
      <IonContent>
        <div className={Styles.container}>
          <Swiper
            className={Styles.swiper}
            onActiveIndexChange={(x) => mergeSearch({ page: x.activeIndex })}
            onSwiper={(swiper) => setSwiper(swiper)}
            allowSlideNext={nextPageUnlocked}
            simulateTouch={false}
          >
            {props.pages.map(eachPage => (
              <SwiperSlide key={eachPage.name}>
                <div className={Styles.slideContainer}>
                  {eachPage.render(goToNextPage)}
                  {!eachPage.hideButtons && <div className={getButtonContainerClassName(eachPage.name)}>
                    {/* Back Button */}
                    {isFirstPage
                      ? props.onFirstPageGoBack === undefined
                        ? <div></div>
                        : (
                          <IonButton className="prev" color='secondary' onClick={props.onFirstPageGoBack}>
                            <IonIcon slot="start" icon={arrowBackOutline} />
                            {eachPage.backButtonText ?? 'Back'}
                          </IonButton>
                        )
                      : (
                        <IonButton className="prev" color='secondary' onClick={async () => {
                          if (eachPage.canGoBack) {
                            const result = eachPage.canGoBack()
                            if (!result) return
                          }

                          if (eachPage.beforeGoBackOnClick) {
                            const result = await eachPage.beforeGoBackOnClick()
                            if (result?.abort) return
                          }

                          goBackAPage()
                        }}>
                          <IonIcon slot="start" icon={arrowBackOutline} />
                          {eachPage.backButtonText ?? 'Back'}
                        </IonButton>
                      )}
                    {/* Forward Button */}
                    {eachPage.name !== signupStepsPage && (<IonButton className="next" color='primary' onClick={async () => {
                      if (eachPage.canGoForward) {
                        const result = await eachPage.canGoForward()
                        if (!result) return
                      }

                      if (eachPage.beforeGoForwardOnClick) {
                        const result = await eachPage.beforeGoForwardOnClick()
                        if (result?.abort) return
                      }

                      isLastPage ? props.onLastPageGoForward() : goToNextPage()
                    }}>
                      <IonIcon slot="end" icon={arrowForwardOutline} />
                      {eachPage.forwardButtonText ?? (isLastPage ? 'Finish' : 'Next')}
                    </IonButton>
                    )}
                  </div>}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default RoutedSwiper
