import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import Styles from "./ConfettiPage.module.scss"

interface ConfettiPageSliderProps {
  caption: string,
  message: string,
}

const width = window.innerWidth
const height = window.innerHeight

const ConfettiPageSlider: React.FC<ConfettiPageSliderProps> = ({ caption, message }) => {

  const [ runLeft, setRunLeft ] = useState(true)
  const [ runRight, setRunRight ] = useState(false)

  useEffect(() => {
    const timerLeft = setTimeout(() => {
      // This will run after 1 second on the RH side
      setRunLeft(false)
      setRunRight(true)
    }, 2000)
    const timerRight = setTimeout(() => {
      // This will run after 1 second on the LH side
      setRunRight(false)
    }, 4000)
    return () => {
      clearTimeout(timerLeft)
      clearTimeout(timerRight)
    }
  }, [])

  return (
    <>
      <div className={Styles.pageContainer}>
        <h1 className={Styles.caption}>{caption}</h1>
        <p className={Styles.notification} >
          {message}
        </p>
        <Confetti
          width={runLeft || runRight ? width : 0}
          height={height}
          recycle={true}
          run={runRight || runLeft}
          gravity={0.07}
          confettiSource={{ x: runLeft  ? 0 : width, y: height / 2, w: width / 3, h: height / 3 }}
        />
      </div>
    </>
  )
}

export default ConfettiPageSlider
