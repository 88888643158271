import { PushNotifications } from "@capacitor/push-notifications"
import { useEffect } from "react"

export const useObtainNotificationPermissions = (onPermissionsObtained: () => void) => {
  useEffect(() => {
    PushNotifications.checkPermissions().then(res => {
      if (res.receive !== 'granted') {
        PushNotifications.requestPermissions().then(res => {
          if (res.receive !== 'denied') {
            onPermissionsObtained()
          }
        })
      }
      else {
        onPermissionsObtained()
      }
    })
  },[])
}
