import React from 'react'
import { useTickingTimer } from '../../hooks/useTickingTimer'

export type TLoadingSpinnerProps = {
  name: string,
  logMessage?: string,
  className?: string,
};

/**
 * A Loading spinner. Only display a loading indicator after a short delay, to make short loads seem instantaneous.
 */
const LoadingSpinner: React.FC<TLoadingSpinnerProps> = (props) =>
  useTickingTimer({ seconds: 3 })
    ? <DisplayedLoadingSpinner {...props} />
    : null

const DisplayedLoadingSpinner: React.FC<TLoadingSpinnerProps> = ({ name, logMessage }) => {
  if (logMessage) console.debug(`[LoadingSpinner#${name}] Spinner displayed: ${logMessage}`)

  return (
    <em className="loadingSpinner">... Loading {name}...</em>
  )
}

export default LoadingSpinner
