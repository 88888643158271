// see: https://github.com/segmentio/analytics-next#using-react-advanced-w-react-context

import React from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useSegmentSetup } from './hooks'

export const SegmentContext = React.createContext<AnalyticsBrowser | undefined>(undefined)

export const SegmentProvider: React.FC= ({ children }) => {
  const analytics = useSegmentSetup()

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  )
}

export default SegmentProvider
