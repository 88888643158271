import { useQueryClient } from "react-query"
import { alwaysArray } from "../../../common/utils"
import { ListNotesQuery, useListNotesQuery, useCreateNoteMutation, useUpdateNoteMutation, useDeleteNoteMutation, CreateNoteInput, UpdateNoteInput, CreateNoteMutation, UpdateNoteMutation, DeleteNoteMutation, Note } from "../../../graphql/generated"
import { useGraphQLDataSource } from "../../graphql"

const PACKAGE = 'api.services.forgetMeNote'

export type TNote = NonNullable<NonNullable<ListNotesQuery['listNotes']>[number]>

type TMutationAddNoteFn = (note: CreateNoteInput) => Promise<CreateNoteMutation>
type TMutationUpdateNoteFn = (note: UpdateNoteInput) => Promise<UpdateNoteMutation>
type TMutationDeleteNoteFn = (note: Pick<Note, 'id'>) => Promise<DeleteNoteMutation>

type TUseNotesReturns = [
  TNote[] | null | undefined,
  TMutationAddNoteFn,
  TMutationUpdateNoteFn,
  TMutationDeleteNoteFn,
]

export const useNotes = (): TUseNotesReturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useNotes`

  const queryClient = useQueryClient()
  const onSuccess = () => {
    queryClient.invalidateQueries('forgetMeNote')
  }

  console.log(`[${LOCAL_PACKAGE}] Rendering `, { queryClient })

  const gqlDataSource = useGraphQLDataSource({ api: 'notes' })
  const queryResult = useListNotesQuery(gqlDataSource, {}, { queryKey: [ 'chatRooms', 'list' ] })
  const mutationCreateNote = useCreateNoteMutation(gqlDataSource, { onSuccess })
  const mutationUpdateNote = useUpdateNoteMutation(gqlDataSource, { onSuccess })
  const mutationDeleteNote = useDeleteNoteMutation(gqlDataSource, { onSuccess })

  console.debug(`[${LOCAL_PACKAGE}] React Graph`, { queryResult, mutationCreateNote, mutationUpdateNote, mutationDeleteNote })

  const notes = queryResult.isSuccess
    ? alwaysArray(queryResult.data.listNotes)
    : queryResult.isLoading
      ? undefined
      : null

  return [
    notes,
    async (note) => {
      return await mutationCreateNote.mutateAsync({ note })
    },
    async (note) => {
      return await mutationUpdateNote.mutateAsync({ note })
    },
    async (note) => {
      return await mutationDeleteNote.mutateAsync({ id: note.id })
    },
  ]
}
