import React, { useEffect, useRef } from 'react'
import { IonContent } from "@ionic/react"
import { ChatMessageBubble } from './ChatMessageBubble'
import { ChatMessageWithDocumentsBubble } from './ChatMessageWithDocumentsBubble'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useChatRoom } from './services/ChatRoomProvider'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useMyIndividual } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import ResponsiveContentWrapper from '../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'

const PACKAGE = 'chatContent'

const ChatContent: React.FC = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const { chatRoomMessages, emptyCache } = useChatRoom()
  const individual = useMyIndividual()

  useEffect(() => {
    // The message images cache is clear every time the chat screen is rendered for the first time
    emptyCache()
  }, [])

  useEffect(() => {
    const scrollToLastMessage = () => {
      if (chatRoomMessages !== undefined){
        virtuosoRef.current?.scrollToIndex({ index: chatRoomMessages.length, behavior: 'auto', align: "start" })
      }
    }
    // We need to make sure the last message has rendered on screen before we scroll
    setTimeout(scrollToLastMessage, 1)
  }, [ chatRoomMessages?.length ])

  if (chatRoomMessages === undefined){
    return <LoadingSpinner name={`${PACKAGE}.undefined`} />
  }

  return (
    <IonContent>
      <Virtuoso
        ref={virtuosoRef}
        style={{ height: "100%" }}
        data={chatRoomMessages}
        itemContent={index => {
          const message = chatRoomMessages[index]
          const wasMessageSentByCurrentUser = message.sentByIndividual.id === individual?.id
          return (
            <>
              <ResponsiveContentWrapper>
                {
                  message.chatRoomMessageDocumentCount
                    ? <ChatMessageWithDocumentsBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} />
                    : <ChatMessageBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} />
                }
              </ResponsiveContentWrapper>

            </>
          )
        }}
      >
      </Virtuoso>
    </IonContent>
  )
}

export default ChatContent
