import React, { useCallback, useState } from 'react'
import { IonButton, IonContent, IonPage, IonText } from '@ionic/react'
import Styles from "./UploadProjectDocuments.module.scss"
import uploadDocuments from "./upload-project-documents.svg"
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'

import { useProjectDocuments } from './useProjectDocuments'
import { useHistory, useParams } from 'react-router'
import { UploadableFileChip } from './uploadableFileChip/UploadableFileChip'
import  BrowseFilesButton from  '../../../common/components/BrowseFilesButton'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { saveFileToDeviceStorage } from '../../../common/utils/files'

const UploadProjectDocuments: React.FC = () => {
  const { projectId } = useParams<{projectId: string}>()
  const history = useHistory()
  const myTeam = useMyIndividualActiveTeam()

  if (!myTeam) return <LoadingSpinner name='displayMyProfile' />

  const [ fileList, setFileList ] = useState<File []>([])

  const addFileToList = (file: File) => {
    setFileList(currentState => ([ ...currentState, file ]))
  }

  const clearFileList = () => {
    setFileList([])
  }

  const removeFileFromList = (fileToRemove: File) => {
    setFileList(currentState => ([ ...currentState.filter(file => file.name !== fileToRemove.name) ]))
  }

  const { createProjectDocument, archiveProjectDocument, incrementProjectDocumentCount } = useProjectDocuments({ projectId })

  const onFileSelectedByUser = useCallback(async (file: File) => {
    const fileHasNotBeenAlreadyAdded = !fileList.includes(file)
    if (fileHasNotBeenAlreadyAdded){
      addFileToList(file)
      await saveFileToDeviceStorage(file)
    }
  }, [ fileList, addFileToList ])

  const onFileRemovedByUser = useCallback((file: File) => {
    removeFileFromList(file)
  }, [ removeFileFromList ])

  const createDocument = async (file: File) => {
    const result = await createProjectDocument({
      projectId,
      uploadedByTeamId: myTeam.id,
      fileName: file.name,
      fileSizeInBytes: file.size,
      fileContentType: file.type,
    })
    if (result){
      return { id: result.id, signedUrlForUpload: result?.signedUrlForUpload }
    }

    return undefined
  }

  const navigateToSuccessPage = () => {
    history.push(`/uploadProjectDocumentSuccess`, {
      routerLink: `/projects/${projectId}`,
    })
  }

  const onConfirm = async () => {
    await incrementProjectDocumentCount({
      projectId,
      documentCount: fileList.length,
    })
    clearFileList()
    navigateToSuccessPage()
  }

  return (
    <IonPage>
      <GlobalHeader pageTitle='Documents'/>
      <IonContent className='ion-padding'>
        <h4 className={Styles.title}>Upload Documents</h4>

        <div className={`${Styles.mainContainer} ion-padding ion-align-items-center`}>
          <img src={uploadDocuments} className={Styles.uploadDocumentsImage} alt="Upload documents image" />
          <h6 className={Styles.uploadYourDocumentsText}>Upload Your Documents</h6>

          <BrowseFilesButton onFileSelectedByUserCallback={onFileSelectedByUser} />

          <IonText className={Styles.supportedFormatsText}>Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</IonText>

          <div className={Styles.filesToUploadContainer}>
            {
              fileList.map(
                file =>
                {
                  // Use the file's last modified + name as the key since files don't come with an id and it's not recommended the use index in cases when items can be removed.
                  const key = `${file.lastModified}_${file.name}`
                  return <UploadableFileChip
                    key={key}
                    file={file}
                    fileRemovedCallback={onFileRemovedByUser}
                    createServerDocument={createDocument}
                    archiveServerDocument={archiveProjectDocument}
                  />
                },
              )
            }
          </div>

          <IonButton disabled={fileList.length === 0} onClick={onConfirm} className={Styles.confirmButton}>Confirm</IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default UploadProjectDocuments
